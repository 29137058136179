import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    InsuranceDataContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { isEmail } from '../libs/utils';
import {
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';

export const Introduction = () => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    // const [contact, setContact] = useState({
    //     email: "",
    //     first_name: "",
    //     last_name: ""
    // });
    //const [citizenship, setCitizenship] = useState(false);
    //const [truthful, setTruthful] = useState(false);
    const [highlighted, setHighlighted] = useState({
        // email: false,
        // phone: false,
        // first_name: false,
        // last_name: false,
        citizenship: false,
        truthful: false,
        replacement: false
    });
    const [locked, setLocked] = useState(true);
    const [validated, setValidated] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (!locked) {

            // we want to remove existing highlights if applicable, nothing more

            let newHighlighted = { ...highlighted };
            let verified = 0;

            // if (isEmail(session.email)) {
            //     newHighlighted.email = false;
            //     verified++;
            // }

            // if (session.phone && session.phone.length > 13) {
            //     newHighlighted.phone = false;
            //     verified++;
            // }

            // if (session.first_name && session.first_name.length > 1) {
            //     newHighlighted.first_name = false;
            //     verified++;
            // }

            // if (session.last_name && session.last_name.length > 1) {
            //     newHighlighted.last_name = false;
            //     verified++;
            // }

            if (session.citizenship) {
                newHighlighted.citizenship = false;
                verified++;
            }

            if (session.truthful) {
                newHighlighted.truthful = false;
                verified++;
            }


            if (session.replacement) {
                newHighlighted.replacement = false;
                verified++;
            }

            if (3 === verified) {
                setValidated(true);
            } else {
                setValidated(false);
            }

            setHighlighted(newHighlighted);
        }

    }, [session.citizenship, session.truthful, session.replacement]);

    useEffect(() => {
        setLocked(false);
        // let newContact = { ...contact };       
        // setContact(newContact);
    }, []);

    //todo use questions from the filter bundle 
    const citizenshipQuestion = "4298";
    const citizenshipChoices = {
        A6643: "Yes",
        A6644: "No"
    }

    const truthfulQuestion = "4303";
    const truthfulChoices = {
        A6650: "Yes",
        A6651: "No"
    }

    const replacementQuestion = "4297";
    const replacementChoices = {
        A6641: "Yes",
        A6642: "No"
    }

    const moveForward = () => {

        let flow = getNextStep(location, { ...session });
        // if (undefined === session.validation_code) {
        //     flow.validation_code = getEmailHash(flow.email);
        //     sendEmailVerification(flow, (data) => {
        //         console.info("Email validation", data);
        //         setTimeout(() => {
        //             let newSessionEntry = {
        //                 verification_code: data.code
        //             };
        //             setSession(newSessionEntry);
        //         }, 1500);
        //     });
        // }
        setSession(flow);
        navigate(flow.next);

    }

    const validate = () => {

        let newHighlighted = {
            // email: !isEmail(session.email),
            // phone: !session.phone || session.phone.length < 14,
            // first_name: !session.first_name || session.first_name.length < 2,
            // last_name: !session.last_name || session.last_name.length < 2,
            citizenship: !session.citizenship,
            truthful: !session.truthful,
            replacement: !session.replacement
        }

        if (!newHighlighted.replacement && !newHighlighted.truthful && !newHighlighted.citizenship) {
            setValidated(true);
        }

        setHighlighted(newHighlighted);

    }

    const onChange = (controlId, value) => {

        if (value) {
            let newSessionEntry = {};
            newSessionEntry[controlId] = value;

            let choiceId = value.replace("A", ""); //ugly hack for now, we have the "A" prefix for Approve answer ID            

            let answer;

            switch (controlId) {
                case "email":
                case "phone":
                case "first_name":
                case "last_name":
                    newSessionEntry[controlId] = value;
                    break;
                case "citizenship":
                    answer = citizenshipChoices[value];
                    newSessionEntry["question_" + citizenshipQuestion] = {
                        id: choiceId,
                        text: answer
                    };
                    if ("No" === answer) {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                        } else {
                            newSessionEntry.disqualified = {};
                        }
                        newSessionEntry.disqualified.citizenship = "yes";
                    } else {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                            delete newSessionEntry.disqualified.citizenship;
                        }
                    }
                    break;
                case "replacement":
                    answer = replacementChoices[value];
                    newSessionEntry["question_" + replacementQuestion] = {
                        id: choiceId,
                        text: answer
                    };
                    if ("Yes" === answer) {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                        } else {
                            newSessionEntry.disqualified = {};
                        }
                        newSessionEntry.disqualified.replacement = "yes";
                    } else {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                            delete newSessionEntry.disqualified.replacement;
                        }
                    }
                    break;
                case "truthful":
                    answer = truthfulChoices[value];
                    newSessionEntry["question_" + truthfulQuestion] = {
                        id: choiceId,
                        text: answer
                    };
                    if ("No" === answer) {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                        } else {
                            newSessionEntry.disqualified = {};
                        }
                        newSessionEntry.disqualified.truthful = "yes";
                    } else {
                        if (session.disqualified) {
                            newSessionEntry.disqualified = { ...session.disqualified };
                            delete newSessionEntry.disqualified.truthful;
                        }
                    }
                    break;
                default:
                    break;
            }

            setSession(newSessionEntry);



        }

    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Tell us about yourself</h1>
                    </Col>
                </Row>
                {/* <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <EmailControl highlighted={highlighted.email} helpText="This is the email address we'll use for official communications. Please make sure there's no typo." controlId="email" caption="Best email address to reach you?" onChange={onChange} choices={{ defaultValue: session.email }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <PhoneNumberControl highlighted={highlighted.phone} helpText="This is the phone number we'll use for official communications. Please make sure there's no typo." controlId="phone" caption="What is your phone number?" onChange={onChange} choices={{ defaultValue: session.phone }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.first_name} helpText="Please provide your first name." controlId="first_name" caption="First name" onChange={onChange} choices={{ default: session.first_name }} />
                    </Col>
                </Row><Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.last_name} helpText="Please provide your last name." controlId="last_name" caption="Last name" onChange={onChange} choices={{ default: session.last_name }} />
                    </Col>
                </Row> */}
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.replacement} helpText="Please confirm whether this policy is intended to replace an existing one." controlId="replacement" caption="Is this policy intended to replace any life insurance currently in effect?" onChange={onChange} choices={{ items: replacementChoices, default: session.replacement }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.citizenship} helpText="Please indicate your citizenship status." controlId="citizenship" caption="Are you a Canadian citizen or landed immigrant, currently residing in Canada?" onChange={onChange} choices={{ items: citizenshipChoices, default: session.citizenship }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.truthful} helpText="Please confirm that you agree to answer questions truthfully." controlId="truthful" caption="Do you agree to answer all of these questions truthfully?" onChange={onChange} choices={{ items: truthfulChoices, default: session.truthful }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        {!locked &&
                            <>
                                {validated ?
                                    <Button id="introduction-continue" onClick={() => moveForward()} variant="primary">Continue</Button>
                                    :
                                    <Button onClick={() => validate()} variant="outline-primary">Continue</Button>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}

