import {
    React,
    useContext,
    useEffect,
} from 'react';

import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { DesktopLayout } from './desktop';
import { MobileLayout } from './mobile';

export const Page = (props) => {


    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext)
    const { layout, setLayout } = useContext(LayoutContext);


    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        let uri = location.pathname;
        if (uri !== "/" && uri !== session.next) {
            // someone accessed a child URI directly (possibly refreshing the screen) so we send them home
            console.info("URI mismatch, sending to /", uri);
            navigate("/");
        }


    }, []);

    useEffect(() => {

        if ("yes" === session.final) {
            console.info("Final step, yes");
            let uri = location.pathname;
            console.info("Final uri?", uri);
            if (uri !== "/policy") {
                // someone probably hit back in the policy page
                console.info("Not /policy, sending there", uri);
                navigate("/policy");
                setSession({ next: '/policy' });
            }
        }

    }, [session.final]);

    useEffect(() => {

        if (branding.ga && branding.ga4) {

            try {
                if (branding.ga4.pageview) {
                    branding.ga4.pageview(location.pathname);
                    branding.ga4.gtag('event', 'pageview', location.pathname);
                    //todo bring back other events
                    //policy:
                    branding.ga4.gtag('event', 'purchase', {
                        currency: "CAD", value: session.premium, items: [
                            { item_id: "Quick Term", item_name: session.term }]
                    });

                }

            } catch (err) {
                console.info("GA4", err);
            }

        }

    }, [branding]);

    useEffect(() => {

        let uri = location.pathname;

        let newLayout = { ...layout };
        if (undefined === newLayout.visited) {
            newLayout.visited = {};
        }

        let pct = 5;
        if (session.happyPath) {
            for (let [k, v] of Object.entries(session.happyPath)) {
                if ("/" + k === uri) {
                    break;
                } else {
                    pct = pct + 9;
                }
            }
        }

        if (pct < 100) {
            newLayout.pct = pct;
        } else {
            newLayout.pct = 91;
        }


        if (['/thankyou', '/opportunities'].includes(uri)) {
            newLayout.pct = 0;
        }

        if (['/policy'].includes(uri)) {
            newLayout.pct = 100;
        }

        if (['/quoter'].includes(uri)) {
            newLayout.pct = 5;
        }

        setLayout(newLayout);

    }, [session.next]);

    return (
        <>
            <PageLayout />
        </>

    )

}


const PageLayout = (props) => {


    const { session } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext)
    const { layout } = useContext(LayoutContext);

    return (
        <>
            {"yes" === layout.ready ?
                <>
                    {"mobile" === layout.device ?
                        <MobileLayout />
                        :
                        <DesktopLayout />
                    }
                </>
                :
                <>
                    <Outlet />
                </>
            }
        </>
    );


}





