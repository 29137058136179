import React from 'react';

import ReactDOM from 'react-dom/client';

import { QuickApplyApp } from './app/main';
import {
  brokerBranding,
  brokerConfig,
} from './config';

// todo bring split % from config.js
let ab = Math.random() < 0.5 ? "a" : "b";

let branding = {};

if (undefined !== brokerBranding[window.location.hostname]) {
  if (undefined !== brokerBranding[window.location.hostname][ab]) {
    branding = { ...brokerBranding[window.location.hostname][ab] };
  }
}

let queryString = window.location.search;

const root = ReactDOM.createRoot(document.getElementById('magic'));
root.render(

  <QuickApplyApp brokerBranding={branding} brokerConfig={brokerConfig} query={queryString} />

);
