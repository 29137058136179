import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import {
    PostalCodeControl,
    ProvinceControl,
    RadioControl,
    TextControl,
} from '../screen/controls';

export const Address = () => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);
    const [highlighted, setHighlighted] = useState({
        province_current: false,
        province_birth: false,
        city: false,
        address1: false,
        postal_code: false,
        country: false
    });
    const [locked, setLocked] = useState(true);
    const [validated, setValidated] = useState(false);
    const [sameProvince, setSameProvince] = useState("Yes");

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {



        if (!locked) {

            // we want to remove existing highlights if applicable, nothing more

            let newHighlighted = { ...highlighted };
            let verified = 0;

            for (let [k, v] of Object.entries(newHighlighted)) {
                if (session[k] && session[k].length >= 1) {
                    newHighlighted[k] = false;
                    verified++;
                }
            }

            if (6 === verified) {
                setValidated(true);
            } else {
                setValidated(false);
            }

            setHighlighted(newHighlighted);
        }

    }, [session.province_current, session.province_birth, session.address1, session.city, session.country, session.postal_code]);

    useEffect(() => {

        if (session.city) {
            setLocked(false);
        }

    }, [session.city]);

    useEffect(() => {

        if (insuranceData.jurisdictions) {
            setLocked(false);
        }

    }, [insuranceData.jurisdictions]);

    useEffect(() => {



        let licensed = "no";

        if (session.province_current) {
            let licenseAttribute = "authorized_" + session.province_current.toLowerCase();
            licensed = branding[licenseAttribute] === "yes" ? "yes" : "no";
            console.info("license info", licenseAttribute, branding[licenseAttribute]);

            let newSessionEntry = {};
            if (undefined === session.disqualified) {
                newSessionEntry.disqualified = {};
            } else {
                newSessionEntry.disqualified = session.disqualified;
            }

            if ("yes" === licensed) {
                delete newSessionEntry.disqualified.jurisdiction;
            } else {
                newSessionEntry.disqualified.jurisdiction = "yes";
            }
            setSession(newSessionEntry);

        }


    }, [session.province_current, session.disqualified, branding]);


    const moveForward = () => {



        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);

    }

    const validate = () => {

        let newHighlighted = {};
        let invalid = 0;

        for (let [k, v] of Object.entries(highlighted)) {
            newHighlighted[k] = undefined === session[k] || session[k].length < 2;
            if (newHighlighted[k]) {
                console.info("Invalid", k, v);
                invalid++;
            }
        }

        if (0 === invalid) {
            setValidated(true);
        }

        setHighlighted(newHighlighted);

    }

    const onChange = (controlId, value) => {

        let newAddress = {};

        if ("province_check" === controlId) {
            setSameProvince(value);
            if ("Yes" === value) {
                newAddress.province_birth = session.province_current;
            }
        } else {
            newAddress[controlId] = value;
        }

        /*
        if (["province_birth", "province_current"].includes(controlId)) {
            for (let province of insuranceData.jurisdictions.provinces) {
                if (province.statecode.toLowerCase() === value.toLowerCase()) {
                    newAddress[controlId + "_id"] = province.id;
                }
            }
        }
        */

        if (["province_birth", "province_current"].includes(controlId)) {
            let found = false;
            let provinceName;
            for (let province of insuranceData.jurisdictions.provinces) {
                if (province.statecode.toLowerCase() === value.toLowerCase()) {
                    newAddress[controlId + "_id"] = province.id;
                    provinceName = province.name;
                    found = true;
                }
            }
            if (!found) {
                newAddress[controlId + "_id"] = "x";
            }

            if ("province_current" === controlId) {

                newAddress.province = provinceName;

                if ("x" === newAddress["province_current_id"]) {
                    if (undefined === session.disqualified) {
                        newAddress.disqualified = {};
                    } else {
                        newAddress.disqualified = session.disqualified;
                    }
                    newAddress.disqualified.residence = "yes";
                } else {
                    if (undefined !== session.disqualified && undefined !== session.disqualified.residence) {
                        newAddress.disqualified = session.disqualified;
                        delete newAddress.disqualified.residence;
                    }
                }

            }


        }

        if (Object.keys(newAddress).length > 0) {
            setSession(newAddress);
        }


    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Tell us about yourself</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <ProvinceControl highlighted={highlighted.province_current} helpText="This is your current province of residence." controlId="province_current" caption="Province of residence" onChange={onChange} choices={{ default: session.province_current }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.city} helpText="Your current city of residence." controlId="city" caption="City of residence" onChange={onChange} choices={{ default: session.city }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-6 col-sm-10'>
                        <TextControl highlighted={highlighted.address1} helpText="Your current street address." controlId="address1" caption="Street address" onChange={onChange} choices={{ default: session.address1 }} />
                    </Col>
                    <Col className='col-md-4 col-sm-10'>
                        <PostalCodeControl highlighted={highlighted.postal_code} helpText="Your postal code." controlId="postal_code" caption="Postal code" onChange={onChange} choices={{ defaultValue: session.postal_code }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.country} helpText="Your country of residence." controlId="country" caption="Country" onChange={onChange} choices={{ default: session.country }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={false} helpText="Answer no if you were born in another province or country." controlId="province_check" caption="Were you born in your current province of residence?" onChange={onChange} choices={{ items: { Yes: "yes", No: "no" }, default: sameProvince }} />
                    </Col>
                </Row>
                {"No" === sameProvince &&
                    <Row>
                        <Col className='col-md-12 col-lg-10'>
                            <ProvinceControl highlighted={highlighted.province_birth} helpText="May or may not be the same as your current province of residence." controlId="province_birth" caption="In which province were you born?" onChange={onChange} choices={{ default: session.province_birth }} />
                        </Col>
                    </Row>
                }

                <Row>
                    <Col className='col-12'>
                        {!locked &&
                            <>
                                {validated ?
                                    <Button id="address-continue" onClick={() => moveForward()} variant="primary">Continue</Button>
                                    :
                                    <Button onClick={() => validate()} variant="outline-primary">Continue</Button>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}


