import { React } from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { FaRegWindowClose } from 'react-icons/fa';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    SessionContext,
} from '../app/context';

export const Faq = ({ setViewMode }) => {


    let navigate = useNavigate();

    return (
        <Container className="text-primary">
            <Row>
                <hr />
                <Col>
                    <h5 id="faq-title">Frequently Asked Questions
                        <small className="float-end clicker">
                            <FaRegWindowClose onClick={(e) => navigate("/quoter")} />
                        </small>

                    </h5>

                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>What's a premium waiver for total disability?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        A waiver of premium for total disability is an optional benefit provision that is designed to waive the premium if the life insured becomes totally disabled prior to attaining age 60 and remains disabled for 4 consecutive months.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>I'm a smoker. Is that a problem?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        Smoking does not disqualify you from getting insured but the premium will be adjusted to account for the additional risk.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>It says that the price is guaranteed for the entire term. What happens after that?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        You can remain insured but your monthly premiums will likely increase annually at the end of the 10 or 20-year term period. Don't worry, you will be notified ahead of time, and you can see the details of the rate increase in the premium schedule as part of your life policy contract.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <Button onClick={(e) => navigate("/quoter")} variant="outline-primary">Close</Button>
                </Col>
            </Row>
        </Container>
    )
}


