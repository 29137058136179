import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import {
  BsChevronRight,
  BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  BrandingContext,
  InsuranceDataContext,
  LayoutContext,
  SessionContext,
} from '../app/context';
import {
  ageNearest,
  prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
  asCurrency,
  isEmail,
  isPhone,
} from '../libs/utils';
import {
  CalendarControl,
  DollarControl,
  EmailControl,
  PhoneNumberControl,
  RadioControl,
  TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { Faq } from './faq';
import { DefaultQuoter } from './quoters';

export const Quoter = () => {

  const { session } = useContext(SessionContext);

  return (
    <>

      {session.quote &&
        <DefaultQuoter></DefaultQuoter>
      }
    </>
  )
}


const PriceCard = ({ preview, controlId, term, tagline, monthly, riderPrice, riderSelected, featured, onSelection }) => {

  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext);
  const [optin, setOptin] = useState(false);
  const [total, setTotal] = useState("");

  const [labels, setLabels] = useState({
    button: "Buy now!",
    header: "Price guaranteed for " + term + "!",
    footer: "Great buy"
  });

  useEffect(() => {
    if (branding.ab) {
      let newLabels = { ...labels };
      let updated = false;
      switch (term) {
        case "10 years":
          if (branding.quoter_term10header) {
            newLabels.header = branding.quoter_term10header;
            updated = true;
          }
          if (branding.quoter_term10button) {
            newLabels.button = branding.quoter_term10button;
            updated = true;
          }
          if (branding.quoter_term10footer) {
            newLabels.footer = branding.quoter_term10footer;
            updated = true;
          }
          break;
        case "20 years":
          if (branding.quoter_term20header) {
            newLabels.header = branding.quoter_term20header;
            updated = true;
          }
          if (branding.quoter_term20button) {
            newLabels.button = branding.quoter_term20button;
            updated = true;
          }
          if (branding.quoter_term20footer) {
            newLabels.footer = branding.quoter_term20footer;
            updated = true;
          }
          break;
        default:
          break;
      }
      if (updated) {
        setLabels(newLabels);
      }
    }
  }, [branding]);

  useEffect(() => {
    setOptin(riderSelected);
  }, [riderSelected]);
  // {branding.quoter_waiver || "Include premium waiver for total disability"}
  useEffect(() => {

    let newTotalValue = monthly;
    if (optin) {
      newTotalValue = parseFloat(monthly.toString()) + parseFloat(riderPrice.toString());
    } else {
      newTotalValue = parseFloat(monthly.toString());
    }
    let newTotal = asCurrency(newTotalValue);
    setTotal(newTotal);
  }, [monthly, riderPrice, optin]);


  const onChange = (e) => {
    setOptin(e.target.checked);
  }

  const onClick = (e) => {
    let selection = {
      premium: total,
      rider: optin ? "yes" : "no",
      term: controlId.replace("term", "")
    };
    onSelection(selection);
  }

  return (
    <Card border={featured ? "primary" : "secondary"}>
      <Card.Header>
        {labels.header}
      </Card.Header>
      <Card.Body>



        <h2 className="card-title pricing-card-title">
          {preview ?
            total.replace(new RegExp("[0-9]", "g"), "?")
            :
            total
          }

          <small className="text-muted fw-light">/mo</small></h2>


        {preview ?
          <Button id={"buy-" + controlId} disabled className='mt-3 disabled' variant="outline-primary"><BsChevronRight /> {labels.button}</Button>
          :
          <Button id={"buy-" + controlId} onClick={(e) => onClick(e)} className='mt-3' variant="primary"><BsChevronRight /> {labels.button}</Button>
        }

      </Card.Body>
      {/* <Card.Footer>
          {labels.footer}
        </Card.Footer> */}

    </Card>


  );
}

const Teaser = ({ offers, onSelection, setViewMode, viewMode }) => {

  const { session, setSession } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext);
  const [riderSelected, setRiderSelected] = useState(false);
  const [riders, setRiders] = useState(null);
  const [baseAmounts, setBaseAmounts] = useState(null);
  const [blurAmounts, setBlurAmounts] = useState({
    tem10: "$0",
    tem20: "$0"
  })

  const [preview, setPreview] = useState(false);


  useEffect(() => {

    if (session.quoter_mode) {

      switch (session.quoter_mode) {
        case "teaser":
          if ("yes" === session.teaser) {
            setPreview(true);
          } else {
            setPreview(false);
          }

          break;
        default:
          setPreview(false);
      }

    }

  }, [session.quoter_mode, session.teaser]);


  useEffect(() => {

    console.info("Setting preview", preview);

  }, [preview]);



  useEffect(() => {

    let terms = ["term10", "term20"];
    let newBaseAmounts = { ...baseAmounts };
    let newRiders = { ...riders };

    for (let term of terms) {

      if (undefined !== offers[term]) {


        if ("Yes" === session.smoker) {
          newBaseAmounts[term] = offers[term].smoker.norider.monthly;
          newRiders[term] = offers[term].smoker.rider.monthly - offers[term].smoker.norider.monthly;

        } else {
          newBaseAmounts[term] = offers[term].nonsmoker.norider.monthly;
          newRiders[term] = offers[term].nonsmoker.rider.monthly - offers[term].nonsmoker.norider.monthly;
        }

      } else {
        newBaseAmounts[term] = null;
        newRiders[term] = null;
      }

    }


    setBaseAmounts(newBaseAmounts);
    setRiders(newRiders);

    let newBlurAmounts = {
      term10: "$" + newBaseAmounts.term10.toString().replace(new RegExp("[0-9]", "g"), "?"),
      term20: "$" + newBaseAmounts.term20.toString().replace(new RegExp("[0-9]", "g"), "?")
    };

    setBlurAmounts(newBlurAmounts);



  }, [offers.term10, offers.term20]);


  const onRiderChange = (e) => {
    setRiderSelected(e.target.checked);
  }



  return (
    <>
      {baseAmounts &&
        <Container fluid>
          <Row className='mt-4'>
            <Col className={preview ? "blur-me" : ""}>
              <PriceCard preview={preview} controlId={"term10"} term="10" monthly={baseAmounts.term10} riderPrice={riders.term10} riderSelected={riderSelected} featured={false} onSelection={(e) => console.info(e)} />
            </Col>
            <Col className={preview ? "blur-me" : ""}>
              <PriceCard preview={preview} controlId={"term20"} term="20" monthly={baseAmounts.term20} riderPrice={riders.term20} riderSelected={riderSelected} featured={false} onSelection={(e) => console.info(e)} />
            </Col>
          </Row>
          {!preview &&

            <Row className='mt-4 mb-4'>
              <Col className='col-12'>
                <h5>
                  {branding.quoter_waivertitle || "Extra peace of mind?"}
                </h5>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Check onChange={(e) => onRiderChange(e)}
                      type="switch"
                      id="custom-switch"
                      label={branding.quoter_waiver || "Include premium waiver for total disability"}
                    />
                  </Card.Body>
                </Card>
              </Col>

            </Row>

          }
        </Container>
      }
    </>
  )

}
