import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    Col,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { BsArrowLeftRight } from 'react-icons/bs';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    PhysicalContext,
    SessionContext,
} from '../app/context';
import {
    toCm,
    toFeet,
    toKg,
    toLbs,
} from '../libs/utils';

export const ImperialHeight = ({ highlighted }) => {

    const { physical, setPhysical } = useContext(PhysicalContext);
    const [feet, setFeet] = useState(5);
    const [inches, setInches] = useState(0);


    useEffect(() => {
        setFeet(physical.feet);
        setInches(physical.inches);
    }, [physical.feet, physical.inches]);

    let feetItems = [4, 5, 6, 7].map((item) =>
        <option value={item} key={item}>{item} ft</option>
    );

    let inchItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) =>
        <option value={item} key={item}>{item} in</option>
    );

    const onValueChange = (e) => {
        let newValue = e.target.value;
        let newPhysical = { ...physical };
        newPhysical[e.target.id] = newValue;
        if (newValue) {
            newPhysical.height = newPhysical.feet + "'" + newPhysical.inches;
        }

        setPhysical(newPhysical);
    }

    const toMetric = function () {
        return toCm({ feet: feet, inches: inches });
    }

    const toggleUnits = (syncOnly) => {
        let newPhysical = { ...physical };
        newPhysical.cm = toMetric();
        newPhysical.height = newPhysical.cm + " cm";
        if (undefined === syncOnly) {
            newPhysical.units.height = "metric";
        }
        newPhysical.cm = toMetric();
        setPhysical(newPhysical);
    }

    return (

        <Row>
            <Col className='col-4'>
                <Form.Select aria-label="feet" id="feet" value={feet} onChange={(e) => onValueChange(e)}>
                    <option disabled value="0" key="0">Choose:</option>
                    {feetItems}
                </Form.Select>
            </Col>
            <Col className='col-4'>
                <Form.Select aria-label="inches" id="inches" value={inches} onChange={(e) => onValueChange(e)}>
                    {inchItems}
                </Form.Select>
            </Col>
            <Col className='col-3'>
                <Badge id="unit-toggle-cm" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> cm</Badge>
            </Col>
        </Row>

    )

}


export const MetricHeight = ({ highlighted }) => {

    const { physical, setPhysical } = useContext(PhysicalContext);
    //const [cm, setCm] = useState(0);

    // useEffect(() => {

    //     if (physical.cm) {
    //         setCm(physical.cm);
    //     } else {
    //         setCm(0);
    //     }

    // }, [physical.cm]);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 225) {
                newValue = "0";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newPhysical = { ...physical };
        newPhysical.cm = newValue;
        if (newValue) {
            newPhysical.height = newValue + " cm";
        }

        // we want to save the alternate unit right away
        let imperialValue = fromMetric(newPhysical.cm);
        newPhysical.feet = imperialValue.feet;
        newPhysical.inches = imperialValue.inches;


        setPhysical(newPhysical);
    }

    const fromMetric = function (cm) {
        return toFeet({ cm: cm });
    }

    const toggleUnits = (syncOnly) => {
        let newPhysical = { ...physical };
        let imperial = fromMetric(newPhysical.cm);
        newPhysical.feet = imperial.feet;
        newPhysical.inches = imperial.inches;
        newPhysical.height = newPhysical.feet + "'" + newPhysical.inches;
        if (undefined === syncOnly) {
            newPhysical.units.height = "imperial";
        }
        setPhysical(newPhysical);
    }

    return (

        <Row>
            <Col className='col-8'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
                    <Form.Control type="text" placeholder="Enter height in cm" value={physical.cm} onChange={e => onValueChange(e)} />
                </InputGroup>


            </Col>
            <Col>
                <Badge id="unit-toggle-ft" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> ft</Badge>
            </Col>
        </Row>

    )

}



export const ImperialWeight = ({ highlighted }) => {

    const { physical, setPhysical } = useContext(PhysicalContext);
    const [lbs, setLbs] = useState(0);

    useEffect(() => {

        if (physical.lbs) {
            setLbs(physical.lbs);
        } else {
            setLbs(0);
        }

    }, [physical.lbs]);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newPhysical = { ...physical };
        newPhysical.lbs = newValue;
        if (newValue) {
            newPhysical.weight = newValue + " lbs";
        }


        // we want to save the alternate unit right away
        newPhysical.kg = fromImperial();

        setPhysical(newPhysical);




    }

    const fromImperial = function () {
        return toKg({ lbs: lbs });
    }

    const toggleUnits = (syncOnly) => {
        let newPhysical = { ...physical };
        newPhysical.kg = fromImperial();
        newPhysical.weight = newPhysical.kg + " kg";
        if (undefined === syncOnly) {
            newPhysical.units.weight = "metric";
        }
        setPhysical(newPhysical);
    }

    return (

        <Row>
            <Col className='col-8'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">lbs</InputGroup.Text>
                    <Form.Control type="text" placeholder="Enter weight in lbs" value={lbs} onChange={e => onValueChange(e)} />
                </InputGroup>
            </Col>
            <Col>
                <Badge id="unit-toggle-kg" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> kg</Badge>
            </Col>
        </Row>

    )

}


export const MetricWeight = ({ highlighted }) => {

    const { physical, setPhysical } = useContext(PhysicalContext);
    const [kg, setKg] = useState(0);

    useEffect(() => {

        if (physical.kg) {
            setKg(physical.kg);
        } else {
            setKg(0);
        }

    }, [physical.kg]);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newPhysical = { ...physical };
        newPhysical.kg = newValue;
        if (newValue) {
            newPhysical.weight = newValue + " kg";
        }

        // we want to save the alternate unit right away
        newPhysical.lbs = fromMetric();


        setPhysical(newPhysical);
    }

    const fromMetric = function () {
        return toLbs({ kg: kg });
    }

    const toggleUnits = (syncOnly) => {
        let newPhysical = { ...physical };
        newPhysical.lbs = fromMetric();
        newPhysical.weight = newPhysical.lbs + " lbs";
        if (undefined === syncOnly) {
            newPhysical.units.weight = "imperial";
        }
        setPhysical(newPhysical);
    }

    return (

        <Row>
            <Col className='col-8'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                    <Form.Control type="text" placeholder="Enter weight in kg" value={kg} onChange={e => onValueChange(e)} />
                </InputGroup>
            </Col>
            <Col>
                <Badge id="unit-toggle-lb" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> lbs</Badge>
            </Col>
        </Row>

    )

}


