import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Accordion,
    Button,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { extractQuestions } from '../libs/utils';
import { DetailsForm } from '../screen/wizards';

export const MedicalInfo = (props) => {

    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [questions, setQuestions] = useState([]);
    const [heading, setHeading] = useState("");
    const [parentQuestion, setParentQuestion] = useState(false);
    const [clicked, setClicked] = useState([]);
    const [helpVisible, setHelpVisible] = useState(false);
    const [viewMode, setViewMode] = useState("accordion");
    const [outcome, setOutcome] = useState("incomplete");

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (undefined !== insuranceData.bundled && undefined !== insuranceData.bundled["diagnostic"]) {
            let newQuestions = extractQuestions(insuranceData.bundled["diagnostic"], "sorted");
            let newHeading;
            let newParentQuestion;
            if (undefined !== insuranceData.bundled["diagnostic"].questions) {
                for (let item of insuranceData.bundled["diagnostic"].questions) {
                    if ("heading" === item.type) {
                        newHeading = item.copy;
                        newParentQuestion = item.id;
                    }
                }
            }
            setQuestions(newQuestions);
            setHeading(newHeading);
            setParentQuestion(newParentQuestion);
        }

    }, [insuranceData]);

    useEffect(() => {

        if ("incomplete" !== outcome) {
            if (helpVisible) {
                setHelpVisible(false);
            }
        }

    }, [outcome]);

    const onSave = (e) => {
        //todo refactor

        let newSessionEntry = getNextStep(location, { ...session });


        if ("disqualified" === outcome) {

            newSessionEntry["question_" + parentQuestion] = {
                "details": e,
                "path": "queue",
                "text": "Yes"
            }

            if (undefined === session.disqualified) {
                newSessionEntry.disqualified = {};
            } else {
                newSessionEntry.disqualified = { ...session.disqualified };
            }
            if (undefined === newSessionEntry.disqualified.questions) {
                newSessionEntry.disqualified.questions = [];
            }
            newSessionEntry.disqualified.questions.push(parentQuestion);
        } else {
            if (undefined !== session.disqualified) {
                if (undefined !== newSessionEntry.disqualified.questions) {
                    if (newSessionEntry.disqualified.questions.includes(parentQuestion)) {
                        // at some point the customer answers Yes to some of the subquestions, moved on, and came back to change it
                        // so we have to discard the disqualification
                        let newDisqualified = [];
                        for (let question of newSessionEntry.disqualified.questions) {
                            if (parentQuestion !== question) {
                                newDisqualified.push(question);
                            }
                        }
                        newSessionEntry.disqualified.questions = newDisqualified;
                    }
                }
            }
        }

        setSession(newSessionEntry);
        navigate(newSessionEntry.next);

    }

    const showHelp = () => {

        setHelpVisible(true);
        setTimeout(() => {
            setHelpVisible(false);
        }, 5000);
    }

    return (
        <Container fluid>

            {"details" === viewMode ?
                <>
                    <DetailsForm onCancel={(e) => setViewMode("accordion")} onSave={onSave} />
                </>
                :
                <>
                    <Row className='mt-4'>
                        <Col>
                            <h5>
                                Have you ever been treated for, diagnosed, consulted a doctor, received abnormal test results, or experienced symptoms of the following?
                            </h5></Col>
                    </Row>
                    {helpVisible &&
                        <Row className='mt-4'>
                            <Col>
                                <Form.Text className="text-muted">
                                    Please review all seven (7) categories.
                                </Form.Text>
                            </Col>
                        </Row>
                    }
                    <Row className='mt-4'>
                        <Col>
                            <RadioAccordion questions={questions} setOutcome={setOutcome} />

                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        {"incomplete" == outcome ?
                            <Col>
                                <Button onClick={(e) => showHelp()} variant="outline-primary" size="lg">Continue</Button>
                            </Col>
                            :
                            <Col>
                                <Button onClick={() => onSave()} variant="primary" size="lg">Continue</Button>
                            </Col>
                        }

                    </Row>

                </>
            }

        </Container>
    )

}

const RadioAccordion = ({ questions, setOutcome }) => {

    const { session, setSession } = useContext(SessionContext);
    const [activeKey, setActiveKey] = useState(false);
    const [answers, setAnswers] = useState({});
    const [ready, setReady] = useState(false);


    useEffect(() => {

        if (questions.length > 0) {
            let newAnswers = {};

            for (let question of questions) {
                if (undefined !== session["question_" + question.id]) {
                    newAnswers["question_" + question.id] = session["question_" + question.id];
                } else {
                    newAnswers["question_" + question.id] = {};
                }
            }

            setAnswers(newAnswers);
            changeFocus();
            setReady(true);
        }

    }, [questions]);

    useEffect(() => {

        if (Object.entries(answers).length > 0) {
            changeFocus();
            if (Object.entries(answers).length === questions.length) {
                // everything has been answered
                let disqualified = [];
                let qualified = [];

                for (let [question_id, answer] of Object.entries(answers)) {
                    switch (answer.text) {
                        case "No":
                            qualified.push(answer.id);
                            break;
                        case "Yes":
                            disqualified.push(answer.id);
                            break;
                        default:
                            break;
                    }
                }
                if (disqualified.length > 0) {
                    setOutcome("disqualified");
                } else {
                    if (qualified.length === questions.length) {
                        setOutcome("completed");
                    } else {
                        setOutcome("incomplete");
                    }

                }
            } else {
                setOutcome("incomplete");
            }
        } else {
            setOutcome("incomplete");
        }

    }, [answers]);

    const changeFocus = () => {

        for (let question of questions) {
            if (undefined === answers["question_" + question.id] || !["Yes", "No"].includes(answers["question_" + question.id].text)) {
                setActiveKey("question_" + question.id);
                break;
            }
        }

    }

    const onCheck = (question, choice, text) => {
        //todo refactor to use bundle path
        let newAnswers = { ...answers };
        let newSessionEntry = {};
        newAnswers["question_" + question] = {
            id: choice,
            text: text,
            path: "Yes" === text ? "queue" : "application"
        };
        setAnswers(newAnswers);
        newSessionEntry["question_" + question] = newAnswers["question_" + question];
        setSession(newSessionEntry);
    }


    return (
        <Container fluid>
            {ready ?
                <Row>
                    <Col>
                        <Accordion activeKey={activeKey} flush>
                            {questions.map((question, index) =>

                                <Accordion.Item key={index + 1} eventKey={"question_" + question.id}>

                                    <Accordion.Header className="bg-info" onClick={() => setActiveKey("question_" + question.id)}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    {index + 1} of {questions.length}
                                                </Col>
                                                <Col className='text-end text-right'>
                                                    {answers["question_" + question.id] && ["Yes", "No"].includes(answers["question_" + question.id].text) &&
                                                        <>
                                                            <small className='text-muted'>your answer: {answers["question_" + question.id].text}</small>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div>
                                            {question.copy}
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <Form.Check
                                                inline
                                                label="No"
                                                name={"question_" + question.id}
                                                type="radio"
                                                id={question.no}
                                                onChange={(e) => onCheck(question.id, question.no, "No")}
                                                checked={answers["question_" + question.id] && "No" === answers["question_" + question.id].text}
                                            />

                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name={"question_" + question.id}
                                                type="radio"
                                                id={question.yes}
                                                onChange={(e) => onCheck(question.id, question.yes, "Yes")}
                                                checked={answers["question_" + question.id] && "Yes" === answers["question_" + question.id].text}
                                            />

                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>

                            )}
                        </Accordion>
                    </Col>
                </Row>
                :
                <></>
            }
        </Container>
    );

}