import {
    React,
    useContext,
    useEffect,
} from 'react';

import {
    Card,
    CardGroup,
    Col,
    Container,
    Image,
    Row,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';

export const Congratulations = (props) => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const { session, setSession } = useContext(SessionContext);

    //let navigate = useNavigate();
    //let { search } = useLocation();

    useEffect(() => {

        let newLayout = { ...layout };
        newLayout.sidebar = "no";
        setLayout(newLayout);

        let now = Date.now();

        let newSessionEntry = {
            congratulations_started: now,
            outcome: "sale",
            workflow: "Application"
        };

        setSession(newSessionEntry);

        // let conversion = {
        //     quote: session.quote,
        //     broker: session.broker,
        //     workflow: "Application",
        //     ts: Date.now()
        // };

        //saveConversion(redflag);

        /*
                ReactGA.initialize(branding.ua);
                ReactGA.event({
                    category: 'Unhappy path',
                    action: 'conclusion',
                    label: 'mismatch',
                    value: quote.term
                });
        */

    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>

                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Congratulations!</Card.Title>
                                    <Card.Text>
                                        Your insurance policy has been approved!
                                    </Card.Text>
                                    {session.policy_number &&
                                        <Card.Text>
                                            <small>Policy number: {session.policy_number}</small>
                                        </Card.Text>
                                    }
                                    <h5><BsTelephone /> <small>{branding.phone}</small></h5>
                                    <h5><HiOutlineMail /> <small>{branding.email}</small></h5>
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    Keep an eye on your inbox for a copy of your policy!
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image fluid src="/images/end_umbrella.jpg" />
                                </Card.Body>
                            </Card>
                        </CardGroup>

                    </Col>
                </Row>
            </Container>

        </>
    )

}

