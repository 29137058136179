import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { assets } from '../config.js';
import { getNextStep } from '../libs/routing.js';

//import { LoadingBoxes } from './loader';

const LoadingBoxes = ({ brandingOk, launch }) => {

    useEffect(() => {

        if (brandingOk) {
            launch();
        }


    }, [brandingOk]);

    return (<></>)
}

export const Home = ({ initialized, ready }) => {

    const [brandingReady, setBrandingReady] = useState(false);
    const [launched, setLaunched] = useState(false);
    const [brandingOk, setBrandingOk] = useState(false);
    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    //const { insuranceData } = useContext(InsuranceDataContext);

    let location = useLocation();
    let navigate = useNavigate();

    const applyBranding = () => {

        if (branding.broker) {


            // deal with background video                        
            if ("none" !== branding.videoFile) {
                let element = document.getElementById("body");
                //element.style.backgroundColor = "black";
                new Image().src = branding.videoFile;
            }

            // waiting video
            new Image().src = "/videos/working.mp4";
            new Image().src = "/videos/typing.mp4";

            if (branding.introduction) {
                // pre-load the introduction video
                let videoLink = document.createElement("link");
                videoLink.rel = "prefetch";
                videoLink.href = "https://www.youtube.com/embed/" + branding.introduction;
                //videoLink.as = "video";
                document.getElementsByTagName("head")[0].appendChild(videoLink);
            }

            // pre-load the logo
            let logoPath = assets + "/" + branding.brand + "/" + branding.ab + ".png";

            new Image().src = logoPath;



            let acceptableThemes = {
                "cerulean": "/vendor/cerulean.min.css",
                "cyborg": "/vendor/cyborg.min.css",
                "darkly": "/vendor/darkly.min.css",
                "flatly": "/vendor/flatly.min.css",
                "litera": "/vendor/litera.min.css",
                "lumen": "/vendor/lumen.min.css",
                "lux": "/vendor/lux.min.css",
                "materia": "/vendor/materia.min.css",
                "minty": "/vendor/minty.min.css",
                "morph": "/vendor/morph.min.css",
                "quartz": "/vendor/quartz.min.css",
                "spacelab": "/vendor/spacelab.min.css",
                "united": "/vendor/united.min.css",
                "vapor": "/vendor/vapor.min.css",
                "yeti": "/vendor/yeti.min.css",
                "zephyr": "/vendor/zephyr.min.css"
            };

            let customTheme = 'cerulean'; //default

            if (branding.theme) {

                let themeName = branding.theme.toLowerCase().split(' ').pop();
                if (acceptableThemes[themeName] !== undefined) {
                    customTheme = themeName;
                }

            }


            if (branding.font) {

                let customFont;

                let acceptableFonts = {
                    "Arial": "Arial, sans-serif",
                    "Verdana": "Verdana, sans-serif",
                    "Helvetica": "Helvetica, sans-serif",
                    "Tahoma": "Tahoma, sans-serif",
                    "Trebuchet MS": "'Trebuchet MS', sans-serif",
                    "Times New Roman": "'Times New Roman', serif",
                    "Georgia": "Georgia, serif",
                    "Garamond": "Garamond, serif",
                    "Comic Sans": "'Comic Sans MS', 'Comic Sans', cursive",
                    "Courier New": "'Courier New', monospace",
                    "Brush Script MT": "'Brush Script MT', cursive"
                };

                for (let [fontName, fontCss] of Object.entries(acceptableFonts)) {
                    if (branding.font.endsWith(fontName)) {
                        customFont = fontCss;
                    }
                }

            }

            // apply the background image on desktop only
            // if ("standard" === layout.device) {
            //     let backgroundImage = branding.background ? branding.background : "bg_neutral";
            //     backgroundImage = backgroundImage.replace('.jpg', ''); // just in case
            //     document.body.style.backgroundImage = `url('/images/${backgroundImage}.jpg')`;

            //     let cssLink = document.createElement("link");
            //     cssLink.id = "brandingcss";
            //     cssLink.href = "/desktop.css";
            //     cssLink.type = "text/css";
            //     cssLink.rel = "stylesheet";
            //     cssLink.media = "screen,print";
            //     document.getElementsByTagName("head")[0].appendChild(cssLink);

            // }

            setBrandingOk(true);
        }

    }

    const launch = () => {

        if (!launched) {
            setLaunched(true);
        }

    }

    useEffect(() => {

        if (ready) {
            applyBranding();
        }

    }, [ready]);

    useEffect(() => {

        if (launched) {
            setBrandingReady(true);

            let newLayout = { ...layout };
            newLayout.mode = "focus";
            newLayout.navbar = "yes";
            newLayout.stage = "main";
            newLayout.ready = "yes";
            newLayout.pct = 20;


            setLayout(newLayout);


            let flow = getNextStep(location, session);
            let newSessionEntry = { ...flow };
            setSession(newSessionEntry);
            navigate(flow.next);


        }

    }, [launched]);

    return (
        <>
            {brandingReady ||
                <LoadingBoxes ready={ready} brandingOk={brandingOk} launch={launch} />
            }
        </>
    )
}

