import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    BsChevronRight,
    BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import {
    ageNearest,
    prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
    asCurrency,
    isEmail,
    isPhone,
} from '../libs/utils';
import {
    CalendarControl,
    DollarControl,
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { ContactForm } from './contact';
import { CoverageSlider } from './coverage';
import { Faq } from './faq';
import { PromoCarousel } from './promo-carousel';

export const BasicConsentForm = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const [hasConsent, setHasConsent] = useState(false);

    useEffect(() => {

        if (session.privacy) {

            if (["Yes", "yes"].includes(session.privacy)) {
                setHasConsent("yes");
            } else {
                setHasConsent("no");
            }

        } else {
            setHasConsent(false);
        }


    }, [session.privacy]);

    const onChange = (controlId, value) => {
        console.info("Saving privacy");
        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);
    }


    return (
        <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>

            {"mobile" === layout.device ?
                <>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            Do we have your consent to collect, use, and share the personal information you will provide to us as described in our <a target="_blank" rel="noopener noreferrer" href="https://westernfinancialgroup.ca/Privacy">Privacy Policy</a>?

                            <div className='mt-1'>

                                <Form.Check
                                    key="privacy-yes"
                                    checked={'yes' === hasConsent}
                                    inline
                                    label="Yes"
                                    name="privacy"
                                    type="radio"
                                    id="privacy1"
                                    onChange={(e) => onChange('privacy', 'Yes')}
                                    aria-label="Yes"
                                />

                                <Form.Check
                                    key="privacy-no"
                                    checked={'no' === hasConsent}
                                    inline
                                    label="No"
                                    name="privacy"
                                    type="radio"
                                    id="privacy2"
                                    onChange={(e) => onChange('privacy', 'No')}
                                    aria-label="Yes"
                                />

                            </div>

                        </Col></Row>
                </>
                :
                <>
                    <Row>
                        <Col className='col-md-12 col-lg-12'>
                            Do we have your consent to collect, use, and share the personal information you will provide to us as described in our <a target="_blank" rel="noopener noreferrer" href="https://westernfinancialgroup.ca/Privacy">Privacy Policy</a>?

                            <div className='mt-2'>

                                <Form.Check
                                    key="privacy-yes"
                                    checked={'yes' === hasConsent}
                                    inline
                                    label="Yes"
                                    name="privacy"
                                    type="radio"
                                    id="privacy1"
                                    onChange={(e) => onChange('privacy', 'Yes')}
                                    aria-label="Yes"
                                />

                                <Form.Check
                                    key="privacy-no"
                                    checked={'no' === hasConsent}
                                    inline
                                    label="No"
                                    name="privacy"
                                    type="radio"
                                    id="privacy2"
                                    onChange={(e) => onChange('privacy', 'No')}
                                    aria-label="Yes"
                                />

                            </div>


                        </Col>

                    </Row>
                </>
            }






        </Container>
    )

}

export const BasicConsentForm2 = () => {

    const { session, setSession } = useContext(SessionContext);
    const [hasConsent, setHasConsent] = useState(false);

    useEffect(() => {

        if (session.privacy) {

            if ("yes" === session.privacy) {
                setHasConsent("yes");
            } else {
                setHasConsent("no");
            }

        } else {
            setHasConsent(false);
        }


    }, [session.privacy]);

    const onChange = (controlId, value) => {
        console.info("Saving privacy");
        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);
    }


    return (
        <Container fluid>

            <Row>

                <Col className='col-md-12 col-lg-12'>
                    Do we have your consent to collect, use, and share the personal information you will provide to us as described in our <a target="_blank" rel="noopener noreferrer" href="https://westernfinancialgroup.ca/Privacy">Privacy Policy</a>?

                    <div className='mt-2'>
                        <Form.Check
                            checked={'yes' === hasConsent}
                            inline
                            type="radio"
                            name="privacy"
                            id="privacyYes"
                            label="Yes"
                            onChange={() => onChange('privacy', 'Yes')}
                        />
                        <Form.Check
                            checked={'no' === hasConsent}
                            inline
                            type="radio"
                            name="privacy"
                            id="privacyNo"
                            label="No"
                            onChange={() => onChange('privacy', 'No')}
                        />
                    </div>


                </Col>
            </Row>



        </Container>
    )

}

export const CompactConsentForm = () => {

    const { session, setSession } = useContext(SessionContext);
    const [hasConsent, setHasConsent] = useState(false);

    useEffect(() => {

        if (session.privacy) {

            if ("yes" === session.privacy) {
                setHasConsent("yes");
            } else {
                setHasConsent("no");
            }

        } else {
            setHasConsent(false);
        }


    }, [session.privacy]);

    const onChange = (controlId, value) => {
        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);
    }


    return (
        <Container fluid>

            <Row>

                <Col className='col-md-12 col-lg-12'>
                    <small>Do we have your consent to collect, use, and share the personal information you will provide to us as described in our <a target="_blank" rel="noopener noreferrer" href="https://westernfinancialgroup.ca/Privacy">Privacy Policy</a>?
                    </small>
                    <div className='float-end mt-2'>
                        <Form.Check
                            checked={'yes' === hasConsent}
                            inline
                            type="radio"
                            id="privacy"
                            label="yes"
                            onChange={() => onChange('privacy', 'yes')}
                        />
                        <Form.Check
                            checked={'no' === hasConsent}
                            inline
                            type="radio"
                            id="privacy"
                            label="no"
                            onChange={() => onChange('privacy', 'no')}
                        />
                    </div>


                </Col>
            </Row>



        </Container>
    )

}