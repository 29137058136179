import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { RadioControl } from '../screen/controls';

export const Privacy = () => {

    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const [locked, setLocked] = useState(true);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {


    }, [session.privacy]);

    const mustTerminate = () => {
        let eject = false;
        let newSession = {};
        if (undefined === session.disqualified) {
            newSession.disqualified = {};
        } else {
            newSession.disqualified = { ...session.disqualified };
        }

        if ("Yes" === session.privacy) {
            delete newSession.disqualified.privacy;
        } else {
            newSession.disqualified.privacy = 'yes';
            eject = true;
        }
        setSession(newSession);
        return eject;
    }

    const moveForward = () => {

        let flow;
        if (mustTerminate()) {
            flow = {
                next: '/opportunities',
                outcome: 'queue'
            }
        } else {
            flow = getNextStep(location, { ...session });
        }

        setSession(flow);
        navigate(flow.next);

    }

    const onChange = (controlId, value) => {

        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);
        console.info("Changed", newSessionEntry);

    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Privacy Consent</h1>
                    </Col>
                </Row>
                {"mobile" === layout.device ?
                    <Row>
                        <Col className='col-12'>
                            <p>
                                In order to offer you products and services from our insurance partners, as well as submit claims and manage related inquiries and services, we will be collecting your personal information from you. This information may be shared with and used by our affiliates and insurance partners to collect data from other sources in order to provide you with ongoing service, market products and services.
                            </p>
                            <p>You understand that you have the option to provide your consent for the collection, use and disclosure of your personal information and that your consent remains valid. You understand that subject to legal or contractual restrictions and with reasonable Notice, you can withdraw consent at any time. </p>
                            <p>By failing to provide your consent for the collection, use and disclosure of your personal information, or by withdrawing your consent, you understand that you may not benefit from the products or services being offered.
                            </p>
                            <p>
                                Additional information on our Privacy Policy can be viewed on <a target="_blank" rel="noopener noreferrer" href={branding.privacyLink}>our website</a>. You can also contact our <a href="mailto:privacy@wawanesa.com">Privacy Office</a> directly.
                            </p>
                            <hr />

                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col className='col-md-12 col-lg-8'>
                            <p>
                                In order to offer you products and services from our insurance partners, as well as submit claims and manage related inquiries and services, we will be collecting your personal information from you. This information may be shared with and used by our affiliates and insurance partners to collect data from other sources in order to provide you with ongoing service, market products and services.
                            </p>
                            <p>You understand that you have the option to provide your consent for the collection, use and disclosure of your personal information and that your consent remains valid. You understand that subject to legal or contractual restrictions and with reasonable Notice, you can withdraw consent at any time. </p>
                            <p>By failing to provide your consent for the collection, use and disclosure of your personal information, or by withdrawing your consent, you understand that you may not benefit from the products or services being offered.
                            </p>
                            <hr />

                        </Col>
                        <Col className='col-md-12 col-lg-4'>
                            <Card>
                                <Card.Header>
                                    Questions?
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>Your privacy matters to us</Card.Title>
                                    <Card.Text>
                                        Additional information on our Privacy Policy can be viewed on <a target="_blank" rel="noopener noreferrer" href={branding.privacyLink}>our website</a>. You can also contact our <a href="mailto:privacy@wawanesa.com">Privacy Office</a> directly.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className='col-md-12 col-lg-8'>
                        <RadioControl highlighted={false} helpText="You can withdraw your consent at any time." controlId="privacy" caption="Do we have your consent to collect, use, and share the personal information you will provide to us as described?" onChange={onChange} choices={{ hideHelpButton: true, items: { Yes: "yes", No: "no" }, default: session.privacy }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        {["No", "Yes"].includes(session.privacy) &&
                            <>
                                <Button onClick={() => moveForward()} variant="primary">Confirm</Button>
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}



