import {
    React,
    useContext,
    useEffect,
} from 'react';

import {
    Button,
    Card,
    CardGroup,
    Col,
    Container,
    Image,
    Row,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';

export const Mismatch = (props) => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const { session, setSession } = useContext(SessionContext);

    //let navigate = useNavigate();
    //let { search } = useLocation();


    const restart = () => {

        document.location.href = "/";
        //let newLayout = { ...layout };
        // newLayout.sidebar = "no";
        // newLayout.stage = "intro";
        // setLayout(newLayout);
        // navigate(`/${search}`);

    }

    useEffect(() => {

        let newLayout = { ...layout };
        newLayout.sidebar = "no";
        setLayout(newLayout);

        let now = Date.now();

        let newSessionEntry = {
            mismatch_started: now,
            outcome: "queue",
            workflow: "Product mismatch"
        };

        setSession(newSessionEntry);

        // let redflag = {
        //     quote: session.quote,
        //     broker: session.broker,
        //     workflow: "Product mismatch",
        //     ts: Date.now()
        // };

        //saveRedFlag(redflag);

        /*
                ReactGA.initialize(branding.ua);
                ReactGA.event({
                    category: 'Unhappy path',
                    action: 'conclusion',
                    label: 'mismatch',
                    value: quote.term
                });
        */

    }, []);


    useEffect(() => {
        if ("intro" === layout.stage || "/opportunities" !== session.next) {
            //navigate("/");
        }
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>

                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Exciting news!</Card.Title>
                                    <Card.Text>
                                        {branding.mismatch || "It looks like there are products that are a better fit for you. Let's get in touch!"}
                                    </Card.Text>
                                    <h5><BsTelephone /> <small>{branding.phone}</small></h5>
                                    <h5><HiOutlineMail /> <small>{branding.email}</small></h5>
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <Button onClick={() => restart()} variant="outline-info">Start over</Button>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image fluid src="/images/queue_hipster.jpg" />
                                </Card.Body>
                            </Card>
                        </CardGroup>

                    </Col>
                </Row>
            </Container>

        </>
    )

}



export const Unqualified = (props) => {


    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const { session, setSession } = useContext(SessionContext);

    //let navigate = useNavigate();
    //    let { search } = useLocation();


    const restart = () => {

        document.location.href = "/";
        // let newLayout = { ...layout };
        // newLayout.sidebar = "no";
        // newLayout.stage = "intro";
        // setLayout(newLayout);

        // navigate(`/${search}`);

    }

    useEffect(() => {

        let newLayout = { ...layout };
        newLayout.sidebar = "no";
        setLayout(newLayout);

        let now = Date.now();

        let newSessionEntry = {
            unqualified_started: now,
            outcome: "stop",
            workflow: "Unqualified lead"
        };

        setSession(newSessionEntry);

        // let redflag = {
        //     quote: session.quote,
        //     broker: session.broker,
        //     workflow: "Unqualified lead",
        //     ts: Date.now()
        // };

        //saveRedFlag(redflag);

        /*
                ReactGA.initialize(branding.ua);
                ReactGA.event({
                    category: 'Unhappy path',
                    action: 'conclusion',
                    label: 'mismatch',
                    value: quote.term
                });
        */

    }, []);

    useEffect(() => {
        if ("intro" === layout.stage || "/thankyou" !== session.next) {
            //navigate("/");
        }
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>

                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Exciting news!</Card.Title>
                                    <Card.Text>
                                        {branding.unqualified || "It looks like there are products that are a better fit for you. Let's get in touch!"}
                                    </Card.Text>
                                    <h5><BsTelephone /> <small>{branding.phone}</small></h5>
                                    <h5><HiOutlineMail /> <small>{branding.email}</small></h5>
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <Button onClick={() => restart()} variant="outline-info">Start over</Button>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image fluid src="/images/stop_omg.jpg" />
                                </Card.Body>
                            </Card>
                        </CardGroup>

                    </Col>
                </Row>
            </Container>

        </>
    )

}

