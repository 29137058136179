import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    InsuranceDataContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { extractQuestions } from '../libs/utils';
import {
    SimpleToggleWizard,
    ToggleWizard,
} from '../screen/wizards';

export const AbormalResults = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["2years"], "toggle");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (newSessionEntry) => {
        let flow = getNextStep(location, { ...session });
        for (let [k, v] of Object.entries(newSessionEntry)) {
            flow[k] = v;
        }
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <ToggleWizard onCompleted={moveForward} questions={questions} title="In the past 2 years, have you been advised of an abnormal result of any of the following?" />
            }
        </>

    )
}


export const Last2years = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["mental"], "toggle");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (newSessionEntry) => {
        let flow = getNextStep(location, { ...session });
        for (let [k, v] of Object.entries(newSessionEntry)) {
            flow[k] = v;
        }
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <SimpleToggleWizard onCompleted={moveForward} questions={questions} title="In the past 2 years, have you:" />
            }
        </>

    )
}
