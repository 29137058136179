import React from 'react';

export const LayoutContext = React.createContext(
    {
        layout: '',
        setLayout: (_) => { }
    }
);


export const InsuranceDataContext = React.createContext(
    {
        insuranceData: '',
        setInsuranceData: (_) => { }
    }
);

export const BrandingContext = React.createContext(
    {
        branding: '',
        setBranding: (_) => { }
    }
);

export const NotificationsContext = React.createContext(
    {
        notifications: '',
        setNotifications: (_) => { }
    }
);



export const ChatContext = React.createContext(
    {
        chat: '',
        setChat: (_) => { }
    }
);


export const ConfigurationContext = React.createContext(
    {
        configuration: '',
        setConfiguration: (_) => { }
    }
);


export const GeoContext = React.createContext(
    {
        geo: '',
        setGeo: (_) => { }
    }
);

export const QuestionnaireContext = React.createContext(
    {
        questions: {},
        setQuestions: (_) => { }
    }
);

export const QuoteContext = React.createContext(
    {
        quote: '',
        setQuote: (_) => { }
    }
);

export const SessionContext = React.createContext(
    {
        session: '',
        setSession: (_) => { }
    }
);

export const ViewContext = React.createContext(
    {
        view: '',
        setView: (_) => { }
    }
);


export const PhysicalContext = React.createContext(
    {
        physical: '',
        setPhysical: (_) => { }
    }
);
