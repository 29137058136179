import {
  React,
  useContext,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { MdOutlineHttps } from 'react-icons/md';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../app/context';
import { asCurrency } from '../libs/utils';
import { TopBar } from './topbar';

export const MobileLayout = (props) => {


  const { layout } = useContext(LayoutContext);
  const { branding } = useContext(BrandingContext);

  let location = useLocation();


  return (
    <>
      <TopBar />
      <Container fluid className="pt-0 pb-0 pl-1 pr-1 mb-0 mt-0">
        <Row>
          {"yes" === layout.mobileVideo &&
            <Col className='col-12'>
              <iframe className='brokerVideo' src={"https://www.youtube.com/embed/" + branding.introduction} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
            </Col>
          }
          <Col className='col-12 main-content'>
            {["/", "/faq", "/analysis", "/quoter", "/consent", "/policy"].includes(location.pathname) ?
              <MobileFocus />
              :
              <MobileSplit />
            }

          </Col>
        </Row>
      </Container>
    </>
  )


}


const MobileFocus = () => {

  return (
    <>
      <Outlet />
      <MobileFooter />
    </>
  );

}


const MobileSplit = () => {
  const { session } = useContext(SessionContext);
  return (
    <>
      <Container fluid>

        <Row>
          <Outlet />
        </Row>
        {"Yes" === session.privacy &&
          <Row className='mt-3 mb-3'>
            <hr />
            <Col className='text-muted'>
              <p>
                {asCurrency(session.facevalue)} coverage for {asCurrency(session.premium)}/mo
              </p>
              <p>
                Price guaranteed for {session.term} years.
              </p>
              <p>
                Cancel anytime.
              </p>
            </Col>
          </Row>
        }
      </Container>
      <MobileFooter />
    </>
  );
}

const MobileFooter = () => {

  const { branding } = useContext(BrandingContext);
  const { layout } = useContext(LayoutContext);
  const { session } = useContext(SessionContext);


  let navigate = useNavigate();
  let location = useLocation();


  const revokeConsent = () => {
    let flow = {
      next: '/privacy',
      privacy: null
    }

    navigate(flow.next);
  }

  return (
    <>
      {session.quote &&
        <>
          <Container fluid className='text-secondary bg-light mt-3'>
            <Row className='pt-2 pb-2'>
              <Col className='text-white'>
                {/* {"Yes" === session.privacy && location.pathname !== "/privacy" &&
              <>
                <p>
                  <u><span className='text-white' onClick={() => revokeConsent()} className='clicker'>Review consent</span></u>
                </p>
              </>
            } */}
                <p>
                  <a className="text-secondary" target="_blank" rel="noopener noreferrer" href={branding.privacyLink}>Legal/Privacy</a>
                </p>
                <p>
                  <a className="text-secondary" target="_blank" rel="noopener noreferrer" href={branding.accessibilityLink}>Accessibility policy</a>
                </p>
                <p>
                  <a className="text-secondary" target="_blank" rel="noopener noreferrer" href={branding.ftcLink}>Fair treatment of customers policy</a>
                </p>
                <p>
                  This life insurance quote is not meant for residents of Quebec | <i>Cette soumission d'assurance-vie n'est pas destinée aux résidents du Québec </i>
                </p>
              </Col>

            </Row>

          </Container>
          <p className='text-info text-center mt-4 mb-2'>
            <MdOutlineHttps /> Your connection is secure
          </p>
        </>
      }
    </>

  )
}