import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Card,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    AiOutlineCloseCircle,
    AiOutlineQuestionCircle,
} from 'react-icons/ai';
import {
    BsChatDots,
    BsTelephone,
} from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import {
    BrandingContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../app/context';
import { asCurrency } from '../libs/utils.js';

export const ChatBox = (props) => {

    const [helpMode, setHelpMode] = useState(false);
    const { layout } = useContext(LayoutContext);
    //const { branding } = useContext(BrandingContext);
    const { notifications } = useContext(NotificationsContext);

    if ("yes" === layout.sidebar) {

        return (
            <>
                {"yes" === props.chat && notifications.message ?
                    <Card className="chatArea">
                        <Card.Header className={helpMode ? "bg-info" : ""}>
                            <BsChatDots /> Live Chat
                            {helpMode ?
                                <AiOutlineCloseCircle onClick={() => setHelpMode(false)} alt="All good" title="All good" className="float-end clicker" />
                                :
                                <AiOutlineQuestionCircle onClick={() => setHelpMode(true)} alt="More info" title="More info" className="float-end clicker" />
                            }

                        </Card.Header>
                        <Card.Body>
                            <Card.Title className={helpMode ? "text-info" : ""}>Incoming message</Card.Title>
                            <Card.Text>
                                {notifications.message}
                            </Card.Text>
                        </Card.Body>
                        {helpMode &&
                            <Card.Footer className="bg-info">
                                This is not a bot, there's an actual human being on the other end!
                            </Card.Footer>
                        }

                    </Card>

                    :
                    <></>
                }
            </>
        );

    } else {
        return (<></>);
    }

}

export const IntroductionBox = (props) => {


    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    if ("none" !== branding.introduction && "yes" === layout.sidebar) {

        return (

            <Card className="contactArea">
                {"yes" === props.video &&
                    <div className="d-none d-lg-block d-xl-block d-xxl-block">
                        <iframe className='brokerVideo' src={"https://www.youtube.com/embed/" + branding.introduction} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </div>
                }
                <Card.Body>
                    <Card.Title>Contact us</Card.Title>
                    <h5><BsTelephone /> <small>{layout.phone}</small></h5>
                    <h5><HiOutlineMail /> <small>{layout.email}</small></h5>
                </Card.Body>
            </Card>

        );

    } else {
        return (<></>);
    }

}

export const PremiumBox = (props) => {

    const [helpMode, setHelpMode] = useState(false);
    const [flash, setFlash] = useState("yes" === props.flash);
    const { session } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);

    useEffect(() => {
        setFlash("yes" === props.flash);
    }, [props.flash]);

    if (session.premium && "yes" === layout.sidebar && 0 === Object.keys(session.disqualified).length) {

        return (
            <Card className={"bottom" === props.viewMode ? "" : "premiumArea"}>
                <Card.Header className={helpMode || flash ? "bg-light" : ""}>
                    Your premium
                    {helpMode ?
                        <AiOutlineCloseCircle onClick={() => setHelpMode(false)} alt="All good" title="All good" className="float-end clicker" />
                        :
                        <AiOutlineQuestionCircle onClick={() => setHelpMode(true)} alt="More info" title="More info" className="float-end clicker" />
                    }


                </Card.Header>
                <Card.Body>
                    <Card.Title className={helpMode ? "text-info" : ""}>{asCurrency(session.premium)}</Card.Title>
                    <Card.Text>
                        <small className="text-muted">per month</small>
                        {/* {session.email && session.premium &&
                              <a className="float-end" title="Download" href={gateway + "/quotes/" + session.quote} target="_blank"><GoCloudDownload /></a>
                          } */}
                        {/* {"bottom" !== props.viewMode &&
                              <>
                                  <FiEdit className="float-end clicker" alt="Adjust" title="Adjust" onClick={() => console.log("go back to needs")} />
                              </>
                          } */}
                    </Card.Text>
                </Card.Body>
                {helpMode &&
                    <Card.Footer className="bg-light">
                        The amount is based on the information you provided so far.
                    </Card.Footer>
                }

            </Card>

        );

    } else {
        return (<></>);
    }

}


export const Sidebar2 = (props) => {
    const { session } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    return (
        <Container fluid>
            <Row className='mt-4 mb-4'>
                <Col>
                    <PremiumBox viewMode="sidebar" />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <ChatBox chat="yes" />
                </Col>
            </Row>
            {"mobile" !== layout.device &&
                <Row className='mb-4'>
                    <Col>
                        <IntroductionBox video="yes" />
                    </Col>
                </Row>
            }
            <Row className='mb-4'>
                <Col>
                    {layout.verdict &&
                        <h5>Verdict: {layout.verdict}</h5>
                    }
                    {layout.download &&
                        <h5>PDF: {layout.download}</h5>
                    }

                </Col>
            </Row>
            <Row className='mt-4 mb-4'>
                <Col>
                    Quote: {session.quote}
                </Col>
            </Row>
        </Container>
    )

}

export const Sidebar = (props) => {

    const { layout } = useContext(LayoutContext);

    return (
        <Container fluid>
            <Row className='mt-4 mb-4'>
                <Col>
                    <SmallBar />
                </Col>
            </Row>
        </Container>
    )


}

export const SmallBar = (props) => {

    const { session } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (

        <Container fluid>
            {["yes", "Yes"].includes(session.privacy) &&
                <>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Your selection
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>{asCurrency(session.premium)}/mo
                                        {"yes" != layout.lockdown &&
                                            <small className='float-end'><Link to="/quoter"><FaEdit /></Link></small>
                                        }
                                    </Card.Title>
                                    <ul>
                                        <li>{asCurrency(session.facevalue)} policy</li>
                                        <li>Price guaranteed for {session.term} years.</li>
                                        {"yes" === session.rider ?
                                            <li>Premium waiver for total disability</li>
                                            :
                                            <li>No premium waiver</li>
                                        }
                                        <li>Cancel anytime.</li>

                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Questions?
                                </Card.Header>
                                <Card.Body>
                                    <p><BsTelephone /> {layout.phone}</p>
                                    <p><HiOutlineMail /> <a href={layout.emailHref}>{layout.email}</a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
            <Row className='mt-3'>
                <Col>
                    <div className="d-none d-lg-block d-xl-block d-xxl-block">
                        <iframe className='brokerVideo' src={"https://www.youtube.com/embed/" + branding.introduction} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </Col>
            </Row>


        </Container>



    );


}