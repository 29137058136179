import {
    React,
    useContext,
} from 'react';

import {
    Container,
    Image,
    Nav,
    Navbar,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { RiMovieLine } from 'react-icons/ri';

import {
    BrandingContext,
    LayoutContext,
} from '../app/context';
import { assets } from '../config.js';

export const TopBar = (props) => {

    const { branding } = useContext(BrandingContext)
    const { layout, setLayout } = useContext(LayoutContext);


    const toggleVideo = () => {

        let newLayout = { ...layout };
        if ("yes" === newLayout.mobileVideo) {
            newLayout.mobileVideo = "no";
        } else {
            newLayout.mobileVideo = "yes";
        }
        setLayout(newLayout);
    }

    return (
        <Navbar>
            <Container fluid className='mt-0 mb-0'>
                <Navbar.Brand>
                    <Image src={assets + "/" + branding.brand + "/" + branding.ab + ".png"} className="brokerLogo" onError={(event) => { event.target.style.display = 'none'; console.info(assets + "/" + branding.broker + "/" + branding.brand + "/" + branding.ab + ".png"); }} />
                </Navbar.Brand>
                <Nav className="me-auto">
                </Nav>

                <Nav>
                    {layout.phone &&
                        <Nav.Link href={layout.phoneHref} className="px-3"><h1><BsTelephone /></h1></Nav.Link>
                    }
                    {layout.email &&
                        <Nav.Link href={layout.emailHref} className="px-3"><h1><HiOutlineMail /></h1></Nav.Link>
                    }

                    {
                        <Nav.Link onClick={() => toggleVideo()} className="px-3"><h1><RiMovieLine /></h1></Nav.Link>}

                </Nav>



            </Container>
        </Navbar>
    )

}
