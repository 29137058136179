import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { extractQuestions } from '../libs/utils';
import { SimpleCarousel } from '../screen/wizards';

export const Last5years = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["5years"], "carousel");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (e) => {
        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <SimpleCarousel onCompleted={moveForward} questions={questions} title="In the last five years, have you:" />
            }
        </>

    )
}

export const Last10years = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["10years"], "carousel");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (e) => {
        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <SimpleCarousel onCompleted={moveForward} questions={questions} title="In the last ten years, have you:" />
            }
        </>

    )
}


export const MiscQuestions = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["none"], "carousel");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (e) => {
        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <SimpleCarousel onCompleted={moveForward} questions={questions} title="Please answer:" />
            }
        </>

    )
}


export const MedicalDiagnosis = (props) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["diagnostic"], "carousel");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    const moveForward = (e) => {
        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);
    }

    return (
        <>
            {questions &&
                <SimpleCarousel onCompleted={moveForward} questions={questions} title="Have you ever been treated for, diagnosed, consulted a doctor, received abnormal test results or experienced symptoms of the following:" />
            }
        </>

    )
}
