import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    BsChevronRight,
    BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import {
    ageNearest,
    prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
    asCurrency,
    isEmail,
    isPhone,
} from '../libs/utils';
import {
    CalendarControl,
    DollarControl,
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { ContactForm } from './contact';
import { CoverageSlider } from './coverage';
import { Faq } from './faq';
import { CompactConsentForm } from './privacy-widgets';
import { PromoCarousel } from './promo-carousel';

export const QuoteEssentials = () => {

    const { branding } = useContext(BrandingContext);
    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const { insuranceData } = useContext(InsuranceDataContext);

    const [productSpecs, setProductSpecs] = useState({ minAge: 0, maxAge: 0 });
    const [locked, setLocked] = useState(true);

    const [highlighted, setHighlighted] = useState({
        dob: false,
        gender: false,
        smoking: false
    });


    useEffect(() => {

        if (undefined !== insuranceData.plans && undefined !== insuranceData.plans.term10) {
            let newSpecs = { ...productSpecs };
            newSpecs.minAge = parseInt(insuranceData.plans.term10.minage);
            newSpecs.maxAge = parseInt(insuranceData.plans.term10.maxage);
            newSpecs.label = "You must be between " + newSpecs.minAge + " and " + newSpecs.maxAge + " years old to qualify for this product";
            setProductSpecs(newSpecs);
            setLocked(false);
        }

    }, [insuranceData.plans]);


    useEffect(() => {

        let newSessionEntry = {
            is_unlocked: false
        }
        if (session.privacy && ["Yes", "yes"].includes(session.privacy)) {


            if (session.email && isEmail(session.email)) {
                if (session.phone && isPhone(session.phone)) {
                    if (session.first_name) {
                        let fname = session.first_name.replace(/\s+/g, '');
                        if (fname.length > 0) {
                            if (session.last_name) {
                                let lname = session.last_name.replace(/\s+/g, '');
                                if (lname.length > 0) {
                                    newSessionEntry.is_unlocked = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        setSession(newSessionEntry);

    }, [session.first_name, session.last_name, session.email, session.phone, session.privacy]);



    const onChange = (k, v) => {

        let newValues = {};
        newValues[k] = v;

        // we have a two-step question for smoking
        if ("smoking" === k) {
            if (["Yes", "No"].includes(v)) {
                newValues["smoker"] = v;
            } else {
                newValues["smoker"] = "";
            }
        }



        setSession({ ...newValues });
    }


    return (


        <Container fluid className={"mobile" === layout.device ? 'px-0 py-0 mt-0 mb-2 ml-0 mr-0' : 'mb-1'}>
            {"mobile" === layout.device ?
                <>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <CalendarControl highlighted={highlighted.dob} controlId="dob" caption="Date of birth" helpText={productSpecs.label} onChange={onChange} choices={{ default: session.dob }} />
                        </Col>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <RadioControl highlighted={highlighted.gender} controlId="gender" caption="Gender" helpText="Life expectancy statistics are available for male and female genders only" onChange={onChange} choices={{ items: { M: "male", F: "female" }, default: session.gender }} />
                        </Col>
                    </Row>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <RadioControl highlighted={highlighted.smoking} controlId="smoking" caption="Use of nicotine or tobacco products in the last 12 months?" helpText="The following are considered nicotine or tobacco products: using cigarettes, cigarillos, colts, cigars, pipes, chewing tobacco, snuff, e-cigarettes, vaporizers, nicotine gum or patches of any other form of nicotine substitute. Does not include cannabis." onChange={onChange} choices={{ items: { No: "None", Yes: "Daily/Weekly", Maybe: "On occasion" }, default: session.smoking }} />
                        </Col>
                    </Row>
                    {"Maybe" === session.smoking &&
                        <Row>
                            <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                <RadioControl highlighted={highlighted.smoker} controlId="smoker" caption="In the last 12 months have you ONLY smoked large cigars (12 or less throughout the year)" helpText="Does not include cannabis." onChange={onChange} choices={{ items: { No: "Yes", Yes: "No" }, default: session.smoker }} />
                            </Col>
                        </Row>
                    }
                </>
                :
                <>
                    <Row>
                        <Col className='col-md-12 col-lg-6'>
                            <CalendarControl highlighted={highlighted.dob} controlId="dob" caption="Date of birth" helpText={productSpecs.label} onChange={onChange} choices={{ default: session.dob }} />
                        </Col>
                        <Col className='col-md-12 col-lg-6'>
                            <RadioControl highlighted={highlighted.gender} controlId="gender" caption="Gender" helpText="Life expectancy statistics are available for male and female genders only" onChange={onChange} choices={{ items: { M: "male", F: "female" }, default: session.gender }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <RadioControl highlighted={highlighted.smoking} controlId="smoking" caption="Use of nicotine or tobacco products in the last 12 months?" helpText="The following are considered nicotine or tobacco products: using cigarettes, cigarillos, colts, cigars, pipes, chewing tobacco, snuff, e-cigarettes, vaporizers, nicotine gum or patches of any other form of nicotine substitute. Does not include cannabis." onChange={onChange} choices={{ items: { No: "None", Yes: "Daily/Weekly", Maybe: "On occasion" }, default: session.smoking }} />
                        </Col>
                    </Row>
                    {"Maybe" === session.smoking &&
                        <Row>
                            <Col>
                                <RadioControl highlighted={highlighted.smoker} controlId="smoker" caption="In the last 12 months have you ONLY smoked large cigars (12 or less throughout the year)" helpText="Does not include cannabis." onChange={onChange} choices={{ items: { No: "Yes", Yes: "No" }, default: session.smoker }} />
                            </Col>
                        </Row>
                    }
                </>
            }

        </Container>

    )

}
