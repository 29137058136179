import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import Fuse from 'fuse.js';
import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { sendEmailVerification } from '../libs/cloud';
import { getNextStep } from '../libs/routing.js';
import {
    RadioControl,
    TextControl,
} from '../screen/controls';

export const Beneficiary = () => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);

    const [highlighted, setHighlighted] = useState({
        beneficiary_name: false,
        beneficiary_relationship: false,
        beneficiary_minor: false,
        beneficiary_trustee: false,
        beneficiary_contingent: false,
        beneficiary_contingent_name: false,
        beneficiary_contingent_relationship: false,
        beneficiary_contingent_minor: false,
        beneficiary_contingent_trustee: false
    });
    const [locked, setLocked] = useState(true);
    const [validated, setValidated] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (!locked) {

            // we want to remove existing highlights if applicable, nothing more

            let newHighlighted = { ...highlighted };
            let verified = 0;

            for (let [k, v] of Object.entries(newHighlighted)) {
                if (k.includes("contingent")) {
                    newHighlighted[k] = false;
                    verified++;
                } else {
                    if (session[k] && session[k].length > 1) {
                        newHighlighted[k] = false;
                        verified++;
                    }
                }

            }

            let problems = 0;

            if ("" === session.beneficiary_name || undefined === session.beneficiary_name) {
                problems++;
            }

            if ("" === session.beneficiary_relationship || undefined === session.beneficiary_relationship) {
                problems++;
            }

            if ("" === session.beneficiary_minor || undefined === session.beneficiary_minor) {
                problems++;
            } else {
                if ("Yes" === session.beneficiary_minor) {
                    if ("" === session.beneficiary_trustee || undefined === session.beneficiary_trustee) {
                        problems++;
                    }
                }
            }

            if ("" === session.beneficiary_contingent || undefined === session.beneficiary_contingent) {
                problems++;
            } else {

                if ("Yes" === session.beneficiary_contingent) {
                    if ("" === session.beneficiary_contingent_name || undefined === session.beneficiary_contingent_name) {
                        problems++;
                    }
                    if ("" === session.beneficiary_contingent_relationship || undefined === session.beneficiary_contingent_relationship) {
                        problems++;
                    }
                    if ("" === session.beneficiary_contingent_minor || undefined === session.beneficiary_contingent_minor) {
                        problems++;
                    } else {
                        if ("Yes" === session.beneficiary_contingent_minor) {
                            if ("" === session.beneficiary_contingent_trustee || undefined === session.beneficiary_contingent_trustee) {
                                problems++;
                            }
                        }
                    }
                }

            }


            if (0 === problems) {
                setValidated(true);
            } else {
                console.info("Problems", problems);
                setValidated(false);
            }

            setHighlighted(newHighlighted);
        }

    }, [session.beneficiary_name, session.beneficiary_relationship, session.beneficiary_minor, session.beneficiary_trustee, session.beneficiary_contingent_name, session.beneficiary_contingent_relationship, session.beneficiary_contingent_minor, session.beneficiary_contingent_trustee, session.beneficiary_contingent]);

    useEffect(() => {

        if (insuranceData.jurisdictions) {
            setLocked(false);
        }


    }, [insuranceData.jurisdictions]);

    useEffect(() => {

        if (undefined === session.verification_code) {
            sendEmailVerification(session, (data) => {

                let newSessionEntry = {
                    verification_code: data.code
                };
                setSession(newSessionEntry);
            });
        }

        let newLayout = { ...layout };
        layout.lockdown = "yes";
        setLayout(newLayout);

    }, []);

    const guessRelationship = () => {


        const options = {
            isCaseSensitive: false,
            includeScore: true,
            includeMatches: false,
            minMatchCharLength: 3,
            keys: [
                "name"
            ]
        };

        const fuse = new Fuse(insuranceData.relationships, options);

        let fields = ["beneficiary_relationship", "beneficiary_contingent_relationship"];

        let newSessionEntry = {};

        for (let field of fields) {
            if (session[field]) {
                let results = fuse.search(session[field]);
                if (results && results.length > 0) {
                    let result = results[0];
                    if (result.score < 0.2) {
                        newSessionEntry[field + "_id"] = result.item.id;
                        console.info("found relationship", field, result);
                    }
                }
            }
        }

        return newSessionEntry;


    }

    const moveForward = () => {
        console.info("MOVING FORWARD");
        let flow = getNextStep(location, { ...session });
        let relationships = guessRelationship();
        if (relationships) {
            for (let [k, v] of Object.entries(relationships)) {
                flow[k] = v;
            }
        }

        setSession(flow);
        navigate(flow.next);
        console.info("NAVIGATING");
    }

    const validate = () => {

        let newHighlighted = {};
        let invalid = 0;

        for (let [k, v] of Object.entries(highlighted)) {
            if (!k.includes("contingent") && !k.includes("trustee")) {
                if (undefined === session[k] || session[k].length < 2) {
                    newHighlighted[k] = true;
                    invalid++;
                    console.info("invalid", k, session[k]);
                } else {
                    newHighlighted[k] = false;
                }
            }
        }

        if (0 === invalid) {
            setValidated(true);
        }

        setHighlighted(newHighlighted);

    }

    const onChange = (controlId, value) => {

        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);

    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Your beneficiary</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.beneficiary_name} helpText="This is your main beneficiary." controlId="beneficiary_name" caption="Name of your beneficiary" onChange={onChange} choices={{ default: session.beneficiary_name }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.beneficiary_relationship} helpText="Is the beneficiary a relative, a friend, someone else?" controlId="beneficiary_relationship" caption="Relationship" onChange={onChange} choices={{ default: session.beneficiary_relationship }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.beneficiary_minor} helpText="Answer yes if the beneficiary is currently under 18 years old." controlId="beneficiary_minor" caption="Is your beneficiary a minor?" onChange={onChange} choices={{ items: { Yes: "yes", No: "no" }, default: session.beneficiary_minor }} />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_minor &&
                    <Row>
                        <Col className='col-md-12 col-lg-10'>
                            <TextControl highlighted={highlighted.beneficiary_trustee} helpText="This person must already be an adult." controlId="beneficiary_trustee" caption="Trustee for your beneficiary" onChange={onChange} choices={{ default: session.beneficiary_trustee }} />
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.beneficiary_contingent} helpText="This is optional." controlId="beneficiary_contingent" caption="Do you want to designate a contingent beneficiary?" onChange={onChange} choices={{ items: { Yes: "yes", No: "no" }, default: session.beneficiary_contingent }} />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_contingent &&
                    <>
                        <Row>
                            <Col className='col-md-12 col-lg-10'>
                                <TextControl highlighted={highlighted.beneficiary_contingent_name} helpText="This is your contingent beneficiary." controlId="beneficiary_contingent_name" caption="Name of your contingent beneficiary" onChange={onChange} choices={{ default: session.beneficiary_contingent_name }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-12 col-lg-10'>
                                <TextControl highlighted={highlighted.beneficiary_contingent_relationship} helpText="Is the contingent beneficiary a relative, a friend, someone else?" controlId="beneficiary_contingent_relationship" caption="Relationship" onChange={onChange} choices={{ default: session.beneficiary_contingent_relationship }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-12 col-lg-10'>
                                <RadioControl highlighted={false} helpText="Answer yes if the contingent beneficiary is currently under 18 years old." controlId="beneficiary_contingent_minor" caption="Is your contingent beneficiary a minor?" onChange={onChange} choices={{ items: { Yes: "yes", No: "no" }, default: session.beneficiary_contingent_minor }} />
                            </Col>
                        </Row>
                        {"Yes" === session.beneficiary_contingent_minor &&
                            <Row>
                                <Col className='col-md-12 col-lg-10'>
                                    <TextControl highlighted={highlighted.beneficiary_contingent_trustee} helpText="This person must already be an adult." controlId="beneficiary_contingent_trustee" caption="Trustee for your contingent beneficiary" onChange={onChange} choices={{ default: session.beneficiary_contingent_trustee }} />
                                </Col>
                            </Row>
                        }

                    </>
                }
                <Row>
                    <Col className='col-12'>
                        {!locked &&
                            <>
                                {validated ?
                                    <Button onClick={() => moveForward()} variant="primary">Continue</Button>
                                    :
                                    <Button onClick={() => validate()} variant="outline-primary">Continue</Button>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}


