import { detect } from 'detect-browser';
import moment from 'moment-timezone';
import { nanoid } from 'nanoid';

import {
  getBrand,
  getBroker,
  getCampaign,
} from './utils.js';

export const ageLast = (dobStr) => {

    let dob = moment(dobStr);
    let now = moment().format('YYYY-MM-DD');
    let currentYear = now.split('-')[0];
    let dobParts = dobStr.split('-');
    dobParts.shift();

    let birthday = moment([currentYear, ...dobParts].join('-'));

    if (birthday.isAfter(now)) {
        birthday.subtract(1, "year");
    }

    return (moment.duration(birthday.diff(dob)).years());

}


export const ageNearest = (dobStr) => {

    let age;

    // we want to calculate the age using same timezone as Approve server
    let dob = moment.tz(dobStr, "America/Winnipeg");
    let now = moment.tz("America/Winnipeg");

    if (dob && dob.isValid()) {

        let years = moment.duration(now.diff(dob)).years();
        let months = moment.duration(now.diff(dob)).months();
        let days = moment.duration(now.diff(dob)).days();

        if (months >= 6) { // less than 6 months delta means the previous birthday is closer

            if (6 === months) {

                let dateParts = dobStr.split('-');
                dateParts.pop();
                const hoursInMonth = 24 * Array.from(Array(moment(dateParts.join('-'), "YYYY-MM").daysInMonth()).keys()).length;

                // we check if there are more half the hours left in the month 
                if ((days * 24) / 2.0 < hoursInMonth / 2.0) {
                    years++;
                }


            } else {
                // more than 6 months delta in the year, so it's closer to the next birthday
                years++;
            }

        }

        age = years;

    }

    return age;

}


const crunchNumbers = (insuranceData, options) => {

    let monthly = 0;
    let upperlimit;
    let selected;
    let facevalue = parseInt(options.facevalue);
    let term = "term" + options.term
    let plan = options.plan;
    let premiums = insuranceData[term];
    let riders = insuranceData.riderRates[term];

    let smokingId;
    if ("yes" === options.smoker) {
        smokingId = "1";
    } else {
        smokingId = "0";
    }

    if (premiums) {

        let premiumSegment = [options.age, options.gender, smokingId].join('#');

        if (undefined !== premiums[premiumSegment]) {
            for (let premium of premiums[premiumSegment]) {
                if (facevalue <= premium.upperlimit) { ///
                    if (upperlimit === undefined || upperlimit > premium.upperlimit) {
                        upperlimit = premium.upperlimit;
                        selected = premium;

                    }
                }
            }
        }

        if (selected) {

            let mode = parseFloat(plan.mode); //0.0833;
            let rawAmount = parseFloat(facevalue / 1000 * parseFloat(selected.rate).toFixed(2) * mode);
            let segment_id = [options.age, options.gender, smokingId].join('#');
            let riderRate = riders[segment_id];


            if ("yes" === options.rider) {
                let riderFee = parseFloat((facevalue / 1000 * parseFloat(riderRate).toFixed(2) * mode).toFixed(2));
                rawAmount = rawAmount + riderFee;
            }

            monthly = rawAmount.toFixed(2);

        }

    } else {
        //.warn("no premiums");
    }
    return monthly;
}

export const calculatePremium = (insuranceData, params) => {

    let options = { ...params };

    let term = "term" + options.term;
    let plan = insuranceData.plans[term];
    let riderPlan = insuranceData.riderPlans[term];
    if (undefined === plan) {
        console.info("We don't have the plan", options.term);
        plan = {};
    }

    let outcome = {
        monthly: 0
    };

    let monthly = 0;
    let ready = false;
    let reason = 'unknown';

    if (options.facevalue && parseInt(options.facevalue) >= parseInt(plan.minface) && parseInt(options.facevalue) <= parseInt(plan.maxface)) {
        if (options.age && parseInt(options.age) >= plan.minage && parseInt(options.age) <= plan.maxage) {
            if (options.gender && ["F", "M"].includes(options.gender)) {
                ready = true;
            } else {
                reason = 'gender needed';
            }
        } else {
            reason = `age must be betwen ${plan.minage} and ${plan.maxage}`;
        }
    } else {
        reason = `amount must be between ${plan.minface} and ${plan.maxface}`;
    }

    if ('unknown' !== reason) {
        outcome.reason = reason;
    }

    if (!ready) {
        console.info("Not ready for crunching", outcome);
        return outcome;
    }

    options.plan = plan;
    options.riderPlan = riderPlan;
    options.age = options.age.toString();
    monthly = crunchNumbers(insuranceData, options);
    outcome.monthly = monthly;
    return outcome;

}



const getInsuranceData = (item) => {
    let rawData = sessionStorage.getItem(item);
    let obj;
    if (rawData) {
        obj = JSON.parse(rawData);
    } else {

        obj = []
    }

    return obj;
}


export const getInitialQuote = () => {

    const browser = detect();
    const campaign = getCampaign();
    let now = Date.now();



    let initialQuote = {
        id: nanoid(),
        brand: getBrand(),
        broker: getBroker(),
        started: now,
        date: moment().format('YYYY-MM-DD'),
        outcome: 'started',
        producttype_id: '5981',  // todo get from plans
        producttemplate_id: '564', // todo get from plans
        utm_campaign: campaign.utm_campaign,
        utm_source: campaign.utm_source,
        utm_medium: campaign.utm_medium,
        browser_name: browser.name,
        browser_version: browser.version,
        os: browser.os,
        latest_step: 'landing',
        latest_ts: now,
        landing_start: now,
        disqualified: {},
        term: "10"
    };

    initialQuote.quote = initialQuote.id;


    return initialQuote;


}


const getJurisdictions = () => {
    let rawData = sessionStorage.getItem('jurisdictions');
    let obj;
    if (rawData) {
        obj = JSON.parse(rawData);
    } else {
        obj = []
    }

    return obj;
}

export const prepareOffers = (insuranceData, dob, gender, facevalue) => {

    let premium = false;
    let premiums = {};

    let terms = ["10", "20"];
    let smoking = ["no", "yes"];
    let riders = ["no", "yes"];
    for (let term of terms) {
        for (let smokingStatus of smoking) {
            for (let rider of riders) {
                let premiumParams = {
                    age: ageNearest(dob),
                    gender: gender,
                    facevalue: facevalue.toString(),
                    rider: rider,
                    smoker: smokingStatus,
                    term: term
                }

                let outcome = calculatePremium(insuranceData, premiumParams);

                if (outcome.monthly) {

                    premium = true;

                    let deal = {
                        monthly: outcome.monthly
                    }

                    // if (undefined !== outcome.proposed && outcome.proposed < outcome.monthly && outcome.recommended > facevalue.toString()) {
                    //     deal.proposed = outcome.proposed;
                    //     deal.recommended = outcome.recommended;
                    // }

                    if (undefined === premiums["term" + term]) {
                        premiums["term" + term] = {
                            smoker: { rider: null, norider: null },
                            nonsmoker: { rider: null, norider: null },
                        }
                    }

                    if ("yes" === smokingStatus) {
                        if ("yes" === rider) {
                            premiums["term" + term].smoker.rider = deal;
                        } else {
                            premiums["term" + term].smoker.norider = deal;
                        }
                    } else {
                        if ("yes" === rider) {
                            premiums["term" + term].nonsmoker.rider = deal;
                        } else {
                            premiums["term" + term].nonsmoker.norider = deal;
                        }
                    }

                }

            }
        }
    }

    if (premium) {
        return premiums;
    } else {
        return undefined;
    }

}

export { getInsuranceData, getJurisdictions };
