import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import {
    BankAccountControl,
    NumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';

export const Payment = () => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);

    const [highlighted, setHighlighted] = useState({
        payment_transit: false,
        payment_account: false,
        payment_address: false,
        payment_bank: false,
        payment_day: false,
        payment_owner_different: false,
        payment_owner: false
    });
    const [locked, setLocked] = useState(true);
    const [validated, setValidated] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (!locked) {

            // we want to remove existing highlights if applicable, nothing more

            let newHighlighted = { ...highlighted };
            let verified = 0;

            for (let [k, v] of Object.entries(newHighlighted)) {

                if (["payment_owner_different", "payment_owner"].includes(k)) {
                    newHighlighted[k] = false;
                    //verified++;
                } else {
                    if (session[k] && session[k].length >= 1) {
                        newHighlighted[k] = false;
                        verified++;

                    }
                }


            }

            if (5 === verified) {
                setValidated(true);
            } else {
                setValidated(false);
            }

            setHighlighted(newHighlighted);
        }

    }, [session.payment_address, session.payment_transit, session.payment_account, session.payment_bank, session.payment_day, session.payment_owner_different, session.payment_owner]);

    useEffect(() => {

        setLocked(false);

    }, []);


    const moveForward = () => {

        let newLayout = { ...layout };
        newLayout.lockdown = "yes";
        setLayout(newLayout);

        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);

    }

    const validate = () => {

        let newHighlighted = {};
        let invalid = 0;

        for (let [k, v] of Object.entries(highlighted)) {
            if (!["payment_owner_different", "payment_owner"].includes(k)) {
                newHighlighted[k] = undefined === session[k] || "" === session[k].length;
                invalid++;
            }
        }

        if (0 === invalid) {
            setValidated(true);
        } else {
            setValidated(false);
        }

        setHighlighted(newHighlighted);

    }

    const onChange = (controlId, value) => {

        let newSessionEntry = {};



        newSessionEntry[controlId] = value;
        // ugly
        newSessionEntry.payment_frequency = "monthly";



        setSession(newSessionEntry);

    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Payment information</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <NumberControl highlighted={highlighted.payment_bank} helpText="This is usually a 3-digit number, such as 001 for BMO." controlId="payment_bank" caption="Bank institution identifier" onChange={onChange} choices={{ default: session.payment_bank }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <TextControl highlighted={highlighted.payment_address} helpText="Mailing address of the bank's branch." controlId="payment_address" caption="Branch mailing address" onChange={onChange} choices={{ default: session.payment_address }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <NumberControl highlighted={highlighted.payment_transit} helpText="This is usually a 5-digit number that identifies your branch." controlId="payment_transit" caption="Transit number" onChange={onChange} choices={{ default: session.payment_transit }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <BankAccountControl highlighted={highlighted.payment_account} helpText="This is usually a 7 to 12 digits number that identifies your account." controlId="payment_account" caption="Account number" onChange={onChange} choices={{ default: session.payment_account }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <NumberControl highlighted={highlighted.payment_day} helpText="Between 1 and 28." controlId="payment_day" caption="Withdrawal day" onChange={onChange} choices={{ default: session.payment_day, minValue: 1, maxValue: 28 }} />
                    </Col>
                </Row>
                {false &&
                    <>
                        <Row>
                            <Col className='col-md-12 col-lg-10'>
                                <RadioControl highlighted={false} helpText="Answer yes if the owner of the bank account is different from the policy owner." controlId="payment_owner_different" caption="Is the account owner different from the policy owner?" onChange={onChange} choices={{ items: { Yes: "yes", No: "no" }, default: session.payment_owner_different || "No" }} />
                            </Col>
                        </Row>
                        {"Yes" === session.payment_owner_different &&
                            <Row>
                                <Col className='col-md-12 col-lg-10'>
                                    <TextControl highlighted={false} helpText="This person must be the owner of the account." controlId="payment_owner" caption="Bank account owner" onChange={onChange} choices={{ default: session.payment_owner }} />
                                </Col>
                            </Row>
                        }
                    </>
                }

                <Row>
                    <Col className='col-12'>
                        {!locked &&
                            <>
                                {validated ?
                                    <Button onClick={() => moveForward()} variant="primary">Continue</Button>
                                    :
                                    <Button onClick={() => validate()} variant="outline-primary">Continue</Button>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}


