import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Image,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import {
  MdOutlineAlternateEmail,
  MdOutlineHttps,
  MdOutlineMail,
} from 'react-icons/md';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../app/context';
import { assets } from '../config.js';
import {
  asHyperlink,
  asPhoneNumber,
} from '../libs/utils';
import { SmallBar } from './sidebar';

export const DesktopLayout = (props) => {


  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext)
  const { layout } = useContext(LayoutContext);


  let navigate = useNavigate();
  let location = useLocation();

  return (
    <>

      {["/", "/privacy", "/faq", "/analysis", "/quoter", "/consent", "/policy"].includes(location.pathname) ?
        <FocusLayout />
        :
        <SplitLayout />
      }

      {/* <TopBar />
      <Container fluid className="px-4 py-5 mb-4">
        <Row>
          
        </Row>
      </Container> */}
    </>
  )


}

export const FocusLayout = (props) => {


  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext)


  const { layout, setLayout } = useContext(LayoutContext);
  const [screenRatio, setScreenRatio] = useState(false);

  useEffect(() => {

    setScreenRatio(window.devicePixelRatio);
    let newLayout = {
      zoomed: window.devicePixelRatio > 1
    }
    setLayout(newLayout);

  }, []);


  useEffect(() => {

    if (undefined !== session.quote) {
      setLayout({ composed: "yes" });
    }

  }, [session.quote]);

  return (
    <>

      {screenRatio &&
        <>
          <Container fluid className="mb-2 mainContent">

            <Row>
              <Col className="text-end">
                Questions? {layout.phone} or  <a href={layout.emailHref}>{layout.email}</a>
              </Col>
            </Row>

            <Row>

              {/* <Col className='col-md-10 col-lg-8 p-0'> */}
              <Col className='col-12'>
                <Container fluid>
                  <Row className="mt-1">
                    <Col className="pt-2 mx-auto">
                      {"yes" === layout.composed &&
                        <Container>
                          <Row className="bg-white p-0 pb-0 pe-lg-0 pt-lg-2 align-items-center rounded-3 border shadow-lg">
                            {/* <Col className="p-3 p-lg-5 pt-lg-3 "> */}
                            <Col className="p-0 p-lg-3 pt-lg-1 ">

                              {/* <Container fluid className="main-content"> */}
                              <Container fluid>

                                <Row>
                                  <Outlet />
                                </Row>

                              </Container>


                            </Col>
                          </Row>
                          <Row className="bg-light mt-0 p-1 pe-lg-0 align-items-center rounded-3 border shadow-lg">
                            <Col className='text-muted text-center col-12'>
                              <Link title="Frequently asked questions" to="/faq">FAQ</Link> | <BsTelephone /> {layout.phone} | <MdOutlineMail /> <a href={layout.emailHref}>{layout.email}</a>
                            </Col>
                            <Col className='text-muted text-center col-12'>
                              This life insurance quote is not meant for residents of Quebec | <i>Cette soumission d'assurance-vie n'est pas destinée aux résidents du Québec </i>
                            </Col>

                          </Row>

                        </Container>
                      }
                    </Col>
                  </Row>

                </Container>


              </Col>

            </Row>

          </Container>

          <Image onClick={() => console.info("SESSION", session)} src={assets + "/" + branding.brand + "/" + branding.ab + ".png"} className="fixed-logo brokerLogo" onError={(event) => { event.target.style.display = 'none'; console.info(assets + "/" + branding.broker + "/" + branding.brand + "/" + branding.ab + ".png"); }} />


          <PageFooter />
        </>
      }

    </>
  )

}

export const FocusLayoutWithNav = (props) => {


  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext)
  const { layout } = useContext(LayoutContext);


  return (
    <>

      <Container fluid className="mb-4 mainContent">

        <Row>
          <Col>
            <Image onClick={() => console.info("SESSION", session)} src={assets + "/" + branding.brand + "/" + branding.ab + ".png"} className="brokerLogo" onError={(event) => { event.target.style.display = 'none'; console.info(assets + "/" + branding.broker + "/" + branding.brand + "/" + branding.ab + ".png"); }} />
          </Col>
          <Col className="text-end">

            Questions? {layout.phone} or  <a href={layout.emailHref}>{layout.email}</a>


          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="pt-4">
            <Container>
              <Row className="bg-white p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                {/* <Col className="p-3 p-lg-5 pt-lg-3 "> */}
                <Col className="p-0 p-lg-3 pt-lg-1 ">

                  {/* <Container fluid className="main-content"> */}
                  <Container fluid>

                    <Row>
                      <Outlet />
                    </Row>

                  </Container>


                </Col>
              </Row>
              <Row className="bg-light mt-0 p-1 pe-lg-0 align-items-center rounded-3 border shadow-lg">
                <Col className='text-muted text-center col-12'>
                  <Link title="Frequently asked questions" to="/faq">FAQ</Link> | <BsTelephone /> {layout.phone} | <MdOutlineMail /> <a href={layout.emailHref}>{layout.email}</a>
                </Col>

              </Row>

            </Container>
          </Col>
        </Row>

      </Container>

      <PageFooter />

    </>
  )

}

export const SplitLayout = (props) => {


  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext)
  const { layout } = useContext(LayoutContext);




  return (
    <>

      <Container fluid className="mb-4 mainContent">



        <Row>
          <Col>
            <Image onClick={() => console.info("SESSION", session)} src={assets + "/" + branding.brand + "/" + branding.ab + ".png"} className="brokerLogo" onError={(event) => { event.target.style.display = 'none'; console.info(assets + "/" + branding.broker + "/" + branding.brand + "/" + branding.ab + ".png"); }} />
          </Col>
          <Col className='col-md-10 col-lg-8 p-0'>
            <Container fluid>
              <Row>
                <Col>
                  <Container fluid>

                    {layout.pct > 0 &&
                      <Row className="p-0 pb-0 pe-lg-0 pt-lg-0 rounded-3 shadow-lg">
                        <Col className='p-0 m-0' >
                          <ProgressBar animated now={layout.pct} label={`${layout.pct}%`} variant="success" />
                        </Col>
                      </Row>
                    }

                    <Row className="bg-white p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">

                      <Col className="p-3 p-lg-5 pt-lg-3 ">

                        <Container fluid className="main-content">



                          <Row>
                            <Col className='col-md-8'>
                              <Outlet />
                            </Col>
                            <Col className='col-md-4'>
                              <SmallBar />
                            </Col>
                          </Row>

                        </Container>


                      </Col>
                    </Row>
                    <Row className="bg-light mt-0 p-1 pe-lg-0 align-items-center rounded-3 border shadow-lg">
                      <Col className='text-success text-center col-12'>
                        <MdOutlineHttps /> You are using a <b>secure SSL connection</b>
                      </Col>
                    </Row>

                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col>
            &nbsp;
          </Col>


        </Row>



      </Container>
      <PageFooter />
    </>

  )

}


export const SplitLayoutWithNav = (props) => {


  const { session } = useContext(SessionContext);
  const { branding } = useContext(BrandingContext)
  const { layout } = useContext(LayoutContext);




  return (
    <>

      <Container fluid className="mb-4 mainContent">

        <Row>
          <Col>
            <Image onClick={() => console.info("SESSION", session)} src={assets + "/" + branding.brand + "/" + branding.ab + ".png"} className="brokerLogo" onError={(event) => { event.target.style.display = 'none'; console.info(assets + "/" + branding.broker + "/" + branding.brand + "/" + branding.ab + ".png"); }} />
          </Col>


        </Row>

        <Row className="mt-4">
          <Col className="pt-4">

            <Container>

              {layout.pct > 0 &&
                <Row className="p-0 pb-0 pe-lg-0 pt-lg-0 rounded-3 shadow-lg">
                  <Col className='p-0 m-0' >
                    <ProgressBar animated now={layout.pct} label={`${layout.pct}%`} variant="success" />
                  </Col>
                </Row>
              }

              <Row className="bg-white p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">

                <Col className="p-3 p-lg-5 pt-lg-3 ">

                  <Container fluid className="main-content">



                    <Row>
                      <Col className='col-md-8'>
                        <Outlet />
                      </Col>
                      <Col className='col-md-4'>
                        <SmallBar />
                      </Col>
                    </Row>

                  </Container>


                </Col>
              </Row>
              <Row className="bg-light mt-0 p-1 pe-lg-0 align-items-center rounded-3 border shadow-lg">
                <Col className='text-success text-center col-12'>
                  <MdOutlineHttps /> You are using a <b>secure SSL connection</b>
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>

      </Container>
      <PageFooter />
    </>

  )

}

const PageFooter = () => {

  const { branding } = useContext(BrandingContext)
  const { layout } = useContext(LayoutContext)
  const { session } = useContext(SessionContext)


  let navigate = useNavigate();
  let location = useLocation();


  const revokeConsent = () => {
    let flow = {
      next: '/privacy',
      privacy: null
    }

    navigate(flow.next);
  }

  return (

    <Container fluid className='text-muted fixed-bottom bg-light footerBar'>
      <Row className='pt-1 pb-2'>
        <Col className='col-md-4 text-primary'>
          <MdOutlineHttps /> Your connection is secure
        </Col>
        <Col className='col-md-8 text-end'>
          {/* {"Yes" === session.privacy && location.pathname !== "/privacy" &&
            <>
              <u><span onClick={() => revokeConsent()} className='clicker'>Review consent</span></u>&nbsp;|&nbsp;
            </>
          } */}
          <a className="text-muted" target="_blank" rel="noopener noreferrer" href={branding.privacyLink}>Legal/Privacy</a>&nbsp;|&nbsp;
          <a className="text-muted" target="_blank" rel="noopener noreferrer" href={branding.accessibilityLink}>Accessibility policy</a>&nbsp;|&nbsp;
          <a className="text-muted" target="_blank" rel="noopener noreferrer" href={branding.ftcLink}>Fair treatment of customers policy</a>&nbsp;
        </Col>
      </Row>
    </Container>


  )
}