import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import moment from 'moment';
import {
    Form,
    InputGroup,
} from 'react-bootstrap';
//import DatePicker from 'react-date-picker';
import {
    BsInfoCircle,
    BsTelephone,
} from 'react-icons/bs';
import { CgCloseR } from 'react-icons/cg';

import {
    BrandingContext,
    InsuranceDataContext,
    SessionContext,
} from '../app/context';
import { asNumber } from '../libs/utils';

export const BankAccountControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseBankAccountControl} highlighted={highlighted} choices={choices} />
    )

}

export const CalendarControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseCalendarControl} choices={choices} highlighted={highlighted} />
    )

}


export const CheckboxControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseCheckboxControl} choices={choices} highlighted={highlighted} />
    )

}


export const DollarControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted, forceValue }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} forceValue={forceValue} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseDollarControl} choices={choices} highlighted={highlighted} />
    )

}

export const EmailControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseEmailControl} highlighted={highlighted} choices={choices} />
    )

}

export const NumberControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseNumberControl} highlighted={highlighted} choices={choices} />
    )

}

export const PostalCodeControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BasePostalCodeControl} highlighted={highlighted} choices={choices} />
    )

}


export const PhoneNumberControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BasePhoneNumberControl} highlighted={highlighted} choices={choices} />
    )

}


export const ProvinceControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseProvinceControl} highlighted={highlighted} choices={choices} />
    )

}

export const RadioControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseRadioControl} choices={choices} highlighted={highlighted} />
    )

}

export const TextControl = ({ controlId, caption, helpText, onChange, onHighlight, choices, highlighted }) => {

    return (
        <BaseControl controlId={controlId} caption={caption} helpText={helpText} onChange={onChange} onHighlight={onHighlight} childControl={BaseTextControl} highlighted={highlighted} choices={choices} />
    )

}

export const BaseControl = ({ controlId, caption, forceValue, helpText, onChange, onHighlight, childControl, choices, highlighted }) => {

    const { session, setSession } = useContext(SessionContext);

    const [colorMode, setColorMode] = useState(highlighted ? "highlight" : "standard");
    const [helpVisible, setHelpVisible] = useState(highlighted);
    const [showButton, setShowButton] = useState(choices && !choices.hideHelpButton);
    const [value, setValue] = useState("");

    useEffect(() => {
        if (highlighted) {
            setHelpVisible(true);
            setColorMode("highlight");
        } else {
            setColorMode("standard");
        }
    }, [highlighted]);

    useEffect(() => {
        if (helpVisible) {
            let helpItems;
            if (session.help_clicked) {
                helpItems = session.help_clicked;
            } else {
                helpItems = [];
            }
            helpItems.push({ [controlId]: Date.now() });
            setSession({ help_clicked: helpItems });

            if (onHighlight) {

                onHighlight();
            } else {

            }

        }
    }, [helpVisible]);


    const onHelpToggle = () => {
        setHelpVisible(!helpVisible);
    }

    const getClassName = () => {
        if ("standard" === colorMode) {
            return "mb-3";
        } else {
            return "mb-3 text-danger";
        }
    }

    return (

        <Form.Group className={getClassName()} controlId={controlId}>
            {!choices.hideLabel &&
                <>

                    <Form.Label>{caption}</Form.Label>
                    {showButton &&
                        <>
                            {helpVisible ?
                                <CgCloseR id={"help-close-" + controlId} className="float-end clicker text-muted" onClick={onHelpToggle} />
                                :
                                <BsInfoCircle id={"help-open-" + controlId} className="float-end clicker" onClick={onHelpToggle} />
                            }
                        </>
                    }

                </>

            }

            {childControl({ controlId: controlId, onChange: onChange, onHighlight, choices: choices, forceValue: forceValue })}
            {helpVisible &&
                <Form.Text id={"help-label-" + controlId} className="text-muted">
                    {helpText}
                </Form.Text>
            }
        </Form.Group>

    )

}


const BaseBankAccountControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.defaultValue || "");

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString();
        let newValue = rawValue.replace(/[^0-9\-]/gi, '');
        setValue(newValue);
        onChange(controlId, newValue);
    }

    return (

        <>
            <div className="mb-3">
                <Form.Control type="text" placeholder="Enter identifier" value={value || ''} onChange={(e) => onValueChange(e)} />
            </div>

        </>

    )

}

// this one sucks
// const BaseDatepickerControl = ({ controlId, choices, onChange }) => {

//     const { session, setSession } = useContext(SessionContext);

//     const initialDate = () => {
//         let date;
//         if (session.dob) {
//             date = moment(session.dob, 'YYYY-MM-DD');
//         } else {
//             if (choices.defaultDate) {
//                 date = moment(choices.defaultDate, 'YYYY-MM-DD');
//             } else {
//                 date = moment().subtract(38, 'years'); //todo refactor                
//             }
//         }

//         return date.toDate();
//     }

//     const [value, setValue] = useState(initialDate());

//     const getMaxDate = () => {
//         let date = moment();
//         if (choices.maxAge && choices.minAge && choices.maxAge > choices.minAge) {
//             date = moment().subtract(choices.minAge, 'years');
//         }
//         return date.toDate();
//     }

//     const getMinDate = () => {
//         let date = moment();
//         if (choices.maxAge && choices.minAge && choices.maxAge > choices.minAge) {
//             date = moment().subtract(choices.maxAge, 'years');
//         }
//         return date.toDate();
//     }

//     const onValueChange = (e) => {
//         setValue(e);
//     }


//     useEffect(() => {
//         if (value) {
//             let dateStr = moment(value).format('YYYY-MM-DD');
//             onChange(controlId, dateStr);
//         }

//     }, [value]);


//     useEffect(() => {

//         setValue(initialDate());

//     }, []);


//     return (
//         <div>
//             <DatePicker locale="en-CA" format="y-MM-dd" className="form-control" onChange={(e) => onValueChange(e)} maxDate={getMaxDate()} minDate={getMinDate()} value={value} />
//         </div>
//     );


// };

const BaseCalendarControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.default || "");


    useEffect(() => {

        if (choices.default) {
            setValue(choices.default);
        }

    }, [choices.default]);

    const reformatDate = (rawValue) => {

        let dateValue = "";
        let newValue = rawValue.replace(/\D/g, '').slice(0, 8);
        if (8 === newValue.length) {
            dateValue = newValue.slice(0, 4) + '-' + newValue.slice(4, 6) + '-' + newValue.slice(6, 8);
        }

        return dateValue;

    }

    const isRealDate = (rawValue) => {

        let isValid = false;
        let dateValue = reformatDate(rawValue);
        if (10 === dateValue.length) {
            let dateObj = moment(dateValue);
            if (dateObj.isValid()) {
                isValid = true;
            }
        }
        return isValid;

    }

    const onCompleted = (e) => {
        let rawValue = e.target.value.toString();
        let dateValue = reformatDate(rawValue);
        if (!isRealDate(dateValue)) {
            dateValue = "";
        }
        setValue(dateValue);
        console.info("Setting DOB", dateValue);
        onChange(controlId, dateValue);
    }

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString();
        let newValue = rawValue.replace(/[^0-9\-]/g, '').slice(0, 10);
        setValue(newValue);
        if (isRealDate(newValue)) {
            let dateValue = reformatDate(newValue);
            onChange(controlId, dateValue);
        }
    }

    return (

        <>
            <InputGroup className="mb-3">
                <Form.Control className="calendar-control" onBlur={(e) => onCompleted(e)} type="text" placeholder="yyyy-mm-dd" value={value || ''} onChange={(e) => onValueChange(e)} />
            </InputGroup>
        </>

    )

}

const BaseCheckboxControl = ({ controlId, choices, onChange }) => {

    const { branding } = useContext(BrandingContext);
    const [checked, setChecked] = useState(choices.defaultValue || false);

    const onValueChange = (e) => {
        let newValue = e.target.checked;
        setChecked(newValue);
        onChange(controlId, newValue);
    }

    return (

        <>
            <InputGroup className="mb-3">
                <Form.Check
                    type="checkbox"
                    label={choices.label}
                    checked={checked}
                    onChange={(e) => onValueChange(e)}
                />
                {choices.link &&
                    <>
                        &nbsp;
                        <a target="_blank" rel="noreferrer" href={choices.link}>{choices.linkLabel}</a>
                    </>

                }

            </InputGroup>

        </>

    )

}


const BaseDollarControl = ({ controlId, choices, onChange, forceValue }) => {

    const [value, setValue] = useState(choices.defaultValue || "");


    useEffect(() => {

        if (forceValue) {
            setValue(forceValue);
        }

    }, [forceValue]);

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString();
        let newValue = parseInt((rawValue.split('.')[0]).replace(/\D/g, ''));
        setValue(newValue);
        onChange(controlId, newValue);
    }

    return (

        <>
            <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control className="dollar-control" type="text" placeholder="Enter amount" value={asNumber(value) || ''} onChange={(e) => onValueChange(e)} />
            </InputGroup>
            {"hide" !== choices.slider &&
                <Form.Range min={choices.minValue} max={choices.maxValue} step="1000" value={value || choices.minValue} onChange={(e) => onValueChange(e)} />
            }

        </>

    )

}



const BaseEmailControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.defaultValue || "");

    const onValueChange = (e) => {
        setValue(e.target.value.toString());
        onChange(controlId, e.target.value.toString());
    }

    return (

        <>
            <InputGroup className="mb-3">
                <InputGroup.Text>@</InputGroup.Text>
                <Form.Control type="email" placeholder="Enter email address" value={value || ''} onChange={(e) => onValueChange(e)} />
            </InputGroup>

        </>

    )

}


const BaseNumberControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.defaultValue || "");

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString();
        let newValue = rawValue.split('.')[0].replace(/\D/g, '');
        let numVal = parseInt(newValue);
        if (isNaN(numVal)) {
            newValue = "";
        } else {
            if (choices.minValue && numVal < choices.minValue) {
                newValue = choices.minValue;
            }
            if (choices.maxValue && numVal > choices.maxValue) {
                newValue = choices.maxValue;
            }
        }

        setValue(newValue);
        onChange(controlId, newValue);
    }

    return (

        <>
            <div className="mb-3">
                <Form.Control type="text" placeholder="Enter number" value={value || ''} onChange={(e) => onValueChange(e)} />
            </div>

        </>

    )

}

const BasePhoneNumberControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.defaultValue || "");
    const [cursorPosition, setCursorPosition] = useState(0);

    const onKeyDown = (e) => {
        if (8 === e.keyCode || 46 === e.keyCode) {
            setCursorPosition(e.target.selectionStart);
        }
    }

    const onKeyUp = (e) => {
        if (8 === e.keyCode || 46 === e.keyCode) {
            if (cursorPosition) {
                e.target.selectionStart = cursorPosition;
                e.target.selectionEnd = cursorPosition;
                setCursorPosition(0);
            }
        }
    }

    const onValueChange = (e) => {

        let rawValue = e.target.value.toString();
        let newValue = rawValue.replace(/\D/g, '');
        let numVal = parseInt(newValue);
        if (isNaN(numVal)) {
            newValue = "";
        }

        newValue = formatPhoneNumber(newValue);

        setValue(newValue);

        onChange(controlId, newValue);
    }

    const formatPhoneNumber = (digits) => {

        let phoneParts = [];

        let newValue = digits.toString();

        if (newValue.length > 3) {
            if (newValue.startsWith("1")) {
                newValue = newValue.substring(1);
            }
        }

        if (newValue.length >= 3) {
            let areaCode = newValue.substr(0, 3);
            phoneParts.push(areaCode);
            newValue = newValue.substring(3);
        }

        if (newValue.length >= 3) {
            let prefix = newValue.substr(0, 3);
            phoneParts.push(prefix);
            newValue = newValue.substring(3);
        }

        if (newValue.length >= 3) {
            let suffix = newValue.substr(0, 4);
            phoneParts.push(suffix);
            newValue = newValue.substring(4);
        }

        if (phoneParts.length > 0) {

            if (phoneParts.length > 1) {

                if (phoneParts.length > 2) {
                    newValue = "(" + phoneParts[0] + ") " + phoneParts[1] + "-" + phoneParts[2];
                } else {
                    if (newValue.length > 0) {
                        newValue = "(" + phoneParts[0] + ") " + phoneParts[1] + "-" + newValue;
                    } else {
                        newValue = "(" + phoneParts[0] + ") " + phoneParts[1];
                    }

                }

            } else {
                if (newValue.length > 0) {
                    newValue = "(" + phoneParts[0] + ") " + newValue;
                } else {
                    newValue = phoneParts[0];
                }

            }

        }

        return newValue;

    }

    return (

        <>
            <InputGroup className="mb-3">
                <InputGroup.Text><BsTelephone /></InputGroup.Text>
                <Form.Control type="text" placeholder="Enter phone number" value={value || ''} onChange={(e) => onValueChange(e)} onKeyUp={(e) => onKeyUp(e)} onKeyDown={(e) => onKeyDown(e)} />
            </InputGroup>

        </>

    )

}

const BasePostalCodeControl = ({ controlId, choices, onChange }) => {

    const [value, setValue] = useState(choices.defaultValue || "");

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString().toUpperCase();
        let newValue = rawValue.replace(/[^A-Z0-9]/gi, '');
        if (newValue.length > 6) {
            newValue = newValue.substr(0, 6);
        }
        if (newValue.length > 3) {
            newValue = newValue.substr(0, 3) + " " + newValue.substr(3);
        }

        setValue(newValue);
        onChange(controlId, newValue);
    }

    return (

        <>
            <div className="mb-3">
                <Form.Control type="text" placeholder="Enter postal code" value={value || ''} onChange={(e) => onValueChange(e)} />
            </div>

        </>

    )

}

const BaseProvinceControl = ({ controlId, onChange, onHighlight, choices }) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const [jurisdictions, setJurisdictions] = useState([]);
    const [selected, setSelected] = useState(choices.default);


    useEffect(() => {

        if (undefined !== insuranceData.jurisdictions && insuranceData.jurisdictions.provinces) {
            let newJurisdictions = [...insuranceData.jurisdictions.provinces];
            if (!choices.canadaOnly) {
                newJurisdictions.push(
                    {
                        "statecode": "x",
                        "name": "(Somewhere else)",
                    }
                );
            }
            setJurisdictions(newJurisdictions);
        }

    }, [insuranceData.jurisdictions]);

    useEffect(() => {

        if (choices.default) {
            setSelected(choices.default);
        }

    }, [choices.default]);

    const getProvinces = () => {
        return (
            <>
                {jurisdictions.map((province) =>
                    <option value={province.statecode.toLowerCase()} key={province.statecode.toLowerCase()}>{province.name}</option>
                )}
            </>
        )
    }

    const onValueChange = (e) => {
        onChange(controlId, e.target.value);
    }

    return (

        <div className="mb-3">
            <Form.Select aria-label="Province" id={controlId} value={selected} onChange={(e) => onValueChange(e)}>
                {getProvinces()}
            </Form.Select>
        </div>
    )

}


const BaseRadioControl = ({ controlId, onChange, onHighlight, choices }) => {

    const [selected, setSelected] = useState(choices.default);

    const [options, setOptions] = useState(false);


    useEffect(() => {

        if (choices.items) {
            if (choices.items instanceof Map || choices.items instanceof Object) {
                let newOptions = [];
                if (choices.items instanceof Map) {
                    // we use a map when we need sorted objects
                    for (const [key, value] of choices.items.entries()) {
                        let newOption = {
                            id: key,
                            copy: value.copy
                        };
                        newOptions.push(newOption);
                    }
                }
                else {
                    // we use an object when we need simple k/v pairs
                    for (const [key, value] of Object.entries(choices.items)) {
                        let newOption = {
                            id: key,
                            copy: value
                        };
                        newOption[key] = value;
                        newOptions.push(newOption);
                    }
                }
                setOptions(newOptions);
            }
        }

    }, [choices]);

    const onValueChange = (e) => {

        if (e.target.checked) {
            setSelected(e.target.id);
            onChange(e.target.name, e.target.id);
        }

    }

    return (

        <div className={choices.centered ? "text-center mb-3" : "mb-3"}>
            {options &&
                <>
                    {options.map((option, index) =>
                        <Form.Check
                            className={"choice-" + option.id}
                            key={option.id}
                            checked={selected === option.id}
                            inline
                            label={option.copy}
                            name={controlId}
                            type="radio"
                            id={`${option.id}`}
                            onChange={(e) => onValueChange(e)}
                            aria-label={`${option.id}`}
                        />
                    )}
                </>
            }

        </div>
    )

}

const BaseTextControl = ({ controlId, onChange, onHighlight, choices }) => {

    const [value, setValue] = useState("");


    useEffect(() => {

        if (undefined !== choices.default) {
            setValue(choices.default);
        }

    }, [choices.default]);

    const onValueChange = (e) => {
        let newValue = e.target.value;
        setValue(newValue);
        onChange(controlId, newValue);
    }

    return (
        <Form.Control type="text" value={value} onChange={(e) => onValueChange(e)} />
    )

}


