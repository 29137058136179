export const assets = "https://qat-belowcost-app-assets.link-nonprod.wawanesalife.com";
export const brokerlist = {

    "qat-belowcost-app-api.link-nonprod.wawanesalife.com": "belowcost",

    "qat-belowcost-app-docs.link-nonprod.wawanesalife.com": "belowcost",

    "qat-belowcost-app.link-nonprod.wawanesalife.com": "belowcost"
  
};
export const gateway = "https://qat-belowcost-app-api.link-nonprod.wawanesalife.com";
export const brokerConfig = {
  "authorized_mb": "yes",
  "broker": "belowcost",
  "authorized_sk": "yes",
  "topic": "arn:aws:sns:ca-central-1:965766005558:qat-partnership",
  "display": "Below Cost Brokers",
  "authorized_qc": "yes",
  "name": "Below Cost Brokers",
  "authorized_nb": "yes",
  "authorized_ns": "yes",
  "enabled": "yes",
  "authorized_on": "yes",
  "authorized_ab": "yes",
  "authorized_bc": "yes",
  "modifiedBy": "6b606589-2e29-5719-93a5-3ea8de390b28"
};
export const brokerBranding = {
  "qat-belowcost-app.link-nonprod.wawanesalife.com": {
    "a": {
      "broker": "belowcost",
      "accessibilityLink": "https://westernfinancialgroup.ca/Accessibility-Guidelines",
      "privacyLink": "https://westernfinancialgroup.ca/Privacy",
      "approve_company": "Link Platform",
      "email": "bob1965@shawcable.ca",
      "name": "Below Cost",
      "asOf": 1670717431817,
      "privacy_link": "https://westernfinancialgroup.ca/Privacy",
      "introduction": "QeDkVQP-PGU",
      "phone": "(204) 223-3344",
      "theme": "yeti",
      "ab": "a",
      "display": "Below Cost brokers",
      "ftcLink": "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
      "happy_path": "landing,quoter,privacy,lifestyle,introduction,address,diagnosis,last10,last5,last2,medical,occupation,misc,beneficiary,payment,consent,policy",
      "logo": "a.png",
      "font": "Comic Sans",
      "email_QC": "bob.french@sympatico.ca",
      "approve_user": "link_belowcost",
      "phone_sk": "(306) 442-4442",
      "email_sk": "bobjr@sasktel.ca",
      "modifiedBy": "c71dbad5-6eba-5ba2-96ef-e6da4c839057",
      "brand": "qat-belowcost-app.link-nonprod.wawanesalife.com"
    },
    "b": {
      "broker": "belowcost",
      "accessibilityLink": "https://westernfinancialgroup.ca/Accessibility-Guidelines",
      "privacyLink": "https://westernfinancialgroup.ca/Privacy",
      "approve_company": "Link Platform",
      "email": "bobinsurance2@hotmail.com",
      "name": "Below Cost",
      "asOf": 1695760171668,
      "privacy_link": "https://westernfinancialgroup.ca/Privacy",
      "introduction": "ABAiUJZUT2M",
      "phone": "(204) 223-3344",
      "theme": "litera",
      "ab": "b",
      "display": "Below Cost brokers",
      "ftcLink": "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
      "happy_path": "landing,quoter,privacy,introduction,lifestyle,address,last10,diagnosis,last5,last2,misc,medical,occupation,beneficiary,payment,consent,policy",
      "logo": "b.png",
      "font": "Tahoma",
      "approve_user": "link_belowcost",
      "phone_sk": "(306) 442-4442",
      "email_sk": "bobjr@sasktel.ca",
      "modifiedBy": "5143cd55-ceed-58dd-810c-90d88f80ddee",
      "brand": "qat-belowcost-app.link-nonprod.wawanesalife.com"
    }
  }
};
