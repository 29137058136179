import convert from 'convert-units';

export const asCurrency = (rawAmount) => {

    let amountStr = null === rawAmount || undefined === rawAmount ? "0" : rawAmount.toString();
    let amount;

    if (amountStr) {
        amount = amountStr.includes(".") ? parseFloat(amountStr) : parseInt(amountStr);
    } else {
        amountStr = "";
    }

    let formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: amountStr.includes(".") ? 2 : 0,
        maximumFractionDigits: amountStr.includes(".") ? 2 : 0,
    });

    return formatter.format(amount);
}

export const asNumber = (rawAmount) => {

    let amountStr = null === rawAmount || undefined === rawAmount ? "0" : rawAmount.toString();
    let rawValue = amountStr.toString();
    let baseAmount = parseInt((rawValue.split('.')[0]).replace(/\D/g, ''));

    if (!isNaN(baseAmount) && baseAmount > 0) {

        let formatter = new Intl.NumberFormat('en-CA', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return formatter.format(baseAmount);

    } else {
        return "";
    }


}

export const extractQuestions = (bundle, uiType) => {

    let newQuestions = [];

    switch (uiType) {

        case "carousel":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }
                    newQuestions.push(newQuestion);
                }

            }

            break;
        case "sorted":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.order = question.order;
                    newQuestion.help = question.help;
                    newQuestion.choices = new Map();
                    let unsortedChoices = [];
                    for (let [choiceId, choice] of Object.entries(question.choices)) {

                        unsortedChoices.push(choice);

                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }

                    // we sort the choices by id
                    unsortedChoices.sort((a, b) => a.order - b.order);
                    for (let item of unsortedChoices) {
                        newQuestion.choices.set(item.id, item);
                    }

                    newQuestions.push(newQuestion);
                }
            }
            newQuestions.sort((a, b) => a.order - b.order);

            break;
        case "toggle":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.drilldown && question.drilldown.length > 0) {
                        newQuestion.drilldown = question.drilldown;
                    }
                    if (undefined !== question.followUp && question.followUp.length > 0) {
                        newQuestion.followUp = question.followUp;
                    }
                    newQuestions.push(newQuestion);
                }
            }
            break;
        default:
            break;

    }

    return newQuestions;

}

export const getBrand = () => {

    let brand = window.location.hostname;
    return brand;
}

export const getBroker = () => {

    let sessionData = getTokenFromStorage();
    return sessionData.broker;
}



export const getCampaign = () => {

    let campaingInfo = {
        utm_campaign: '2022',
        utm_medium: 'link',
        utm_source: 'default'
    };

    const urlParams = new URLSearchParams(window.location.search);

    for (let [k] of Object.entries(campaingInfo)) {
        let value = urlParams.get(k);
        if (value) {
            campaingInfo[k] = value;
        }
    }

    return campaingInfo;
}

export const getEmailHash = (email) => {
    // we do it on lambda side    
    return "requested";
}

export const getTokenFromStorage = () => {

    let sessionData = sessionStorage.getItem("session");
    if (sessionData) {
        sessionData = JSON.parse(sessionData);
        sessionData.active = true;
    } else {
        sessionData = {};
    }

    return sessionData;

}

export const isPhone = (phone) => {

    let phonenum = phone.replace(/\D/g, '');
    if (phonenum.length >= 10) {
        return true;
    } else {
        return false;
    }


}

export const isEmail = (email) => {

    let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!email) return false;

    let emailParts = email.split('@');
    if (emailParts.length !== 2) return false

    let account = emailParts[0];
    let address = emailParts[1];

    if (account.length > 64) return false
    else if (address.length > 255) return false

    let domainParts = address.split('.');
    if (domainParts.some(function (part) {
        return part.length > 63;
    })) return false;

    if (!tester.test(email)) return false;

    return true;


}

export const asEmailAddress = (contactInfo) => {

    let emailAddress;

    if (contactInfo) {
        emailAddress = contactInfo.replace('mailto:', '');
    } else {
        emailAddress = contactInfo;
    }

    return emailAddress;

}

export const asPhoneNumber = (contactInfo) => {

    let phoneNumber;
    let phoneDigits = contactInfo.replace(/\D/g, '');

    switch (phoneDigits.length) {
        case '7':
            phoneNumber = [phoneDigits.substring(0, 3), phoneDigits.substring(3)].join('-');
            break;
        case '10':
            phoneNumber = "(" + phoneDigits.substring(0, 3) + ") " + [phoneDigits.substring(3, 6), phoneDigits.substring(6)].join('-');
            break;
        case '11':
            phoneNumber = "1 (" + phoneDigits.substring(1, 4) + ") " + [phoneDigits.substring(4, 7), phoneDigits.substring(7)].join('-');
            break;
        default:
            // leave it alone
            phoneNumber = contactInfo;
            break;
    }

    return phoneNumber;

}

export const asHyperlink = (contactInfo) => {

    let hyperlink = {
        text: contactInfo,
        href: '#'
    }

    if (contactInfo) {

        if (contactInfo.includes('@')) {
            if (isEmail(contactInfo)) {
                contactInfo = asEmailAddress(contactInfo);
                hyperlink.text = contactInfo;
                hyperlink.href = `mailto:${contactInfo}`
            }
        } else {
            let phonenum = contactInfo.replace(/\D/g, '');
            if (phonenum.length >= 10) {
                if (phonenum.length === 10) {
                    phonenum = `1${phonenum}`;
                }
                hyperlink.text = asPhoneNumber(contactInfo);
                hyperlink.href = `tel:+${phonenum}`

            }
        }

    }

    return hyperlink;

}

export const stateReducer = (currentState, changes) => {

    // we compose a clean copy
    let newState = { ...currentState };

    if (changes) {

        let updated = false;

        // we merge changes
        for (let [k, v] of Object.entries(changes)) {

            if (undefined === newState[k] || v !== newState[k]) {
                newState[k] = v;
                updated = true;
            }
        }

        if (updated) {
            // we track the latest update
            newState.asOf = Date.now();
        }

    }

    return { ...newState };

}



export const noHtml = (original) => {
    let doc = new DOMParser().parseFromString(original, 'text/html');
    return doc.body.textContent || "";
}

export const toFeet = ({ cm }) => {

    let preciseFt = convert(cm).from('cm').to('ft-us');
    let feet = Math.floor(preciseFt);
    let inches = Math.round(convert(preciseFt % 1).from('ft').to('in'));
    return { feet: feet, inches: inches };

}

export const toLbs = ({ kg }) => {

    let preciseWeight = convert(kg).from('kg').to('lb');
    return Math.round(preciseWeight);

}



export const toCm = ({ feet, inches }) => {

    let heights = [
        convert(feet).from('ft').to('cm'),
        convert(inches).from('in').to('cm')
    ];
    let cm = Math.round(heights[0] + heights[1]);
    return cm;

}

export const toKg = ({ lbs }) => {

    let preciseWeight = convert(lbs).from('lb').to('kg');
    return Math.round(preciseWeight);

}
