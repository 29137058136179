import {
    React,
    useEffect,
    useReducer,
    useState,
} from 'react';

import GA4React from 'ga-4-react';

import {
    downloadInsuranceData,
    getBranding,
    getGeo,
    getNotifications,
    getSession,
    saveQuote,
} from '../libs/cloud.js';
import { QuickApplyRoutes } from '../libs/routing';
import {
    asEmailAddress,
    asHyperlink,
    asPhoneNumber,
    getBrand,
    noHtml,
    stateReducer,
} from '../libs/utils.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from './context';

export const QuickApplyApp = ({ brokerBranding, brokerConfig, queryString }) => {


    const [geo, setGeo] = useState({});
    const [insuranceData, setInsuranceData] = useState({});
    const [notifications, setNotifications] = useState({});
    const [sessionFlag, setSessionFlag] = useState(0);
    const [session, setSession] = useReducer(stateReducer, {});
    const [layout, setLayout] = useReducer(stateReducer, { about: 0, policy: 0, coverage: 0 });
    const [branding, setBranding] = useReducer(stateReducer, {});
    const [initialized, setInitialized] = useReducer(stateReducer, {
        branding: false,
        geo: false,
        insuranceData: false,
        layout: false,
        notifications: false,
        session: false
    });


    useEffect(() => {

        if (undefined === branding || undefined === branding.ab) {
            let newBranding = { ...brokerBranding, ...brokerConfig };
            setBranding(newBranding);
            setInitialized({ branding: true });
        }

        if (undefined === session || undefined === session.id) {
            // we open a session 
            // the app is anonymous but we actually get a one-time guest user and token from Cognito
            // since the backend is broker-specific, the token is tagged with the relevant broker to avoid cross-broker traffic        
            getSession((sessionData) => {

                // the session includes the quote ID and the JWT for the newly created anonymous user        
                let newSession = {
                    ...sessionData
                };

                sessionData.queryString = queryString;

                if (sessionData.queryString && "" != sessionData.queryString.mode) {
                    sessionData.quoter_mode = sessionData.queryString.mode;
                } else {
                    sessionData.quoter_mode = "default";
                }
                setSession({ "type": "session", ...sessionData });
                setInitialized({ session: true });

                console.info("Session", sessionData);

                downloadInsuranceData((data) => {
                    setInsuranceData(data);
                    setInitialized({ insuranceData: true });
                    console.info("Insurance data", data);
                });

            });
        }


    }, []);

    //useEffect(() => {},[session.id])

    useEffect(() => {


        if (undefined === geo || undefined === geo.country) {


            // we use IP geolocation although resolution is cached in DynamoDB (see Lambda functions in modules)
            getGeo((geoData) => {
                let newGeo = {
                    version: Date.now(),
                    ...geoData
                };

                if ("Canada" === newGeo.country) {
                    setGeo(newGeo);
                }
                setInitialized({ geo: true });

            });

        } else {
            if (undefined !== brokerBranding) {

                let newLayout = {
                    version: Date.now(),
                    stage: "intro",
                    device: window.innerWidth < 500 ? "mobile" : "standard",
                    //device: "mobile",
                    phone: asPhoneNumber(brokerBranding.phone),
                    email: asEmailAddress(brokerBranding.email)
                };

                console.info(
                    "layout", newLayout
                );

                if (geo.province_code) {
                    // we display the regional contact info, if present
                    let phoneAttribute = "phone_" + geo.province_code.toLowerCase();
                    let emailAttribute = "email_" + geo.province_code.toLowerCase();
                    if (branding[phoneAttribute]) {
                        newLayout.phone = asPhoneNumber(brokerBranding[phoneAttribute]);
                    }
                    if (branding[emailAttribute]) {
                        newLayout.email = asEmailAddress(brokerBranding[emailAttribute]);
                    }

                    let updatedEmail = asHyperlink(newLayout.email);
                    newLayout.emailHref = updatedEmail.href;

                    if ("mobile" === newLayout.device) {
                        let updatedPhone = asHyperlink(newLayout.phone);
                        newLayout.phoneHref = updatedPhone.href;
                    }

                    setLayout(newLayout);
                    setInitialized({ layout: true });

                }

            }
        }


    }, [geo, brokerBranding]);



    useEffect(() => {


        if (["desktop", "standard", "mobile"].includes(layout.device)) {

            if ("standard" === layout.device) {
                let backgroundImage = branding.background ? branding.background : "bg_neutral";
                backgroundImage = backgroundImage.replace('.jpg', ''); // just in case
                document.body.style.backgroundImage = `url('/images/${backgroundImage}.jpg')`;

                let cssLink = document.createElement("link");
                cssLink.id = "brandingcss";
                cssLink.href = "/desktop.css";
                cssLink.type = "text/css";
                cssLink.rel = "stylesheet";
                cssLink.media = "screen,print";
                document.getElementsByTagName("head")[0].appendChild(cssLink);

            }
        }



    }, [layout.device, branding.background]);


    useEffect(() => {

        if (undefined !== branding.ab) {

            if (branding.ga) {

                const ga4react = new GA4React(branding.ga);
                ga4react.initialize().then((ga4) => {
                    let g4BrandingData = {
                        ga4: ga4
                    };
                    console.info("GA set");
                    setBranding(g4BrandingData);
                }, (err) => {
                    console.error(err);
                })

            }
        }

    }, [branding.ab, branding.ga]);

    useEffect(() => {

        // did we get a MIB verdict from Approve?
        if (undefined !== notifications.verdict && notifications.verdict.length > 1) {

            console.info("Received a verdict", notifications);

            let newSessionEntry = {
                approved: "OK" === notifications.verdict ? "yes" : "no"
            };
            newSessionEntry.verdict = notifications.verdict;
            console.info("Updating session based on verdict");
            setSession(newSessionEntry);

        }

        // is a policy PDF now available?
        if (undefined !== notifications.document && notifications.document.length > 1) {

            let newSessionEntry = {
                download: "ready"
            };

            setSession(newSessionEntry);

        }

    }, [notifications.document, notifications.message, notifications.verdict]);

    useEffect(() => {

        if (geo.source && session.broker) {

            // we determine if the broker is licensed in the customer's province (based on IP geolocation)
            // if the broker is not licensed in this province, we will get confirmation from the customer as to their actual location
            const checkLicensing = () => {

                let licensed = "no";

                if (geo.province_code && branding.broker) {
                    let licenseAttribute = "authorized_" + geo.province_code.toLowerCase();
                    licensed = branding[licenseAttribute] === "yes" ? "yes" : "no";
                    console.info("license attribute", licenseAttribute, branding[licenseAttribute]);
                }

                return licensed;

            }

            let newSessionEntry = {};

            // we merge our geolocation info in the session
            for (let [k, v] of Object.entries(geo)) {
                if (k) {
                    newSessionEntry["geo_" + k] = v;
                }
            }

            // note that we use snake_case for session attributes, see our guiding principles in confluence about this
            newSessionEntry.ab = branding.ab;
            newSessionEntry.province = geo.province;
            newSessionEntry.province_current = geo.province_code ? geo.province_code.toLowerCase() : "";
            newSessionEntry.province_birth = newSessionEntry.province_current;
            newSessionEntry.city = newSessionEntry.geo_city;
            newSessionEntry.country = newSessionEntry.geo_country;
            newSessionEntry.postal_code = newSessionEntry.geo_postal;

            // for commission etc
            newSessionEntry.approve_company = branding.approve_company;
            newSessionEntry.approve_user = branding.approve_user;

            // now we check broker licensing, which is tied to geolocation at first
            newSessionEntry.licensed = checkLicensing();
            if ("no" === newSessionEntry.licensed) {
                newSessionEntry.disqualified = { jurisdiction: "yes" };
            }

            // screen order
            if (undefined !== branding.screens && undefined !== branding.screens.landing) {
                newSessionEntry.happyPath = branding.screens;
            } else {

                const defaultPath = {
                    'landing': 'quoter',
                    'quoter': 'introduction',
                    'introduction': 'lifestyle',
                    'lifestyle': 'address',
                    'address': 'diagnosis',
                    'diagnosis': 'last10',
                    'last10': 'last5',
                    'last5': 'last2',
                    'last2': 'medical',
                    'medical': 'misc',
                    'misc': 'occupation',
                    'occupation': 'beneficiary',
                    'beneficiary': 'payment',
                    'payment': 'consent',
                    'consent': 'policy'
                };


                if (branding.happy_path) {

                    let screenSequence = branding.happy_path.split(',');

                    let customPath = {};


                    try {

                        for (let i = 0; i < screenSequence.length; i++) {
                            if (0 === i) {
                                customPath[screenSequence[i]] = screenSequence[i + 1];
                            } else {
                                if (i <= screenSequence.length - 1) {
                                    customPath[screenSequence[i - 1]] = screenSequence[i];
                                    customPath[screenSequence[i]] = screenSequence[i + 1];
                                } else {
                                    customPath[screenSequence[i - 1]] = screenSequence[i];
                                }
                            }
                        }
                    } catch (e) {
                        console.info("Cannot rebuild path");
                        customPath = {}
                    }


                    if (customPath && Object.keys(customPath).length > 14) {
                        //// workaround for proto
                        customPath = defaultPath;
                        ////
                        newSessionEntry.happyPath = customPath;
                        console.info("Setting custom path", customPath);
                    } else {
                        newSessionEntry.happyPath = defaultPath;
                        console.info("Reverting path", defaultPath);
                    }

                } else {
                    newSessionEntry.happyPath = defaultPath;
                }


            }

            console.info("Saving session with path", newSessionEntry);
            setSession(newSessionEntry);

            // this triggers the polling for notifications
            setInitialized({ notifications: true });

        }
    }, [geo.source, geo.province_code, branding.ab, branding.happy_path, session.broker, branding.approve_company, branding.approve_user]);


    useEffect(() => {

        if (initialized.notifications) {

            // here we ping our web service every few seconds to see if we have a new message from the broker or from Approve
            const checkNotifications = () => {

                if (session.quote) {

                    getNotifications((notificationsData) => {

                        let relevant = false;

                        if (notificationsData.notifications && notificationsData.notifications.length > 0) {

                            // there are three types of notifications, each correspond to a specific attribute in the session object
                            let newNotifications = {
                                document: '',
                                message: '',
                                verdict: ''
                            };

                            for (let notification of notificationsData.notifications) {
                                for (let [k, v] of Object.entries(notification)) {
                                    if (Object.keys(newNotifications).includes(k)) {
                                        newNotifications[k] = noHtml(v);
                                        relevant = true;
                                    }
                                }
                            }

                            if (relevant) {
                                setNotifications(newNotifications);
                            }

                        }

                    });

                }

            }

            const timer = setInterval(() => checkNotifications(session.quote), 10000);
            return () => clearInterval(timer);
        }
    }, [initialized, session.quote, session.asOf, sessionFlag]);

    useEffect(() => {

        if (sessionFlag !== session.asOf) {

            if (branding.ab && session.broker) {
                saveQuote({ ...session }, (data) => {
                    setSessionFlag(session.asOf);
                });
            }

        }

    });


    return (

        <SessionContext.Provider value={{ session, setSession }}>
            <GeoContext.Provider value={{ geo }}>
                <BrandingContext.Provider value={{ branding }}>
                    <InsuranceDataContext.Provider value={{ insuranceData }}>
                        <LayoutContext.Provider value={{ layout, setLayout }}>
                            <NotificationsContext.Provider value={{ notifications }}>
                                <>
                                    {initialized && initialized.branding ?
                                        <>
                                            {QuickApplyRoutes({ ready: true, initialized: initialized })}
                                        </>
                                        :
                                        <>&nbsp;</>
                                    }
                                </>
                            </NotificationsContext.Provider>
                        </LayoutContext.Provider>
                    </InsuranceDataContext.Provider>
                </BrandingContext.Provider>
            </GeoContext.Provider>
        </SessionContext.Provider>
    );

}
