import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    ListGroup,
    Row,
} from 'react-bootstrap';
import {
    BsChevronRight,
    BsUnlock,
} from 'react-icons/bs';
import {
    FaShippingFast,
    FaTasks,
} from 'react-icons/fa';
import { TbFileDollar } from 'react-icons/tb';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import {
    ageNearest,
    prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
    asCurrency,
    isEmail,
    isPhone,
} from '../libs/utils';
import {
    CalendarControl,
    DollarControl,
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { ContactForm } from './contact';
import { CoverageSlider } from './coverage';
import { Faq } from './faq';
import {
    BasicConsentForm,
    CompactConsentForm,
} from './privacy-widgets';
import { PromoCarousel } from './promo-carousel';
import { QuoteEssentials } from './quote-essentials';

export const ConditionalQuoter = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const [screenRatio, setScreenRatio] = useState(false);

    useEffect(() => {

        setScreenRatio(window.devicePixelRatio);
        let newLayout = {
            zoomed: window.devicePixelRatio > 1
        }
        setLayout(newLayout);

    }, []);

    //window.visualViewport.scale

    return (
        <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>

            {screenRatio &&
                <>
                    {/* {"abc" === layout.zoomed ?
                        <>
                            <Row>
                                <Col>
                                    <IdentificationArea />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <PremiumArea />
                                </Col>
                            </Row>
                        </>
                        : */}
                    <>
                        {"mobile" === layout.device ?
                            <>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <IdentificationArea />
                                    </Col>
                                </Row>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <PremiumArea />
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <IdentificationArea />
                                    </Col>
                                    <Col>
                                        <PremiumArea />
                                    </Col>
                                </Row>
                            </>
                        }

                    </>
                    {/* } */}
                </>
            }


        </Container>
    )

}

const IdentificationArea = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <Container fluid className={"mobile" === layout.device ? 'px-0 py-0 mt-0 mb-2 ml-0 mr-0' : 'mb-1'}>

            <Row>
                <Col>
                    <ContactForm />
                </Col>
            </Row>
            <Row className='mb-0 pb-0'>
                <Col>
                    <QuoteEssentials />
                </Col>
            </Row>

            <Row>
                <Col>
                    <BasicConsentForm />

                </Col>
            </Row>
        </Container>
    )

}

const PremiumArea = () => {

    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [hasOffers, setHasOffers] = useState(false);
    const [hasCarousel, setHasCarousel] = useState(true);
    const [viewMode, setViewMode] = useState('slider');

    useEffect(() => {

        if (branding.carousel && "hide" === branding.carousel) {
            setHasCarousel(false);
        }
        if ("mobile" === layout.device) {
            setHasCarousel(false);
        }

    }, [branding.carousel]);

    useEffect(() => {

        if (session.analysis_coverage && session.analysis_coverage !== session.facevalue) {
            let newSessionEntry = {
                facevalue: session.analysis_coverage
            }
            setSession(newSessionEntry);
        }


    }, [session.analysis_coverage]);

    useEffect(() => {

        if (session.offers) {
            setHasOffers(true);
        }

    }, [session.offers]);

    const onAnalysisChange = (k, v) => {

        let newSessionEntry = {};
        newSessionEntry['analysis_coverage'] = v;
        console.info("Update from analysis", newSessionEntry);
        setSession(newSessionEntry);

    }

    return (
        <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>

            {'analysis' === viewMode ?
                <>
                    {"mobile" === layout.device ?
                        <>
                            <Row className='px-0 py-0 ml-0 mr-0'>
                                <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                    <NeedsAnalysis onParentChange={onAnalysisChange} setViewMode={setViewMode} />
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <NeedsAnalysis onParentChange={onAnalysisChange} setViewMode={setViewMode} />
                                </Col>
                            </Row>
                        </>
                    }

                </>
                :
                <>
                    <Row className={"mobile" === layout.device ? 'px-0 py-0 ml-0 mr-0' : 'mb-1'}>
                        <Col>
                            <CoverageSlider />
                        </Col>
                    </Row>
                    <Row className={"mobile" === layout.device ? 'px-0 py-0 ml-0 mr-0' : 'mb-1'}>
                        <Col className="text-muted text-center">
                            Not sure how much insurance you need? <Badge id="analysis-open" className='clicker' onClick={() => setViewMode("analysis")}>Find out</Badge>
                        </Col>
                    </Row>
                    <Row className={"mobile" === layout.device ? 'px-0 py-0 ml-0 mr-0' : 'mb-1'}>
                        <Col className={"mobile" === layout.device ? 'col-12 px-0 py-0 ml-0 mr-0' : 'mb-1'}>
                            {hasOffers ?
                                <>
                                    {session.is_unlocked ?
                                        <OffersArea />
                                        :
                                        <Card style={{ width: '24rem' }} border="secondary" className="less-faint mt-4 mx-auto">
                                            <Card.Body className="text-muted">
                                                <h1 className='text-center'>
                                                    <FaTasks />
                                                </h1>
                                                <Card.Text>
                                                    You're almost there! Please complete the form to unlock your instance quote.
                                                </Card.Text>
                                                {"no" === session.privacy &&
                                                    <Card.Text>
                                                        You must provide your consent before moving forward.
                                                    </Card.Text>
                                                }
                                            </Card.Body>
                                        </Card>
                                    }

                                </>
                                :
                                <>
                                    <Card style={{ width: '24rem' }} border="secondary" className="faint mt-4 mx-auto">
                                        <Card.Body className="text-muted">
                                            <h1 className='text-center'>
                                                <TbFileDollar />
                                            </h1>
                                            <Card.Text>
                                                Your instant quote will appear here as soon as the required information is provided.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                </>
                            }
                        </Col>
                    </Row>
                </>
            }



        </Container>
    )

}

const OffersArea = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const [riderSelected, setRiderSelected] = useState(null);
    const [baseAmounts, setBaseAmounts] = useState(null);
    const [riders, setRiders] = useState(null);
    const [hasClicked, setHasClicked] = useState(false);


    let navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {


        if (session.offers && undefined !== session.offers.term10 && undefined !== session.offers.term10) {


            let terms = ["term10", "term20"];
            let newBaseAmounts = { ...baseAmounts };
            let newRiders = { ...riders };

            for (let term of terms) {

                if (undefined !== session.offers[term]) {


                    if ("Yes" === session.smoker) {
                        newBaseAmounts[term] = session.offers[term].smoker.norider.monthly;
                        newRiders[term] = session.offers[term].smoker.rider.monthly - session.offers[term].smoker.norider.monthly;

                    } else {
                        newBaseAmounts[term] = session.offers[term].nonsmoker.norider.monthly;
                        newRiders[term] = session.offers[term].nonsmoker.rider.monthly - session.offers[term].nonsmoker.norider.monthly;
                    }

                } else {
                    newBaseAmounts[term] = null;
                    newRiders[term] = null;
                }

            }

            setBaseAmounts(newBaseAmounts);
            setRiders(newRiders);

        }


        /*
                let newBlurAmounts = {
                    term10: "$" + newBaseAmounts.term10.toString().replace(new RegExp("[0-9]", "g"), "?"),
                    term20: "$" + newBaseAmounts.term20.toString().replace(new RegExp("[0-9]", "g"), "?")
                };
        
                setBlurAmounts(newBlurAmounts);*/



    }, [session.offers]);



    useEffect(() => {

        if (hasClicked) {
            let newSessionEntry = { ...session };
            let flow = getNextStep(location, newSessionEntry);
            console.log("FLOW", flow);
            setSession(flow);
            navigate(flow.next);
        }

    }, [hasClicked, session]);


    const onRiderChange = (e) => {
        setRiderSelected(e.target.checked);
    }

    const onSelection = (e) => {


        let newSessionEntry = {
            premium: e.premium.replace('$', ''),
            rider: e.rider,
            term: e.term
        }
        setSession(newSessionEntry);
        setHasClicked(true);



    }


    return (
        <>
            {baseAmounts &&
                <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>
                    <Row className='mt-3'>
                        <Col>
                            <b>Choose the offer below that best suits your needs:</b>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <PriceCard preview={false} controlId={"term10"} term="10" monthly={baseAmounts.term10} riderPrice={riders.term10} riderSelected={riderSelected} featured={false} onSelection={onSelection} />
                        </Col>
                        <Col>
                            <PriceCard preview={false} controlId={"term20"} term="20" monthly={baseAmounts.term20} riderPrice={riders.term20} riderSelected={riderSelected} featured={false} onSelection={onSelection} />
                        </Col>
                    </Row>

                    <Row className='mt-4 mb-4'>
                        <Col className='col-12'>
                            <h5>
                                {branding.quoter_waivertitle || "Extra peace of mind?"}
                            </h5>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Form.Check onChange={(e) => onRiderChange(e)}
                                        type="switch"
                                        id="custom-switch"
                                        label={branding.quoter_waiver || "Include premium waiver for total disability"}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>

                </Container>
            }
        </>
    )

}




const PriceCard = ({ preview, controlId, term, monthly, riderPrice, riderSelected, featured, onSelection }) => {

    const { session } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const [optin, setOptin] = useState(false);
    const [total, setTotal] = useState("");
    const [activePlan, setActivePlan] = useState(false);

    const [labels, setLabels] = useState({
        button: "Buy now!",
        header: "Price guaranteed for " + term + "!",
        footer: "Great buy"
    });

    useEffect(() => {
        if (branding.ab) {
            let newLabels = { ...labels };
            let updated = false;
            switch (term) {
                case "10 years":
                    if (branding.quoter_term10header) {
                        newLabels.header = branding.quoter_term10header;
                        updated = true;
                    }
                    if (branding.quoter_term10button) {
                        newLabels.button = branding.quoter_term10button;
                        updated = true;
                    }
                    if (branding.quoter_term10footer) {
                        newLabels.footer = branding.quoter_term10footer;
                        updated = true;
                    }
                    break;
                case "20 years":
                    if (branding.quoter_term20header) {
                        newLabels.header = branding.quoter_term20header;
                        updated = true;
                    }
                    if (branding.quoter_term20button) {
                        newLabels.button = branding.quoter_term20button;
                        updated = true;
                    }
                    if (branding.quoter_term20footer) {
                        newLabels.footer = branding.quoter_term20footer;
                        updated = true;
                    }
                    break;
                default:
                    break;
            }
            if (updated) {
                setLabels(newLabels);
            }
        }
    }, [branding]);

    useEffect(() => {
        setOptin(riderSelected);
    }, [riderSelected]);
    // {branding.quoter_waiver || "Include premium waiver for total disability"}
    useEffect(() => {

        let newTotalValue = monthly;
        if (optin) {
            newTotalValue = parseFloat(monthly.toString()) + parseFloat(riderPrice.toString());
        } else {
            newTotalValue = parseFloat(monthly.toString());
        }
        let newTotal = asCurrency(newTotalValue);
        setTotal(newTotal);
    }, [monthly, riderPrice, optin]);


    const onChange = (e) => {
        setOptin(e.target.checked);
    }

    const onClick = (e) => {
        let selection = {
            premium: total,
            rider: optin ? "yes" : "no",
            term: controlId.replace("term", "")
        };
        onSelection(selection);
    }

    return (
        <>
            <ListGroup>
                <ListGroup.Item action onClick={onClick} className="p-0 border-0">
                    <Card
                        border={activePlan === term ? "warning" : "primary"}
                        bg={activePlan === term ? "light" : "white"}
                        text={activePlan === term ? "dark" : "dark"}
                        className="shadow rounded"
                        onMouseEnter={() => {
                            setActivePlan(term);
                        }}
                        onMouseLeave={() => {
                            setActivePlan(false);
                        }}
                    >
                        <Card.Header>
                            <BsChevronRight /> {term} Year Plan
                        </Card.Header>
                        <Card.Body>

                            <h2 className="card-title pricing-card-title">
                                {preview ?
                                    total.replace(new RegExp("[0-9]", "g"), "?")
                                    :
                                    total
                                }

                                <small className="text-muted fw-light">/mo</small></h2>

                            <p><small>Price Guaranteed</small></p>

                        </Card.Body>
                    </Card>
                </ListGroup.Item>
            </ListGroup>
        </>




    );
}

