import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { extractQuestions } from '../libs/utils';
import { RadioControl } from '../screen/controls';
import { Physical } from './physical';

export const Lifestyle = () => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);

    const [lifestyleOutcome, setLifestyleOutcome] = useState("incomplete");

    const [physical, setPhysical] = useState(false);
    const [lifestyle, setLifestyle] = useState(false);
    const [purpose, setPurpose] = useState(false);
    const [highlighted, setHighlighted] = useState({
        physical: false,
        lifestyle: false,
        purpose: false
    });
    const [locked, setLocked] = useState(true);
    const [validated, setValidated] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (session.purpose) {
            setPurpose(true);
        }

    }, [session.purpose, purpose]);

    useEffect(() => {

        if (!locked) {

            // careful here: we don't want to simply toggle the highlight flag
            // we want to remove existing highlights if applicable, nothing more
            // new highlights will be handled by the Continue button

            let newHighlighted = { ...highlighted };

            if (physical) {
                newHighlighted.physical = false;
            }
            if (lifestyle) {
                newHighlighted.lifestyle = false;
            }

            if (purpose) {
                newHighlighted.purpose = false;
            }

            if (lifestyle && physical && purpose) {
                setValidated(true);
            }

            setHighlighted(newHighlighted);
        }

    }, [physical, lifestyle, purpose]);

    useEffect(() => {

        if ("incomplete" === lifestyleOutcome) {
            setLifestyle(false);
        } else {
            // also disqualify
            setLifestyle(true);
        }

    }, [lifestyleOutcome]);



    useEffect(() => {

        if (insuranceData.bundled) {
            //todo use questions from the lifestyle bundle 
            setLocked(false);
        }

    }, [insuranceData.bundled]);

    useEffect(() => {

        if (session.lifestyle && session.height && session.weight && session.purpose) {
            setValidated(true); // mostly for back button behavior
        }

    }, [session.height, session.weight, session.lifestyle, session.purpose]);

    //todo use questions from the bundle 

    const purposeChoices = {
        "Personal": "Personal",
        "Business": "Business"
    }

    const moveForward = () => {
        let flow = getNextStep(location, { ...session });
        setSession(flow);
        navigate(flow.next);
    }

    const validate = () => {

        let newHighlighted = {
            physical: !physical,
            lifestyle: !lifestyle,
            purpose: !purpose
        }

        if (lifestyle && physical && purpose) {
            setValidated(true);
        }

        setHighlighted(newHighlighted);

    }

    const onChange = (controlId, value) => {

        if (value) {
            let newSessionEntry = {};
            newSessionEntry[controlId] = value;

            switch (controlId) {
                case "purpose":
                    setPurpose(true);
                    break;
                default:
                    break;
            }

            setSession(newSessionEntry);

        }

    }

    return (
        <>

            <Container fluid>
                <Row>
                    <Col className='col-12'>
                        <h1>Tell us about yourself</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <Physical setCompleted={setPhysical} highlighted={highlighted.physical} />
                    </Col>
                </Row>

                <LifeStyleQuestions helpVisible={highlighted.lifestyle} setOutcome={setLifestyleOutcome} />

                <Row>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={highlighted.purpose} helpText="Please choose the answer that best describes the purpose for this insurance policy" controlId="purpose" caption="What is the purpose for this insurance policy?" onChange={onChange} choices={{ items: purposeChoices, default: session.purpose }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        {!locked &&
                            <>
                                {validated ?
                                    <Button id="lifestyle-continue" onClick={() => moveForward()} variant="primary">Continue</Button>
                                    :
                                    <Button onClick={() => validate()} variant="outline-primary">Continue</Button>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Container>

        </>
    )

}


const LifeStyleQuestions = ({ helpVisible, setOutcome }) => {

    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [questions, setQuestions] = useState([]);
    const [heading, setHeading] = useState("");
    const [parentQuestion, setParentQuestion] = useState(false);
    const [answers, setAnswers] = useState({});

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        if (undefined !== insuranceData.bundled && undefined !== insuranceData.bundled["lifestyle"]) {
            let newQuestions = extractQuestions(insuranceData.bundled["lifestyle"], "sorted");

            let newHeading;
            let newParentQuestion;
            if (undefined !== insuranceData.bundled["lifestyle"].questions) {
                for (let item of insuranceData.bundled["lifestyle"].questions) {
                    if ("heading" === item.type) {
                        newHeading = item.copy;
                        newParentQuestion = item.id;
                    }
                }
            }
            setQuestions(newQuestions);
            setHeading(newHeading);
            setParentQuestion(newParentQuestion);
        }

    }, [insuranceData]);


    useEffect(() => {

        if (questions && questions.length > 0) {
            let newAnswers = {};
            for (let question of questions) {
                if (undefined !== session["question_" + question.id]) {
                    newAnswers["question_" + question.id] = session["question_" + question.id];
                }
            }
            if (Object.entries(newAnswers).length > 0) {
                setAnswers(newAnswers);
                console.info("Reloading session values", newAnswers);
            }
        }

    }, [questions]);

    useEffect(() => {

        if (answers && Object.entries(answers).length > 1 && Object.entries(answers).length === questions.length) {

            let qualified = 0;
            let disqualified = 0;
            for (let question of questions) {
                if (answers["question_" + question.id]) {
                    if ("application" === answers["question_" + question.id].path) {
                        qualified++;
                    } else {
                        disqualified++;
                    }
                }
            }
            if (qualified === questions.length) {
                setOutcome("completed");
            } else {
                if (disqualified > 0) {
                    setOutcome("disqualified");
                } else {
                    setOutcome("incomplete");
                }
            }
        } else {
            setOutcome("incomplete");
        }

    }, [answers]);

    const onChange = (question, answer) => {

        for (let q of questions) {
            if ("question_" + q.id === question) {
                for (let [k, v] of q.choices.entries()) {
                    if (answer === k) {
                        let newAnswers = { ...answers };
                        newAnswers["question_" + q.id] = v;
                        setAnswers(newAnswers);
                        let newSessionEntry = {};
                        newSessionEntry["question_" + q.id] = v;
                        newSessionEntry["lifestyle_" + q.id] = v.id;

                        if ("application" === v.path) {
                            // we may need to un-disqualify 
                            if (undefined !== session.disqualified && undefined !== session.disqualified.questions) {
                                if (session.disqualified.questions.includes(q.id)) {
                                    let newDisqualified = [];
                                    for (let item of session.disqualified.questions) {
                                        if (q.id !== item) {
                                            newDisqualified.push(item);
                                        }
                                    }
                                    newSessionEntry.disqualified = {};
                                    newSessionEntry.disqualified.questions = newDisqualified;
                                }
                            }
                        }
                        else {
                            if (undefined === session.disqualified) {
                                newSessionEntry.disqualified = {};
                            } else {
                                newSessionEntry.disqualified = { ...session.disqualified };
                            }
                            if (undefined === newSessionEntry.disqualified.questions) {
                                newSessionEntry.disqualified.questions = [];
                            }

                            newSessionEntry.disqualified.questions.push(q.id);
                        }

                        setSession(newSessionEntry);
                    }
                }
            }
        }

    }

    return (
        <>
            {questions.map((question, index) =>
                <Row key={index}>
                    <Col className='col-md-12 col-lg-10'>
                        <RadioControl highlighted={helpVisible} helpText={question.help} controlId={"question_" + question.id} caption={question.copy} onChange={onChange} choices={{ items: question.choices, default: session["lifestyle_" + question.id] }} />
                    </Col>
                </Row>
            )}
        </>
    );


}