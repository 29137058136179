import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Accordion,
    Button,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { getNextStep } from '../libs/routing.js';
import { extractQuestions } from '../libs/utils';
import { DetailsForm } from '../screen/wizards';

export const CurrentOccupation = (props) => {

    const { session, setSession } = useContext(SessionContext);
    const { insuranceData, setInsuranceData } = useContext(InsuranceDataContext);
    const [category, setCategory] = useState(false);
    const [clicked, setClicked] = useState(0);
    const [heading, setHeading] = useState("");
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [viewMode, setViewMode] = useState("list");
    const [outcome, setOutcome] = useState("incomplete");
    const [helpVisible, setHelpVisible] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {

        if (undefined !== insuranceData.bundled && undefined !== insuranceData.bundled["occupation"]) {
            let questions = extractQuestions(insuranceData.bundled["occupation"], "carousel");
            for (let question of questions) {
                if (question.bullets) {

                    let newOccupations = [[], []];
                    for (let i = 0; i < question.bullets.length; i++) {

                        let subQuestion = {
                            copy: question.bullets[i],
                            id: i,
                            parentId: question.id,
                            yes: question.yes,
                            no: question.no
                        }

                        if (i <= question.bullets.length / 2) {
                            newOccupations[0].push(subQuestion);
                        } else {
                            newOccupations[1].push(subQuestion);
                        }

                    }

                    setOccupations(newOccupations);
                    setParentQuestion(question);
                    setHeading(question.copy);
                }
            }
        }

    }, [insuranceData]);


    useEffect(() => {

        console.info("outcome has changed", outcome);

    }, [outcome]);

    const showHelp = () => {

        setHelpVisible(true);
        setTimeout(() => {
            setHelpVisible(false);
        }, 5000);
    }


    const onSave = (e) => {
        //todo refactor

        let newSessionEntry = getNextStep(location, { ...session });
        let answer;

        switch (outcome) {
            case "disqualified":
                answer = {
                    id: parentQuestion.yes,
                    text: "Yes",
                    path: "queue"
                };

                if (undefined === session.disqualified) {
                    newSessionEntry.disqualified = {};
                } else {
                    newSessionEntry.disqualified = { ...session.disqualified };
                }
                if (undefined === newSessionEntry.disqualified.questions) {
                    newSessionEntry.disqualified.questions = [];
                }

                newSessionEntry.disqualified.questions.push(parentQuestion.id);

                break;
            case "completed":
                answer = {
                    id: parentQuestion.no,
                    text: "No",
                    path: "application"
                };

                // we must un-disqualify, if applicable

                if (undefined !== session.disqualified && undefined !== newSessionEntry.disqualified.questions) {
                    if (newSessionEntry.disqualified.questions.includes(parentQuestion.id)) {
                        let newDisqualified = [];
                        for (let item of newSessionEntry.disqualified.questions) {
                            if (parentQuestion.id !== item) {
                                newDisqualified.push(item);
                            }
                        }
                        newSessionEntry.disqualified.questions = newDisqualified;
                    }
                }

                break;
            default:
                // shouldn't happen
                setHelpVisible(true);
                break;
        }

        newSessionEntry["question_" + parentQuestion.id] = answer;
        setSession(newSessionEntry);
        navigate(newSessionEntry.next);


    }

    const validate = () => {

        let isValidated = true;



        if (isValidated) {
            // onSave()

        }
    }

    return (
        <Container fluid>

            {"details" === viewMode ?
                <>
                    <DetailsForm onCancel={(e) => setViewMode("accordion")} onSave={onSave} />
                </>
                :
                <>
                    {parentQuestion.id &&
                        <>
                            <Row className='mt-4'>
                                <Col>
                                    <h5>{heading}</h5></Col>
                            </Row>
                            {helpVisible &&
                                <Row className='mt-4'>
                                    <Col>
                                        <Form.Text className="text-muted">
                                            Please review both sections.
                                        </Form.Text>
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-4'>
                                <Col>
                                    <RadioAccordion questions={occupations} setOutcome={setOutcome} />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                {"incomplete" == outcome ?
                                    <Col>
                                        <Button onClick={(e) => showHelp()} variant="outline-primary" size="lg">Continue</Button>
                                    </Col>
                                    :
                                    <Col>
                                        <Button onClick={() => onSave()} variant="primary" size="lg">Continue</Button>
                                    </Col>
                                }

                            </Row>
                        </>
                    }


                </>
            }

        </Container>
    )

}


const RadioAccordion = ({ questions, setOutcome }) => {

    const { session, setSession } = useContext(SessionContext);
    const [activeKey, setActiveKey] = useState(false);
    const [answers, setAnswers] = useState({});
    const [ready, setReady] = useState(false);


    useEffect(() => {

        if (questions.length > 0) {
            let newAnswers = {};

            for (let i = 0; i < questions.length; i++) {
                if (undefined !== session["occupations_" + i]) {
                    newAnswers["occupations_" + i] = session["occupations_" + i];
                } else {
                    newAnswers["occupations_" + i] = {};
                }
            }
            setAnswers(newAnswers);
            changeFocus();
            setReady(true);
        }

    }, [questions]);

    useEffect(() => {

        if (Object.entries(answers).length > 0) {
            changeFocus();
            if (Object.entries(answers).length === questions.length) {
                // everything has been answered
                let disqualified = [];
                let qualified = [];

                for (let [question_id, answer] of Object.entries(answers)) {
                    switch (answer.text) {
                        case "No":
                            qualified.push(answer.id);
                            break;
                        case "Yes":
                            disqualified.push(answer.id);
                            break;
                        default:
                            break;
                    }
                }
                if (disqualified.length > 0) {
                    setOutcome("disqualified");
                } else {
                    if (qualified.length === questions.length) {
                        setOutcome("completed");
                    } else {
                        setOutcome("incomplete");
                    }

                }
            } else {
                setOutcome("incomplete");
            }
        } else {
            setOutcome("incomplete");
        }

    }, [answers]);

    const changeFocus = () => {

        for (let i = 0; i < questions.length; i++) {
            if (undefined === answers["occupations_" + i] || !["Yes", "No"].includes(answers["occupations_" + i].text)) {
                setActiveKey("occupations_" + i);
                break;
            }
        }

    }

    const onCheck = (bucket, choice, text) => {
        //todo refactor to use bundle path
        let newAnswers = { ...answers };
        let newSessionEntry = {};
        newAnswers["occupations_" + bucket] = {
            id: choice,
            text: text,
            path: "Yes" === text ? "queue" : "application"
        };
        setAnswers(newAnswers);

        if ("Yes" === text) {
            console.info("Disqualify occupation");

            if (undefined === session.disqualified) {
                newSessionEntry.disqualified = {};
            } else {
                newSessionEntry.disqualified = { ...session.disqualified };
            }
            if (undefined === newSessionEntry.disqualified.occupation) {
                newSessionEntry.disqualified.occupation = [];
            }
            newSessionEntry.disqualified.occupation.push("occupation_" + bucket);

        } else {
            if (undefined !== session.disqualified && undefined !== session.disqualified.occupation) {
                newSessionEntry.disqualified = { ...session.disqualified };
                let newOccupations = [];
                for (let item of session.disqualified.occupation) {
                    if (item !== "occupation_" + bucket) {
                        newOccupations.push(item);
                    }
                }
                newSessionEntry.disqualified.occupation = newOccupations;
            }
        }

        newSessionEntry["occupations_" + bucket] = newAnswers["occupations_" + bucket];
        setSession(newSessionEntry);
    }

    return (
        <Container fluid>
            {ready ?
                <Row>
                    <Col>
                        <Accordion activeKey={activeKey} flush>
                            {questions.map((question, index) =>

                                <Accordion.Item key={index + 1} eventKey={"occupations_" + index}>

                                    <Accordion.Header className="bg-info" onClick={() => setActiveKey("occupations_" + index)}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    {index + 1} of {questions.length}
                                                </Col>
                                                <Col className='text-end text-right'>
                                                    {answers["occupations_" + index] && ["Yes", "No"].includes(answers["occupations_" + index].text) &&
                                                        <>
                                                            <small className='text-muted'>your answer: {answers["occupations_" + index].text}</small>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div>
                                            <ul>
                                                {question.map((choice, choiceIndex) =>
                                                    <li key={choiceIndex}>{choice.copy}</li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <Form.Check
                                                inline
                                                label="No"
                                                name={"occupations_" + index}
                                                type="radio"
                                                id={question.no}
                                                onChange={(e) => onCheck(index, index, "No")}
                                                checked={answers["occupations_" + index] && "No" === answers["occupations_" + index].text}
                                            />

                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name={"occupations_" + index}
                                                type="radio"
                                                id={question.yes}
                                                onChange={(e) => onCheck(index, index, "Yes")}
                                                checked={answers["occupations_" + index] && "Yes" === answers["occupations_" + index].text}
                                            />

                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>

                            )}
                        </Accordion>
                    </Col>
                </Row>
                :
                <></>
            }
        </Container>
    );

}

export const CurrentOccupation2 = (props) => {

    const { session, setSession } = useContext(SessionContext);
    const [category, setCategory] = useState(false);
    const [clicked, setClicked] = useState([]);
    const [helpVisible, setHelpVisible] = useState(false);
    const [viewMode, setViewMode] = useState("accordion");

    let navigate = useNavigate();
    let location = useLocation();

    const onAccordionClick = (key) => {
        setCategory(key);
        let newClicked = [...clicked];
        if (!newClicked.includes(key)) {
            newClicked.push(key);
        }
        setClicked(newClicked);
        if (5 === newClicked.length) {
            setHelpVisible(false);
        }
    }

    const onSave = (e) => {
        //todo refactor

        let newSessionEntry = getNextStep(location, { ...session });
        let answer;

        if (undefined === e) {
            //ok
            console.info("Moving forward");
            answer = {
                id: "6646",
                text: "No",
                path: "application"
            };
        } else {
            // bad 
            console.info("Not moving forward");
            answer = {
                id: "6645",
                text: "Yes",
                path: "mismatch",
                details: e
            };
            if (undefined === session.disqualified) {
                newSessionEntry.disqualified = {};
            } else {
                newSessionEntry.disqualified = { ...session.disqualified };
            }
            if (undefined === newSessionEntry.disqualified.questions) {
                newSessionEntry.disqualified.questions = [];
            }
            newSessionEntry.disqualified.questions.push("4299");
        }

        newSessionEntry["question_4299"] = answer;
        console.info("Saving session?", newSessionEntry);
        setSession(newSessionEntry);
        navigate(newSessionEntry.next);


    }

    const validate = () => {

        let isValidated = true;

        for (let i = 0; i < 5; i++) {
            if (!clicked.includes(i.toString())) {

                setCategory(i.toString());
                let newClicked = [...clicked];
                newClicked.push(i.toString());
                setClicked(newClicked);
                if (5 !== newClicked.length) {
                    setHelpVisible(true);
                    isValidated = false;
                } else {
                    setHelpVisible(false);
                }
                break;
            }
        }
        if (isValidated) {
            // onSave()

        }
    }

    return (
        <Container fluid>

            {"details" === viewMode ?
                <>
                    <DetailsForm onCancel={(e) => setViewMode("accordion")} onSave={onSave} />
                </>
                :
                <>
                    <Row className='mt-4'>
                        <Col>
                            <h5>Does your occupation belong to any of the following?</h5></Col>
                    </Row>
                    {helpVisible &&
                        <Row className='mt-4'>
                            <Col>
                                <Form.Text className="text-muted">
                                    Please review every category.
                                </Form.Text>
                            </Col>
                        </Row>
                    }
                    <Row className='mt-4'>
                        <Col>
                            <Accordion activeKey={false === category ? null : category}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header onClick={(e) => onAccordionClick('0')}>Fishing, diving or off-shore work &nbsp; <small className='text-muted'>(8)</small></Accordion.Header>
                                    <Accordion.Body>
                                        Fisherman (not coming home nightly), professional diving (cableman, deep Sea, demolition, oil rig, pipe laying, saturation),
                                        off shore workers (Oil and Gas).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header onClick={(e) => onAccordionClick('1')}>Building and construction &nbsp;  <small className='text-muted'>(2)</small></Accordion.Header>
                                    <Accordion.Body>
                                        Asbestos Worker, structural iron or steel workers (on towers, bridges, buildings over 3 stories).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header onClick={(e) => onAccordionClick('2')}>Forestry &nbsp;  <small className='text-muted'>(12)</small></Accordion.Header>
                                    <Accordion.Body>
                                        Chipper, choke setter, chopper, faller, bucker, marker, sorter, logger, lumberjack, raftsman, riverman, woodsman.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header onClick={(e) => onAccordionClick('3')}>Entertainment, media and sports &nbsp; <small className='text-muted'>(6)</small></Accordion.Header>
                                    <Accordion.Body>
                                        Professional athlete, carnival, acrobat, animal trainer, rodeo performer/competitor, journalist or photographic journalist (international assignments)
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header onClick={(e) => onAccordionClick('4')}>Other occupations &nbsp;  <small className='text-muted'>(4)</small></Accordion.Header>
                                    <Accordion.Body>
                                        Military currently deployed or under orders to deploy, commercial aviation (other than pilot for major airline), professional gambler, missionary (international assignments).
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        {5 === clicked.length ?
                            <Col>
                                <Button onClick={() => onSave()} variant="primary" size="lg">None of the above</Button>{' '}
                                <Button onClick={() => setViewMode("details")} variant="outline-primary" size="lg"><u>Yes</u></Button>
                            </Col>
                            :
                            <Col>
                                <Button onClick={(e) => validate(e)} variant="outline-primary" size="lg">Review</Button>
                            </Col>
                        }

                    </Row>

                </>
            }

        </Container>
    )

}

