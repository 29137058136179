import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { asCurrency } from '../libs/utils.js';
import {
    DollarControl,
    NumberControl,
} from '../screen/controls';

export const NeedsAnalysis = ({ setViewMode, onParentChange }) => {


    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const [suggestion, setSuggestion] = useState(false);
    const { branding } = useContext(BrandingContext);


    const [values, setValues] = useState({
        debt: 0,
        existing: 0,
        perAnnum: 0,
        years: 0
    });


    const [highlighted, setHighlighted] = useState({
        debt: false,
        existing: false,
        perAnnum: false,
        years: false
    });

    const [coverage, setCoverage] = useState(0);


    useEffect(() => {

        console.info("INSURANCE", insuranceData);

    }, [insuranceData]);


    useEffect(() => {

        if (coverage > 0) {

            if (insuranceData.plans.term10.minface > coverage) {
                onParentChange("facevalue", insuranceData.plans.term10.minface.toString());

            } else {

                if (insuranceData.plans.term10.maxface <= coverage) {
                    onParentChange("facevalue", insuranceData.plans.term10.maxface.toString());

                } else {
                    onParentChange("facevalue", coverage.toString());

                }

            }

        }

    }, [coverage]);


    useEffect(() => {

        if (undefined !== values.existing && undefined !== values.debt && undefined !== values.perAnnum && undefined !== values.years) {
            let newValues = { ...values };
            for (let [k, v] of Object.entries(newValues)) {
                if ("" === newValues[k] || isNaN(parseInt(v.toString())) || v <= 0) {
                    newValues[k] = 0;
                } else {
                    newValues[k] = parseInt(v.toString());
                }
            }
            let years = 1;
            if (newValues.years > 0) {
                years = newValues.years;
            }
            let newCoverage = newValues.debt + (newValues.perAnnum * years) - newValues.existing;
            if (newCoverage < 1) {
                newCoverage = 0;
            }
            setCoverage(newCoverage);

            if (newCoverage > 0) {

                if (insuranceData.plans.term10.minface > coverage) {
                    onParentChange("facevalue", insuranceData.plans.term10.minface.toString());

                } else {

                    if (insuranceData.plans.term10.maxface <= coverage) {
                        onParentChange("facevalue", insuranceData.plans.term10.maxface.toString());

                    } else {
                        onParentChange("facevalue", coverage.toString());

                    }

                }

            }

        }

    }, [values]);




    let navigate = useNavigate();
    let location = useLocation();

    const onChange = (controlId, amount) => {
        const newValues = { ...values };
        newValues[controlId] = amount.toString();
        setValues(newValues);
    }

    const moveForward = (updateCoverage) => {
        if (updateCoverage) {
            onChange('facevalue', coverage);
        }
        setViewMode("slider");

    }



    return (

        <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>

            {"mobile" === layout.device ?
                <>
                    <hr />
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <h5>How much life insurance do you need?</h5>
                        </Col>
                    </Row>

                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <Container fluid>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <NumberControl highlighted={highlighted.years} controlId="years" caption="Income replacement (number of years)" helpText="Number of years for the replacement income." onChange={onChange} choices={{ minValue: 0, maxValue: 100, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <DollarControl highlighted={highlighted.perAnnum} controlId="perAnnum" caption="Income replacement (amount per year)" helpText="Income that your beneficiaries would require." onChange={onChange} choices={{ minValue: 0, maxValue: 2000000, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <DollarControl highlighted={highlighted.existing} controlId="existing" caption="Current coverage" helpText="Current life insurance coverage (personal or group)." onChange={onChange} choices={{ minValue: 0, maxValue: 10000000, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row className='px-0 py-0 ml-0 mr-0'>
                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                                        <DollarControl highlighted={highlighted.debt} controlId="debt" caption="Debt" helpText="Final expenses, mortage balance, outstanding loans." onChange={onChange} choices={{ minValue: 0, maxValue: 200000, slider: "hide" }} />
                                    </Col>
                                </Row>


                                <Row className='px-0 py-0 ml-0 mr-0'>

                                    <Col className='col-12 px-0 py-0 ml-0 mr-0'>


                                        <div className="mb-4">{' '}</div>

                                        {coverage > 0 ?
                                            <>
                                                {coverage < insuranceData.plans.term10.minface ?
                                                    <Alert id="analysis-alert" variant="warning">
                                                        Your insurance needs ({asCurrency(coverage)}) are covered by the minimum policy value for this product.
                                                    </Alert>
                                                    :
                                                    <>
                                                        {coverage <= insuranceData.plans.term10.maxface ?
                                                            <Alert id="analysis-alert" variant="success">
                                                                Suggested coverage: {asCurrency(coverage)}
                                                            </Alert>
                                                            :
                                                            <>

                                                                <Card className="bg-light">
                                                                    <Card.Header>
                                                                        Important note
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Title>About your insurance needs</Card.Title>
                                                                        <Card.Text>
                                                                            <p>
                                                                                Your insurance needs ({asCurrency(coverage)}) exceed the maximum policy value for this product.
                                                                            </p>
                                                                            <p>
                                                                                Please contact us if you want to explore additional products that could provide more coverage: {layout.phone} or {layout.email}
                                                                            </p>
                                                                            <p>
                                                                                Alternately, you can also proceed with the maximum policy value available with this product, which is <u className='clicker' onClick={() => moveForward(true)}>{asCurrency(insuranceData.plans.term10.maxface)}</u>
                                                                            </p>
                                                                        </Card.Text>

                                                                    </Card.Body>
                                                                </Card>
                                                            </>

                                                        }
                                                    </>
                                                }
                                            </> :
                                            <>

                                            </>
                                        }

                                        <div>

                                            <div className="mt-4">
                                                {coverage > 0 ?
                                                    <Button onClick={() => moveForward(true)} variant="primary"><BsCheckCircle /> Continue</Button>
                                                    :
                                                    <Button onClick={() => moveForward(false)} variant="outline-primary">Discard</Button>
                                                }
                                                {/* <Button onClick={() => giveUp()} variant="outline-primary">Discard</Button>{' '} */}
                                            </div>

                                        </div>

                                    </Col>
                                </Row>
                            </Container>

                        </Col>


                    </Row>
                </>
                :
                <>
                    <Row className='mb-4'>
                        <Col>
                            <h5>How much life insurance do you need?</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='col-12'>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <NumberControl highlighted={highlighted.years} controlId="years" caption="Income replacement (number of years)" helpText="Number of years for the replacement income." onChange={onChange} choices={{ minValue: 0, maxValue: 100, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DollarControl highlighted={highlighted.perAnnum} controlId="perAnnum" caption="Income replacement (amount per year)" helpText="Income that your beneficiaries would require." onChange={onChange} choices={{ minValue: 0, maxValue: 2000000, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DollarControl highlighted={highlighted.existing} controlId="existing" caption="Current coverage" helpText="Current life insurance coverage (personal or group)." onChange={onChange} choices={{ minValue: 0, maxValue: 10000000, slider: "hide" }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DollarControl highlighted={highlighted.debt} controlId="debt" caption="Debt" helpText="Final expenses, mortage balance, outstanding loans." onChange={onChange} choices={{ minValue: 0, maxValue: 200000, slider: "hide" }} />
                                    </Col>
                                </Row>


                                <Row className='mt-4'>

                                    <Col>


                                        <div className="mb-4">{' '}</div>

                                        {coverage > 0 ?
                                            <>
                                                {coverage < insuranceData.plans.term10.minface ?
                                                    <Alert id="analysis-alert" variant="warning">
                                                        Your insurance needs ({asCurrency(coverage)}) are covered by the minimum policy value for this product.
                                                    </Alert>
                                                    :
                                                    <>
                                                        {coverage <= insuranceData.plans.term10.maxface ?
                                                            <Alert id="analysis-alert" variant="success">
                                                                Suggested coverage: {asCurrency(coverage)}
                                                            </Alert>
                                                            :
                                                            <>

                                                                <Card className="bg-light">
                                                                    <Card.Header>
                                                                        Important note
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Title>About your insurance needs</Card.Title>
                                                                        <Card.Text>
                                                                            <p>
                                                                                Your insurance needs ({asCurrency(coverage)}) exceed the maximum policy value for this product.
                                                                            </p>
                                                                            <p>
                                                                                Please contact us if you want to explore additional products that could provide more coverage: {layout.phone} or {layout.email}
                                                                            </p>
                                                                            <p>
                                                                                Alternately, you can also proceed with the maximum policy value available with this product, which is <u className='clicker' onClick={() => moveForward(true)}>{asCurrency(insuranceData.plans.term10.maxface)}</u>
                                                                            </p>
                                                                        </Card.Text>

                                                                    </Card.Body>
                                                                </Card>
                                                            </>

                                                        }
                                                    </>
                                                }
                                            </> :
                                            <>

                                            </>
                                        }

                                        <div>

                                            <div className="mt-4">
                                                {coverage > 0 ?
                                                    <Button onClick={() => moveForward(true)} variant="primary"><BsCheckCircle /> Continue</Button>
                                                    :
                                                    <Button onClick={() => moveForward(false)} variant="outline-primary">Discard</Button>
                                                }
                                                {/* <Button onClick={() => giveUp()} variant="outline-primary">Discard</Button>{' '} */}
                                            </div>

                                        </div>

                                    </Col>
                                </Row>
                            </Container>

                        </Col>


                    </Row>
                </>
            }



        </Container >


    )
}


