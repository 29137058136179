/**
 * Our intent with this file is to group functions that interact with the cloud API (in API Gateway).
 * We do not use hooks extensively for API interactions in QuickApply because our main access pattern is asynchronous writes,
 * the UI sends updates to the cloud but does not depend on the return values to continue.
 */

import {
    cloudQuery,
    cloudSave,
} from 'linkclient';

import { gateway } from '../config.js';
import { getInitialQuote } from './insurance.js';
import { getTokenFromStorage } from './utils.js';

export const downloadInsuranceData = async (next) => {

    const options = {
        uri: "data"
    };

    let json = await sendApiRequest(options);

    for (let [k, v] of Object.entries(json)) {
        sessionStorage.setItem(k, JSON.stringify(v));
    }

    if (next) {
        next(json);
    }

    return json;
}


const getApiEndpoint = () => {
    let apiEndpoint = gateway;
    return apiEndpoint;
}

export const getBranding = async (brand, next) => {

    const options = {
        uri: "branding/" + brand
    };

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}

export const getDownloadLink = async (next) => {

    const options = {
        uri: "approve/download"
    };

    setAuthorization(options);

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;

}


export const getGeo = async (next) => {

    const options = {
        uri: "geo"
    }

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json

}


export const getNotifications = async (next) => {

    const options = {
        uri: "notifications"
    };

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}


export const getSession = async (next) => {


    let quote = getInitialQuote();

    const options = {
        uri: "sessions",
        payload: quote
    };

    let json = await sendApiRequest(options);
    sessionStorage.setItem("session", JSON.stringify(json));

    if (next) {

        next(json);
    }

    return json;
}




export const getSignatureToken = async (next) => {

    const options = {
        uri: "signatures"
    }

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;

}


export const prepareDocuments = async (quote, next) => {

    const options = {
        uri: "signatures",
        payload: quote
    };

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}



export const requestApproveApplication = async (next) => {

    const options = {
        uri: "approve/application"
    }

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;

}

export const requestSignatureAudit = async (next) => {

    const options = {
        uri: "signatures/audit"
    }

    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;

}



export const saveQuote = async (quote, next) => {

    const options = {
        uri: "quotes",
        payload: quote
    };
    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}

export const sendEmailVerification = async (quote, next) => {

    const options = {
        uri: "email",
        payload: quote
    };
    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}

export const sendEmailLinkRequest = async (quote, next) => {

    const options = {
        uri: "approve/email"
    };
    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}

export const sendLead = async (lead, next) => {

    const options = {
        uri: "leads",
        payload: lead
    };
    let json = await sendApiRequest(options);

    if (next) {
        next(json);
    }

    return json;
}

const sendApiRequest = async (options) => {
    options.api = getApiEndpoint();
    setAuthorization(options);

    let json;
    if (options.payload) {
        json = await cloudSave(options);
    } else {
        json = await cloudQuery(options);
    }
    if (json && json.data) {
        return json.data;
    } else {
        return null;
    }
}


const setAuthorization = (options) => {

    let sessionInfo = getTokenFromStorage();

    options.token = sessionInfo.token;
}

export { getApiEndpoint };
