/**
 * Our intent with this file is to group functions that relate to routing and navigation.
 * The main router component is also here so we can more easily manage it.
 */

import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import { Address } from '../buy/address';
import { Beneficiary } from '../buy/beneficiary';
import {
    Last10years,
    Last5years,
    MiscQuestions,
} from '../buy/carousels';
import { Consent } from '../buy/consent';
import { MedicalInfo } from '../buy/diagnosis';
import { Introduction } from '../buy/introduction';
import { Lifestyle } from '../buy/lifestyle';
import { CurrentOccupation } from '../buy/occupation';
import { Payment } from '../buy/payment';
import { Policy } from '../buy/policy';
import {
    AbormalResults,
    Last2years,
} from '../buy/triggers';
import { Congratulations } from '../endings/happy';
import { InvalidPage } from '../endings/invalid';
import {
    Mismatch,
    Unqualified,
} from '../endings/unhappy';
import { Home } from '../screen/home';
import { Page } from '../screen/page';
import { NeedsAnalysis } from '../shop/analysis';
import { Faq } from '../shop/faq';
import { Privacy } from '../shop/privacy';
import { Quoter } from '../shop/quoter';

/**
 * Determine what is the next screen. 
 * The idea is to avoid routing manually as much as possible in the various components.
 * @param location 
 * @param session 
 * @returns Partial session object with updated location for the next screen
 */
export const getNextStep = (location, session) => {

    let now = Date.now();
    let flow = { ...session };
    let uri;


    if (location.pathname === "/") {
        // this is the entry point before branding gets loaded
        uri = 'landing';
    } else {
        if (session.next === undefined) {
            // we probably landed on a long url directly            
            uri = 'landing';
        } else {
            // we are engaged in the workflow, this is our most common scenario
            uri = location.pathname.split('/').pop();
        }
    }

    let happyPath = session.happyPath;
    console.info("Happy path", session.happyPath);

    if (undefined === happyPath) {

        happyPath = { 'landing': 'quoter' };
    }



    // this map describes the unhappy path, i.e. the unfortunate endings
    let unhappyPath = {
        'queue': "opportunities",  // conclusion: good lead but would need a different Wawanesa Life product
        'stop': "thankyou"  // conclusion: Wawanesa Life will not insure no matter what (ex: under 18, not Canadian citizen, etc.)
    };

    // here we figure out whether we can keep going on the happy path or not based on the session state
    if (flow.outcome
        && ["queue", "stop", "mismatch", "unqualified"].includes(flow.outcome) // this means the conclusion is not a sale
        && ["beneficiary", "consent"].includes(happyPath[uri]) // these are the only screens where people can eject (based on business requirements)
    ) {
        flow.next = unhappyPath[flow.outcome];
    } else {

        let canContinue = true;

        if (flow.disqualified && ["beneficiary", "consent"].includes(happyPath[uri])) {

            if (flow.disqualified.questions && flow.disqualified.questions.length > 0) {
                canContinue = false;
            }

            if (flow.disqualified.occupation && flow.disqualified.occupation.length > 0) {
                canContinue = false;
            }

            if (canContinue) {
                for (let [category, value] of Object.entries(flow.disqualified)) {
                    if ("questions" !== category && "occupation" !== category) {
                        canContinue = false;
                    }
                }
            }

        }

        if (canContinue) {
            console.info("Can continue", uri);
            flow.next = happyPath[uri];
        } else {
            console.info("Cannot continue", flow);
            flow.outcome = "queue";
            flow.next = unhappyPath["queue"];
        }

    }

    // we update the milestone values    
    // those are useful in the Control Room and in reports
    let ending = uri + '_end';
    let latest = flow.next;
    let starting = latest + '_start';

    flow[ending] = now;
    flow[starting] = now;
    flow.latest_step = latest;

    // this is where we establish the next route
    flow.next = "/" + flow.next;

    if (location.search) {
        // we have a query string, probably the utm codes
        flow.next += location.search;
    }

    return flow;

}

/**
 * React component that contains the routing rules
 * @returns 
 */
export const QuickApplyRoutes = ({ ready, initialized }) => {

    return (

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Page />}>
                    <Route index element={<Home ready={ready} initialized={initialized} />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="quoter" element={<Quoter />} />
                    <Route path="faq" element={<Faq />} />
                    <Route path="address" element={<Address />} />
                    <Route path="analysis" element={<NeedsAnalysis />} />
                    <Route path="lifestyle" element={<Lifestyle />} />
                    <Route path="introduction" element={<Introduction />} />
                    <Route path="thankyou" element={<Unqualified />} />
                    <Route path="opportunities" element={<Mismatch />} />
                    <Route path="congratulations" element={<Congratulations />} />
                    <Route path="occupation" element={<CurrentOccupation />} />
                    <Route path="last10" element={<Last10years />} />
                    <Route path="last5" element={<Last5years />} />
                    <Route path="last2" element={<Last2years />} />
                    <Route path="misc" element={<MiscQuestions />} />
                    <Route path="medical" element={<AbormalResults />} />
                    <Route path="beneficiary" element={<Beneficiary />} />
                    <Route path="policy" element={<Policy />} />
                    <Route path="diagnosis" element={<MedicalInfo />} />
                    <Route path="payment" element={<Payment />} />
                    <Route path="consent" element={<Consent />} />
                    <Route path="*" element={<InvalidPage />} />
                </Route>
            </Routes>
        </BrowserRouter>

    );

}
/*


*/