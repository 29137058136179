import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    BsChevronRight,
    BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import {
    ageNearest,
    prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
    asCurrency,
    isEmail,
    isPhone,
} from '../libs/utils';
import {
    CalendarControl,
    DollarControl,
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { Faq } from './faq';

export const CoveragePicker = () => {

    // todo pull from branding
    const [amounts, setAmounts] = useState([500000, 250000, 50000]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    {amounts.map((amount) =>
                        <Button variant="outline-primary">{asCurrency(amount)}</Button>
                    )}
                </Col>
            </Row>
        </Container>
    );


}

export const CoverageSlider = () => {

    const { branding } = useContext(BrandingContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [productSpecs, setProductSpecs] = useState({ minFace: 0, maxFace: 0, label: "" });
    const [ready, setReady] = useState(false);
    const [forceValue, setForceValue] = useState(undefined);

    useEffect(() => {

        if (undefined !== insuranceData.plans && undefined !== insuranceData.plans.term10) {
            let newSpecs = { ...productSpecs };
            newSpecs.minFace = parseInt(insuranceData.plans.term10.minface);
            newSpecs.maxFace = parseInt(insuranceData.plans.term10.maxface);
            newSpecs.label = "From " + asCurrency(newSpecs.minFace) + " to " + asCurrency(newSpecs.maxFace);
            setProductSpecs(newSpecs);
            setReady(true);
        }

    }, [insuranceData.plans]);


    useEffect(() => {

        if (session.analysis_coverage && session.facevalue && session.analysis_coverage !== session.facevalue) {
            setForceValue(session.analysis_coverage);
        }

    }, [session.facevalue, session.analysis_coverage]);


    const getDefaultFacevalue = () => {

        let value;
        if (session.facevalue) {
            value = session.facevalue;
        } else {
            if (branding.default_facevalue) {
                value = branding.default_facevalue;
            } else {
                value = 213000; //a little under halfway
            }

        }

        return value;

    }

    const onChange = (k, v) => {
        if ("facevalue" === k) {
            if (parseInt(v)) {
                let newValues = {};
                newValues[k] = v;
                setSession({ ...newValues });
            }
        }
    }

    const onHighlight = (e) => {
        //
    }

    return (
        <Container fluid>

            <Row>
                <Col>

                    {ready &&
                        <DollarControl highlighted={true} controlId="facevalue" forceValue={forceValue} caption="Desired policy value" helpText={productSpecs.label} onChange={onChange} onHighlight={(e) => onHighlight(e)} choices={{ hideHelpButton: true, defaultValue: getDefaultFacevalue(), minValue: productSpecs.minFace, maxValue: productSpecs.maxFace }} />
                    }

                </Col>


            </Row>


        </Container>
    )

}


