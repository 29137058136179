import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import { Form } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import { CgCloseR } from 'react-icons/cg';
import { useLocation } from 'react-router-dom';

import {
    InsuranceDataContext,
    PhysicalContext,
    SessionContext,
} from '../app/context';
import {
    toCm,
    toFeet,
    toKg,
    toLbs,
} from '../libs/utils';
import {
    ImperialHeight,
    ImperialWeight,
    MetricHeight,
    MetricWeight,
} from '../screen/conversions';

export const Physical = ({ setCompleted, highlighted }) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);

    const [physical, setPhysical] = useState({});

    const [colorMode, setColorMode] = useState(highlighted ? "highlight" : "standard");
    const [helpVisible, setHelpVisible] = useState(highlighted);

    let location = useLocation();

    useEffect(() => {

        let updated = false;
        if (undefined !== session.physical) {
            if (session.physical.feet) {
                setPhysical({ ...session.physical });
                updated = true;
            }
        }

        if (!updated) {
            let newPhysical = {
                feet: 0,
                inches: 0,
                cm: 0,
                units: {
                    height: "imperial",
                    weight: "imperial"
                },
                height: "0'0",
                lbs: 0,
                kg: 0,
                weight: "0 lbs"
            }
            setPhysical(newPhysical);
        }

    }, []);

    useEffect(() => {

        let isReady = false;
        if (physical.height && physical.weight) {
            let height = physical.height.replace("'", " ").split("")[0];
            if (parseInt(height)) {
                let weight = physical.weight.split("")[0];
                if (parseInt(weight)) {
                    isReady = true;
                }
            }
        }
        if (isReady) {
            verifyPhysical();
        }
        setCompleted(isReady);

    }, [physical.height, physical.weight]);


    useEffect(() => {
        if (highlighted) {
            setHelpVisible(true);
            setColorMode("highlight");
        } else {
            setColorMode("standard");
            setHelpVisible(false);
        }
    }, [highlighted]);

    useEffect(() => {
        if (helpVisible) {
            let helpItems;
            if (session.help_clicked) {
                helpItems = session.help_clicked;
            } else {
                helpItems = [];
            }
            helpItems.push({ physical: Date.now() });
            setSession({ help_clicked: helpItems });
        }
    }, [helpVisible]);


    const onHelpToggle = () => {
        setHelpVisible(!helpVisible);
    }

    const getClassName = () => {
        if ("standard" === colorMode) {
            return "mb-3";
        } else {
            return "mb-3 text-danger";
        }
    }

    const verifyPhysical = () => {

        let qualify = false;
        let weightData = [...insuranceData.weights];
        if (physical.height) {

            let heightOk = false;
            let weightOk = false;

            let weightBracket;

            if (physical.height.includes(" cm")) {
                //metric
                let cm = parseInt(physical.height.split(" ")[0]);
                for (let i = 0; i < weightData.length; i++) {
                    if (cm === weightData[i].cm) {
                        heightOk = true;
                        weightBracket = weightData[i];
                    } else {
                        if (cm > weightData[i].cm) {
                            if (i < weightData.length - 1) {
                                if (cm < weightData[i + 1].cm) {
                                    heightOk = true;
                                    weightBracket = weightData[i + 1];
                                }
                            }
                        }
                    }
                }
            } else {
                for (let item of weightData) {
                    if (item.ft === physical.height) {
                        heightOk = true;
                        weightBracket = item;
                    }
                }
            }

            if (heightOk) {

                let value = parseInt(physical.weight.split(" ")[0]);
                let unit = physical.weight.split(" ")[1];

                if (!isNaN(value) && weightBracket) {

                    if ("lbs" === unit) {
                        if (value >= weightBracket.lb_low && value <= weightBracket.lb_high) {
                            weightOk = true;
                        }
                    } else {
                        if (value >= weightBracket.kg_low && value <= weightBracket.kg_high) {
                            weightOk = true;
                        }
                    }

                }

            }

            let newSession = { ...session };
            newSession.weight = physical.weight;
            newSession.height = physical.height;
            newSession.physical = { ...physical };

            if (weightOk && heightOk) {
                newSession.physical_status = "pass";
                newSession.outcome = "continue";

                if ("imperial" === newSession.physical.units.height) {
                    newSession.physical.cm = toCm(newSession.physical);
                } else {
                    let imperial = toFeet({ cm: newSession.physical.cm });
                    newSession.physical.feet = imperial.feet;
                    newSession.physical.inches = imperial.inches;
                }

                if ("imperial" === newSession.physical.units.weight) {
                    newSession.physical.kg = toKg({ lbs: newSession.physical.lbs });
                } else {
                    newSession.physical.lbs = toLbs({ kg: newSession.physical.kg });
                }

                if (undefined !== newSession.disqualified) {
                    delete newSession.disqualified.physical;
                }

            } else {
                newSession.physical_status = "fail";
                newSession.outcome = "stop";
                if (undefined === newSession.disqualified) {
                    newSession.disqualified = {};
                }
                newSession.disqualified.physical = "yes";

            }

            //let flow = getNextStep(location, newSession);
            //let newSessionEntry = { ...flow };
            setSession(newSession);

        }

    }

    return (


        <PhysicalContext.Provider value={{ physical, setPhysical }}>


            <Form>

                <Form.Group className={getClassName()} controlId="height">
                    <Form.Label>How tall are you?</Form.Label>
                    {helpVisible ?
                        <CgCloseR className="float-end clicker text-muted" onClick={onHelpToggle} />
                        :
                        <BsInfoCircle className="float-end clicker" onClick={onHelpToggle} />
                    }

                    {physical.units && "imperial" === physical.units.height ?
                        <ImperialHeight />
                        :
                        <MetricHeight />
                    }

                    {helpVisible &&
                        <Form.Text className="text-muted">
                            Use the button to switch between metric and imperial units
                        </Form.Text>
                    }
                </Form.Group>


                <Form.Group className={getClassName()} controlId="weight">
                    <Form.Label>How much do you weigh?</Form.Label>
                    {helpVisible ?
                        <CgCloseR className="float-end clicker text-muted" onClick={onHelpToggle} />
                        :
                        <BsInfoCircle className="float-end clicker" onClick={onHelpToggle} />
                    }

                    {physical.units && "imperial" === physical.units.weight ?
                        <ImperialWeight />
                        :
                        <MetricWeight />
                    }

                    {helpVisible &&
                        <Form.Text className="text-muted">
                            Use the button to switch between metric and imperial units
                        </Form.Text>
                    }
                </Form.Group>


            </Form>
        </PhysicalContext.Provider>

    )

}

