import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import {
  BsChevronRight,
  BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  BrandingContext,
  InsuranceDataContext,
  LayoutContext,
  SessionContext,
} from '../app/context';
import { sendLead } from '../libs/cloud';
import {
  ageNearest,
  prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
  asCurrency,
  isEmail,
  isPhone,
} from '../libs/utils';
import {
  CalendarControl,
  DollarControl,
  EmailControl,
  PhoneNumberControl,
  RadioControl,
  TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { ConditionalQuoter } from './conditonal-quoter';
import { Faq } from './faq';

export const DefaultQuoter = () => {

  const { session } = useContext(SessionContext);

  return (
    <>
      <QuoterArea />
    </>
  )
}

export const QuoterArea = () => {

  const { session, setSession } = useContext(SessionContext);
  const { insuranceData } = useContext(InsuranceDataContext);
  const { branding } = useContext(BrandingContext);
  const { layout, setLayout } = useContext(LayoutContext);
  const [productSpecs, setProductSpecs] = useState({ minAge: 0, maxAge: 0, minFace: 0, maxFace: 0 });
  const [hasPlans, setHasPlans] = useState(false);
  const [isAgeQualified, setIsAgeQualified] = useState(false);




  useEffect(() => {

    if (undefined === session.facevalue) {
      let newSessionEntry = {
        facevalue: 213000
      }
      setSession(newSessionEntry);
    }

  }, [session.facevalue]);


  useEffect(() => {

    if (undefined !== session.privacy && ["Yes", "yes"].includes(session.privacy)) {
      if (undefined !== session.has_contact && "yes" === session.has_contact) {

        if (undefined === session.lead_notification) {
          setSession({ lead_notification: Date.now() });
          sendLead(session, (data) => {
            console.info("Send lead notification");
          })
        }
      }
    }

  }, [session.has_contact, session.privacy, session.lead_notification]);

  //setSession({has_essentials: "yes"});

  useEffect(() => {

    if (undefined !== insuranceData.plans && undefined !== insuranceData.plans.term10) {
      let newSpecs = { ...productSpecs };
      newSpecs.minAge = parseInt(insuranceData.plans.term10.minage);
      newSpecs.maxAge = parseInt(insuranceData.plans.term10.maxage);
      newSpecs.minFace = parseInt(insuranceData.plans.term10.minface);
      newSpecs.maxFace = parseInt(insuranceData.plans.term10.maxface);
      setProductSpecs(newSpecs);
      setHasPlans(true);
    }

  }, [insuranceData.plans]);



  useEffect(() => {

    if (isAgeQualified) {
      if (session.disqualified) {
        if (session.disqualified.age) {
          let newSessionEntry = {
            disqualified: session.disqualified
          }
          delete newSessionEntry.disqualified.age;
          setSession(newSessionEntry);
        }
      }
    } else {
      if (session.facevalue) {
        if (session.disqualified) {

          let newSessionEntry = {
            disqualified: session.disqualified
          }
          newSessionEntry.disqualified.age = "yes";
          setSession(newSessionEntry);

        }
      }
    }

  }, [session.facevalue, session.disqualified, isAgeQualified]);

  useEffect(() => {

    if (hasPlans) {
      console.info("Got facevalue trigger?");
      let newOffers = false;
      let facevalue = session.facevalue;
      //if (session.analysis_coverage) {
      //  facevalue = session.analysis_coverage;
      //}

      if (undefined !== session.dob && 10 === session.dob.length) {
        if (undefined !== session.gender && ["F", "M"].includes(session.gender)) {
          if (undefined !== session.facevalue && !isNaN(parseInt(facevalue.toString()))) {

            if (undefined !== session.smoker && ["Yes", "No"].includes(session.smoker)) {

              setSession({ has_essentials: "yes" });

              let age = ageNearest(session.dob);

              if (age && insuranceData.plans) {
                let inRange = (age >= productSpecs.minAge && age <= productSpecs.maxAge);

                let newSessionEntry = {
                  premium: null
                }
                if (inRange) {
                  setIsAgeQualified(true);
                  let newOffers = prepareOffers(insuranceData, session.dob, session.gender, facevalue);

                  if (undefined === newOffers) {
                    newSessionEntry.offers = {};
                  } else {
                    newSessionEntry.offers = { ...newOffers };
                  }
                  console.info("Setting new offers", newSessionEntry);
                  setSession(newSessionEntry);

                } else {
                  //.info("Age not in range", age, productSpecs);                  
                  setIsAgeQualified(false);
                  newSessionEntry.offers = {};

                }
                setSession(newSessionEntry);
              }
            }
          }
        }
      }
    }


  }, [hasPlans, session.dob, session.gender, session.facevalue, session.smoking, session.smoker, session.analysis_coverage]);




  return (

    <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0' : 'mb-4'}>

      <>
        <Row className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0' : 'mb-4'}>
          <Col>
            <h1><small><FaShippingFast /></small> Get your <span className='text-primary'>instant</span> life insurance quote</h1>
          </Col>
        </Row>
      </>


      <Row>

        <Col><ConditionalQuoter /></Col>

      </Row>

    </Container>


  )

}