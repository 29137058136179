import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Col,
    Container,
    Image,
    Row,
} from 'react-bootstrap';
import { BsCloudDownload } from 'react-icons/bs';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { assets } from '../config.js';
import {
    getDownloadLink,
    requestApproveApplication,
    sendEmailLinkRequest,
} from '../libs/cloud.js';
import { EmailValidationBox } from './email';

export const Policy = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    let navigate = useNavigate();
    //let { search } = useLocation();

    const { session, setSession } = useContext(SessionContext);
    //const { branding } = useContext(BrandingContext);
    const [timer, setTimer] = useState(false);
    const [approved, setApproved] = useState(false);
    const [waiting, setWaiting] = useState(false);
    //const [download, setDownload] = useState(false);
    const [verified, setVerified] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);
    const [failed, setFailed] = useState(false);
    const [emailOk, setEmailOk] = useState(false);
    const [longRunning, setLongRunning] = useState(false);


    useEffect(() => {
        if (session.approved) {
            console.info("Session approval changed", session, Date.now());
            if ("yes" === session.approved) {
                //
            } else {

                /* DEBUG
                let newSessionEntry = {
                    "outcome": "stop",
                    "next": "thankyou"
                };

                if (undefined !== session.disqualified) {
                    newSessionEntry.disqualified = session.disqualified;
                } else {
                    newSessionEntry.disqualified = {};
                }

                newSessionEntry.disqualified.mib = "yes";
                setSession(newSessionEntry);
                */
                let newVerified = 'fail';
                setVerified(newVerified);
                clearInterval(timer);
                setTimer(false);
                //navigate(`/thankyou`);
            }

        }
    }, [session.approved]);



    useEffect(() => {
        if (waiting) {

            if (!session.applied) {
                let newSessionEntry = { applied: "yes" };
                setSession(newSessionEntry);
            }
        }
    }, [waiting]);

    useEffect(() => {


        if (session.approved) {

            if ("yes" === session.approved) {

                setApproved("yes");
                if (session.applied) {

                    if ("yes" === session.applied) {

                        if ("ready" === session.download) {

                            getDownloadLink((data) => {

                                let newVerified = 'pass';
                                setVerified(newVerified);
                                let newPdfLink = data.url;
                                setPdfLink(newPdfLink);
                                clearInterval(timer);
                                setTimer(false);
                            });
                        } else {
                            //
                        }
                    }
                } else {

                    // if (emailOk) {
                    //     requestApproveApplication((data) => {
                    //         setWaiting(Date.now());
                    //     });
                    // }
                }
            } else {
                setApproved("no");
            }


        }
    }, [session, emailOk]);

    useEffect(() => {
        // if ("intro" === layout.stage || "/policy" !== session.next) {
        //     navigate("/");
        // } else {
        let newLayout = { ...layout };
        newLayout.about = 100;
        newLayout.coverage = 100;
        newLayout.stage = "policy";
        newLayout.policy = 95;
        setLayout(newLayout);
        // }
        let newSession = {
            "final": "yes"
        }
        setSession(newSession);
    }, []);

    useEffect(() => {

        // setInterval(() => {
        //     if (waiting && !failed) {
        //         if (Date.now() - waiting > 30000) {
        //             console.info("Giving up");
        //             setFailed(true);
        //         }
        //     }
        // }, 5000);

        setInterval(() => {
            setLongRunning(true);
        }, 8000);


    }, []);

    return (
        <>
            <Container fluid>

                {emailOk ?
                    <>
                        {verified ?
                            <>
                                <Row>
                                    <Col>
                                        <h1 className="display-4 fw-bold lh-1 mb-3">Your insurance policy</h1>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    {'pass' === verified ?
                                        <Col className="col-lg-12 text-center text-lg-start">
                                            <div className="mt-1">
                                                {pdfLink ?
                                                    <DownloadOptions pdfLink={pdfLink} />
                                                    :

                                                    <p>Your application has been approved. We're preparing your documents...</p>


                                                }
                                            </div>
                                        </Col>
                                        :
                                        <Col className="col-lg-12 text-center text-lg-start">
                                            <div className="mt-1">
                                                <p>Generating the policy is taking longer than usual. We will email you as soon as the document is ready!</p>
                                                <p>Feel free contact us to discuss the situation: {layout.phone} or {layout.email}</p>
                                                <Image src={assets + "/" + branding.brand + "/wait.gif"} />
                                            </div>
                                        </Col>
                                    }

                                </Row>
                            </>
                            :
                            <>
                                {failed ?
                                    <Row className="mt-4">
                                        <Col className="col-lg-6 text-lg-start">
                                            <p>Generating the policy is taking longer than usual. We will email you as soon as the document is ready!</p>
                                            <p>Feel free contact us to discuss the situation: {layout.phone} or {layout.email}</p>
                                            <Image src={assets + "/" + branding.brand + "/wait.gif"} />
                                        </Col>
                                    </Row>
                                    :
                                    <>

                                        {longRunning ?
                                            <>
                                                <p>Generating the policy is taking longer than usual. We will email you as soon as the document is ready!</p>
                                                <p>Feel free contact us to discuss the situation: {layout.phone} or {layout.email}</p>
                                                <Image src={assets + "/" + branding.brand + "/wait.gif"} />
                                            </>
                                            :
                                            <>
                                                <Row className="mt-4">
                                                    <Col className="col-lg-6 text-lg-start">
                                                        <p>We are processing your application, please wait...</p>
                                                        <div className='dot-pulse'></div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col className="col-12 text-center">
                                                        <video style={{ width: "340px" }} autoPlay muted loop>
                                                            <source src="/videos/typing.mp4" type="video/mp4" />
                                                        </video>
                                                    </Col>
                                                </Row>
                                            </>
                                        }


                                    </>
                                }

                            </>
                        }
                    </>
                    :
                    <>
                        <Row>
                            <Col className='col-md-6 offset-md-3'>
                                <EmailValidationBox setEmailOk={setEmailOk} />
                            </Col>
                        </Row>
                    </>
                }

            </Container>

        </>
    )
}

const DownloadOptions = ({ pdfLink }) => {

    const { session } = useContext(SessionContext);
    const [requested, setRequested] = useState(false);

    const requestEmail = (e) => {
        setRequested(true);
        sendEmailLinkRequest((data) => {
            console.info(data);
        });
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    Your policy is ready!
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Button target="_blank" href={pdfLink} variant="outline-primary"><BsCloudDownload /> Download</Button>
                    {requested ?
                        <>
                            <hr />
                            <p>A copy of your insurance policy has been sent to {session.email}.</p>
                        </>
                        :
                        <>
                            {' '}
                            <Button onClick={requestEmail} variant="outline-primary"><MdOutlineAttachEmail /> Email</Button>
                        </>
                    }

                </Col>
            </Row>
        </Container>

    )

}