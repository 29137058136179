import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {
    BsChevronRight,
    BsUnlock,
} from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    InsuranceDataContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import {
    ageNearest,
    prepareOffers,
} from '../libs/insurance';
import { getNextStep } from '../libs/routing';
import {
    asCurrency,
    isEmail,
    isPhone,
} from '../libs/utils';
import {
    CalendarControl,
    DollarControl,
    EmailControl,
    PhoneNumberControl,
    RadioControl,
    TextControl,
} from '../screen/controls';
import { NeedsAnalysis } from './analysis';
import { ProductCatalog } from './catalog';
import { CoverageSlider } from './coverage';
import { Faq } from './faq';
import { PromoCarousel } from './promo-carousel';

export const ContactForm = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const [ready, setReady] = useState(false);
    const [validated, setValidated] = useState(false);

    const [highlighted, setHighlighted] = useState({
        email: false,
        phone: false,
        first_name: false,
        last_name: false
    });

    useEffect(() => {

        let newValidated = false;
        if (session.email && isEmail(session.email)) {
            if (session.phone && isPhone(session.phone)) {
                if (session.first_name) {
                    let fname = session.first_name.replace(/\s+/g, '');
                    if (fname.length > 0) {
                        if (session.last_name) {
                            let lname = session.last_name.replace(/\s+/g, '');
                            if (lname.length > 0) {
                                newValidated = true;
                                setSession({ has_contact: "yes" });
                            }
                        }
                    }
                }
            }
        }
        setValidated(newValidated);

    }, [session.email, session.first_name, session.last_name, session.phone]);

    const onChange = (controlId, value) => {
        let newSessionEntry = {};
        newSessionEntry[controlId] = value;
        setSession(newSessionEntry);
    }

    const onShow = (e) => {
        let newSessionEntry = {
            teaser: "done"
        }
        setSession(newSessionEntry);
        setReady(true);
    }

    return (
        <Container fluid className={"mobile" === layout.device ? 'mt-0 mb-2 ml-0 mr-0 px-0 py-0' : 'mb-1'}>
            {"mobile" === layout.device ?
                <>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <TextControl highlighted={highlighted.first_name} helpText="Please provide your first name." controlId="first_name" caption="First name" onChange={onChange} choices={{ default: session.first_name }} />
                        </Col>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <TextControl highlighted={highlighted.last_name} helpText="Please provide your last name." controlId="last_name" caption="Last name" onChange={onChange} choices={{ default: session.last_name }} />
                        </Col>
                    </Row>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <EmailControl highlighted={highlighted.email} helpText="This is the email address we'll use for official communications. Please make sure there's no typo." controlId="email" caption="Your email address?" onChange={onChange} choices={{ defaultValue: session.email }} />
                        </Col>
                    </Row>
                    <Row className='px-0 py-0 ml-0 mr-0'>
                        <Col className='col-12 px-0 py-0 ml-0 mr-0'>
                            <PhoneNumberControl highlighted={highlighted.phone} helpText="This is the phone number we'll use for official communications. Please make sure there's no typo." controlId="phone" caption="Your phone number?" onChange={onChange} choices={{ defaultValue: session.phone }} />
                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row>
                        <Col className='col-md-12 col-lg-6'>
                            <TextControl highlighted={highlighted.first_name} helpText="Please provide your first name." controlId="first_name" caption="First name" onChange={onChange} choices={{ default: session.first_name }} />
                        </Col>
                        <Col className='col-md-12 col-lg-6'>
                            <TextControl highlighted={highlighted.last_name} helpText="Please provide your last name." controlId="last_name" caption="Last name" onChange={onChange} choices={{ default: session.last_name }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-md-12 col-lg-12'>
                            <EmailControl highlighted={highlighted.email} helpText="This is the email address we'll use for official communications. Please make sure there's no typo." controlId="email" caption="Your email address?" onChange={onChange} choices={{ defaultValue: session.email }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-md-12 col-lg-12'>
                            <PhoneNumberControl highlighted={highlighted.phone} helpText="This is the phone number we'll use for official communications. Please make sure there's no typo." controlId="phone" caption="Your phone number?" onChange={onChange} choices={{ defaultValue: session.phone }} />
                        </Col>
                    </Row>
                </>
            }


        </Container >
    )

}