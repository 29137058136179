import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../app/context';
import { sendEmailVerification } from '../libs/cloud';
import { isEmail } from '../libs/utils';

export const EmailValidationBox = ({ setEmailOk }) => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [optout, setOptout] = useState(false);
    const [code, setCode] = useState("");
    const [allowResend, setAllowResend] = useState(true);
    const [message, setMessage] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [emailReadOnly, setEmailReadOnly] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setEmailAddress(session.email || "");
    }, []);

    useEffect(() => {


    }, [allowResend]);

    const handleChange = (e) => {
        setMessage(false);
        let value = e.target.value;
        switch (e.target.id) {
            case "formCode":
                value = value.replace(/[^0-9\-]/gi, '').substr(0, 4);
                setCode(value);
                setValid(4 === value.length);
                break;
            case "formEmail":
                setEmailAddress(value);
                if (isEmail(value)) {
                    setAllowResend(true);
                } else {
                    setAllowResend(false);
                }
                break;
            default:
                break;
        }
    }

    const resendCode = () => {

        setAllowResend(false);
        setSpinner(true);

        let payload = {
            quote: session.quote,
            email: emailAddress
        };

        sendEmailVerification(payload, (data) => {
            setSpinner(false);
            setMessage("Please check your inbox. The new code should arrive within a minute or two.");
            console.info(data);
            if (data.code && data.email) {
                let newSessionEntry = {
                    verification_code: data.code,
                    email: data.email
                }
                setSession(newSessionEntry);
            }

        })

        setTimeout(() => {
            setAllowResend(true);
            setMessage(false);
        }, 8000);
    }

    const verifyCode = () => {
        console.info("Valid?", valid, code, session.email, session.verification_code);
        if (valid && code === session.verification_code) {
            setEmailOk(true);
        } else {
            setMessage("This code is not valid. Please check your email and/or request a new code.");
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h5>Email verification</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        We sent a 4-digit code to your email address. Please enter this code below.
                    </p>
                    <Form>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" readOnly={emailReadOnly} disabled={emailReadOnly} value={emailAddress} onChange={handleChange} />
                            {emailReadOnly &&
                                <Form.Text className="text-muted">
                                    This is the email address you provided. <u className='clicker' onClick={() => setEmailReadOnly(false)}>Change</u>
                                </Form.Text>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCode">
                            <Form.Label>4-digit code</Form.Label>
                            <Form.Control type="text" placeholder="Enter 4-digit code" value={code} onChange={handleChange} />
                        </Form.Group>
                        <Button variant="primary" readOnly={!valid} disabled={!valid} type="button" onClick={verifyCode}>
                            Confirm
                        </Button>{' '}
                        {allowResend &&
                            <Button variant="outline-primary" type="button" onClick={resendCode}>
                                Resend code
                            </Button>
                        }{' '}
                    </Form>
                </Col>
            </Row>
            {spinner &&
                <Row className='mt-4'>
                    <Col>
                        <div className='dot-pulse'></div>
                    </Col>
                </Row>
            }
            {message &&
                <Row className='mt-4'>
                    <Col>
                        <Alert variant="info">
                            {message}
                        </Alert>
                    </Col>
                </Row>
            }

        </Container>

    )
}

